// Libs
import classNames from 'classnames/bind';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailPurchaseOrder } from '~/thunks/purchaseOrder/purchaseOrderThunk';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING, purchaseTypeOptions } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency, formattedTime } from '~/utils/helper';
import {
  IFormCreatePurchaseOrderProduct,
  IPurchaseOrderDetail,
} from '~/utils/interface/purchaseOrder';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './DetailPo.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailPo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { purchaseId } = useParams();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [detailPo, setDetailPo] = useState<IPurchaseOrderDetail>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!purchaseId) return;

    fetchDetailPo(purchaseId);
  }, [purchaseId]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchDetailPo = async (purchaseId: string) => {
    if (!purchaseId) return;
    loadingData?.show();

    try {
      const resp = await dispatch(getDetailPurchaseOrder(purchaseId)).unwrap();
      if (!resp) return;
      const { data } = resp;
      setDetailPo(data);
    } catch (error) {
    } finally {
      loadingData?.hide();
    }
  };

  const displayPoType = (type?: string) => {
    if (!type) return EMPTY_STRING;
    return purchaseTypeOptions.find((item) => item.value === type)?.label || EMPTY_STRING;
  };

  const calculateTotal = (products?: IFormCreatePurchaseOrderProduct[]) => {
    if (!products || products.length <= DEFAULT_NUMBER_ZERO) {
      return formatCurrency(CurrencyEnum.USD, DEFAULT_NUMBER_ZERO);
    }

    const total = products?.reduce(
      (total, currentTotal) => total + (currentTotal?.subTotal || DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );
    return formatCurrency(CurrencyEnum.USD, total);
  };

  const isDocumentImage = (url?: string) => {
    if (!url) return;
    return url?.match(/\.(jpg|jpeg)$/i);
  };
  //#endregion Handle

  return (
    <div id='detailPoTemplate' className={cx('container')}>
      <Toolbar title={t('template_purchase_detail_toolbar_title')} />

      <div className={cx('body')}>
        <div className={cx('verticalLine')} />

        <div className={cx('layout')}>
          <section className={cx('detailContent')}>
            <p className={cx('title')}>{t('template_purchase_detail_label_group_detail_info')}</p>
            <div className={cx('horizontalLine')} />

            <div className={cx('mainContent')}>
              <div className={cx('jobDetailsItem')}>
                <span className={cx('key')}>{t('template_purchase_detail_field_type')}</span>
                <span className={cx('value')}>{displayPoType(detailPo?.type)}</span>
              </div>

              <div className={cx('twoCol')}>
                <div className={cx('jobDetailsItem')}>
                  <span className={cx('key')}>{t('template_purchase_detail_field_vendor')}</span>
                  <span className={cx('value')}>{detailPo?.vendor?.name || EMPTY_STRING}</span>
                </div>

                <div className={cx('jobDetailsItem')}>
                  <span className={cx('key')}>{t('template_purchase_detail_field_job')}</span>
                  <span className={cx('value')}>{detailPo?.jobName || EMPTY_STRING}</span>
                </div>
              </div>

              <div className={cx('items')}>
                <p className={cx('itemsLabel')}>{t('template_purchase_detail_label_items')}</p>

                <div className={cx('listItemGroup')}>
                  <div className={cx('twoCol')}>
                    <p className={cx('key', 'italicStyle')}>
                      {t('template_purchase_detail_field_product')}
                    </p>

                    <div className={cx('threeCol')}>
                      <p className={cx('key', 'italicStyle')}>
                        {t('template_purchase_detail_field_quantity')}
                      </p>
                      <p className={cx('key', 'italicStyle')}>
                        {t('template_purchase_detail_field_unit_cost')}
                      </p>
                      <p className={cx('key', 'italicStyle')}>
                        {t('template_purchase_detail_field_subtotal')}
                      </p>
                    </div>
                  </div>

                  {detailPo?.products &&
                    detailPo?.products?.length > DEFAULT_NUMBER_ZERO &&
                    detailPo?.products?.map((item, index) => (
                      <Fragment key={index}>
                        <div className={cx('twoCol')}>
                          <p className={cx('value')}>{item?.productName || EMPTY_STRING}</p>

                          <div className={cx('threeCol')}>
                            <p className={cx('value')}>{item?.quantity ?? DEFAULT_NUMBER_ZERO}</p>
                            <p className={cx('value')}>
                              {formatCurrency(
                                CurrencyEnum.USD,
                                item?.unitCost ?? DEFAULT_NUMBER_ZERO
                              )}
                            </p>
                            <p className={cx('value')}>
                              {formatCurrency(
                                CurrencyEnum.USD,
                                item?.subTotal ?? DEFAULT_NUMBER_ZERO
                              )}
                            </p>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>

              <div className={cx('totalField')}>
                <div className={cx('jobDetailsItem')}>
                  <span className={cx('value', 'boldStyle')}>
                    {t('template_purchase_detail_field_total')}
                  </span>
                  <span className={cx('value', 'boldStyle')}>
                    {calculateTotal(detailPo?.products)}
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className={cx('additionalInfoContent')}>
            <p className={cx('title')}>
              {t('template_purchase_detail_label_group_additional_info')}
            </p>
            <div className={cx('horizontalLine')} />

            <div className={cx('mainContent')}>
              <div className={cx('jobDetailsItem')}>
                <span className={cx('key')}>{t('template_purchase_detail_label_date')}</span>
                <span className={cx('value')}>{formattedTime(detailPo?.date)}</span>
              </div>

              <div className={cx('documentGroup')}>
                <p className={cx('key')}>{t('template_purchase_detail_label_documents')}</p>

                {detailPo?.documents && detailPo?.documents?.length > DEFAULT_NUMBER_ZERO && (
                  <div className={cx('documentContainer')}>
                    {detailPo?.documents?.map((item, index) => (
                      <div className={cx('fileDocument')} key={index}>
                        {isDocumentImage(item?.url) ? (
                          <img
                            src={item.url}
                            alt={t('common_img_text_alt')}
                            className={cx('fileImg')}
                          />
                        ) : (
                          <img
                            src={icons.commonIconFile}
                            alt={t('common_img_text_alt')}
                            className={cx('fileIcon')}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={cx('noteGroup')}>
                <p className={cx('key')}>{t('template_purchase_detail_label_notes')}</p>
                <div className={cx('noteContent', 'value')}>{detailPo?.notes}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DetailPo;
