import { ApexOptions } from 'apexcharts';
import { NEUTRAL400, ZINC400, ZINC800 } from '~/utils/constants/color';
import {
  DEFAULT_NUMBER_ZERO,
  DEFAULT_TO_FIXED,
  EMPTY_STRING,
  K_SUFFIX,
  ONE_THOUSAND,
  PERCENT,
} from './common';
import { LabelColumnChartEnum } from '../enum';

export const configChartDonut = (payload: {
  isShowLabel: boolean;
  labels: string[];
  series: number[];
  colors: string[];
}) => {
  const { isShowLabel, labels, series, colors } = payload;
  return {
    chart: {
      id: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: isShowLabel,
            value: {
              fontSize: '20px',
              show: true,
              color: ZINC800,
              fontWeight: 700,
            },
            name: {
              fontSize: '10px',
              show: true,
              color: NEUTRAL400,
              fontWeight: 500,
            },
            total: {
              fontSize: '12px',
              show: true,
              color: NEUTRAL400,
              fontWeight: 500,
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    series: series,
    labels: labels,
    colors: colors,
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: number) => `${val.toFixed(DEFAULT_TO_FIXED)}${PERCENT}`,
      },
    },
  };
};

export const defaultConfigColumnChart: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      distributed: false,
      columnWidth: '20px',
      horizontal: false,
      rangeBarOverlap: false,
      borderRadius: 0,
    },
  },
  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
    fontSize: '12px',
    fontWeight: 500,
    horizontalAlign: 'center',
    // markers: {
    //   width: 12,
    //   height: 12,
    //   radius: 12,
    // },
    itemMargin: {
      horizontal: 60,
    },
    labels: {
      colors: ZINC800,
      useSeriesColors: false,
    },
  },
  grid: {
    strokeDashArray: 0,
    padding: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 5,
    },
  },
  stroke: {
    show: true,
    width: 0,
    curve: 'smooth',
    colors: ['transparent'],
  },
  yaxis: {
    stepSize: 20,
    show: true,
    labels: {
      offsetX: -17,
      style: {
        fontSize: '12',
        colors: NEUTRAL400,
        fontWeight: 500,
      },
    },
  },
  noData: {
    text: 'No data available',
    style: {
      fontSize: '12',
      fontFamily: 'Inter',
      color: NEUTRAL400,
    },
  },
};

export const configColumChart = (payload: {
  categories: string[];
  colors: string[];
}): ApexOptions => {
  const { categories, colors } = payload;
  return {
    ...defaultConfigColumnChart,
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12',
          colors: ZINC400,
          fontWeight: 500,
        },
      },
    },
    colors: colors,
  } as ApexOptions;
};

export const defaultConfigStackedColumnChart: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    },
    zoom: {
      enabled: false,
    },
    stacked: true,
  },

  plotOptions: {
    bar: {
      distributed: false,
      columnWidth: '60%',
      horizontal: false,
      rangeBarOverlap: false,
      borderRadius: 0,
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '12px',
          },
          formatter: (val) => {
            if (!val) return EMPTY_STRING;
            const value = parseFloat(val);
            return value >= ONE_THOUSAND
              ? `${(value / ONE_THOUSAND).toFixed(2)}${K_SUFFIX}`
              : value.toString();
          },
        },
      },
    },
  },

  dataLabels: {
    enabled: false,
  },
  grid: {
    show: false,
    strokeDashArray: 0,
    padding: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 5,
    },
  },
  stroke: {
    show: true,
    width: 0,
    curve: 'smooth',
    colors: ['transparent'],
  },
  yaxis: {
    show: true,
    labels: {
      formatter: (val) => {
        if (!val) return DEFAULT_NUMBER_ZERO.toString();
        return val >= ONE_THOUSAND ? `${val / ONE_THOUSAND}${K_SUFFIX}` : val.toString();
      },
      offsetX: -17,
      style: {
        fontSize: '12',
        colors: NEUTRAL400,
        fontWeight: 500,
      },
    },
  },
};

export const configStackedColumChart = (payload: {
  categories: string[];
  colors: string[];
  textNoData: string;
  labelContractJob: string;
  labelServiceJob: string;
}): ApexOptions => {
  const { categories, colors, textNoData, labelContractJob, labelServiceJob } = payload;

  return {
    ...defaultConfigStackedColumnChart,
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '10',
          colors: ZINC400,
          fontWeight: 400,
        },
        trim: true,
      },
    },
    noData: {
      text: textNoData,
      style: {
        fontSize: '12',
        fontFamily: 'Inter',
        color: NEUTRAL400,
      },
    },
    legend: {
      show: true,
      fontSize: '12px',
      fontWeight: 500,
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 60,
      },
      labels: {
        colors: ZINC800,
        useSeriesColors: false,
      },
      formatter(legendName, opts) {
        switch (legendName) {
          case LabelColumnChartEnum.CONTRACT_JOB:
            return labelContractJob;

          case LabelColumnChartEnum.SERVICE_JOB:
            return labelServiceJob;
          default:
            return EMPTY_STRING;
        }
      },
    },
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter(seriesName) {
            switch (seriesName) {
              case LabelColumnChartEnum.CONTRACT_JOB:
                return labelContractJob;

              case LabelColumnChartEnum.SERVICE_JOB:
                return labelServiceJob;
              default:
                return EMPTY_STRING;
            }
          },
        },
      },
    },
    colors: colors,
  } as ApexOptions;
};
