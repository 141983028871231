// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseModal,
  BaseSelect,
  BaseTextarea,
  FormInput,
  Loading,
  MultipleSelection,
} from '~/components';
// Others
import {
  DEFAULT_DATA_FROM_TASK,
  DEFAULT_INIT_EDIT_VALUES,
  taskSchema,
} from '~/components/specific/formTaskModal/helper';
import { useAppDispatch } from '~/redux/hooks';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { getListJob } from '~/thunks/job/jobThunk';
import { createTask, getTaskDetails, updateTask } from '~/thunks/task/taskThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsState,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, DateFormatEnum, StatusEnum } from '~/utils/enum';
import { formatDate, getFullName } from '~/utils/helper';
import { IAccount, IQueryAccount } from '~/utils/interface/account';
import { IBaseOption } from '~/utils/interface/common';
import { IGetListJobReq, IJob } from '~/utils/interface/job';
import { IFormTask, ITaskDetails, ITaskJob, IUpdateTask } from '~/utils/interface/task';
// Styles, images, icons
import styles from './FormTaskModal.module.scss';

type Props = {
  isOpen: boolean;
  taskId?: string;
  onClose: () => void;
  onSuccess?: () => void;
};

export type InitEditModeValues = Pick<IFormTask, 'dueDate' | 'completionDate'>;

const cx = classNames.bind(styles);

const FormTaskModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, taskId, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEditMode = useMemo(() => !!taskId, [taskId]);
  const initEditModeValues = useRef<InitEditModeValues>(DEFAULT_INIT_EDIT_VALUES);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormTask>({
    resolver: yupResolver(taskSchema({ t, isEditMode, initEditValue: initEditModeValues.current })),
    defaultValues: DEFAULT_DATA_FROM_TASK,
  });
  //#endregion Declare Hook

  //#region Declare useWatch
  const isShowLocation = useWatch({ name: `isLocation`, control });
  const stateSelected = useWatch({ name: `state`, control });
  //#endregion Declare useWatch

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listJob, setListJob] = useState<ITaskJob[]>([]);
  const [listAssignee, setListAssignee] = useState<IAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Declare useMemo
  const jobOptions: IBaseOption[] = useMemo(() => {
    if (listJob?.length === DEFAULT_NUMBER_ZERO) return [];
    return listJob?.map((job: ITaskJob) => ({ label: job.name, value: job.id }));
  }, [listJob]);

  const assigneeOptions: IBaseOption[] = useMemo(() => {
    if (listAssignee?.length === DEFAULT_NUMBER_ZERO) return [];
    return listAssignee?.map((account: IAccount) => ({
      label: getFullName({ firstName: account.firstName, lastName: account.lastName }),
      value: account.id,
    }));
  }, [listAssignee]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    // Modal Close: clear state
    if (!isOpen) {
      initEditModeValues.current = DEFAULT_INIT_EDIT_VALUES;
      return;
    }

    // Modal Open: fetch data for components Select
    getJobList({ page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_NUMBER_OPTIONS_SELECT });
    getAssigneeList({
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.PROJECT_MANAGER,
        AccountRoleCodesEnum.TECHNICIAN,
      ],
      status: StatusEnum.ACTIVE,
    });

    // Modal Open - Edit mode: fetch detail and reset Form
    if (taskId) {
      handleGetTaskDetails(taskId);
      return;
    }

    // Modal Open - Add mode: reset Form
    reset(DEFAULT_DATA_FROM_TASK);
  }, [isOpen, taskId]);

  /** When Field State change -> set: lat, lng by State selected **/
  useEffect(() => {
    const option = optionsState?.find((item) => item?.value === stateSelected);
    setValue('latitude', option ? option?.latitude : undefined);
    setValue('longitude', option ? option?.longitude : undefined);
  }, [stateSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const getJobList = (payload: IGetListJobReq) => {
    dispatch(getListJob(payload))
      .then((res) => {
        if (!res?.payload) return;

        const formattedData = res.payload?.data?.responses?.map((item: IJob) => ({
          ...item,
          name: item.jobName,
        }));
        setListJob(formattedData);
      })
      .catch((error) => {});
  };

  const getAssigneeList = (payload: IQueryAccount) => {
    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res.data;

        setListAssignee(responses);
      })
      .catch((error) => {});
  };

  const handleGetTaskDetails = (id: string) => {
    setIsLoading(true);

    dispatch(getTaskDetails(id))
      .unwrap()
      .then((res) => {
        const taskDetailsRes: ITaskDetails = res?.data;
        if (!taskDetailsRes) return;

        const {
          name,
          job,
          assignees,
          dueDate,
          completionDate,
          note,
          streetAddress,
          city,
          state,
          zipCode,
          latitude,
          longitude,
          isLocation,
        } = taskDetailsRes;

        const dataFormEdit: IFormTask = {
          name,
          jobId: job?.id ? +job.id : null,
          assigneeIds: assignees?.map((item) => item?.id) || [],
          dueDate: dueDate ? formatDate(dueDate, DateFormatEnum['YYYY-MM-DD']) : '',
          completionDate: completionDate
            ? formatDate(completionDate, DateFormatEnum['YYYY-MM-DD'])
            : null,
          ...(isLocation
            ? {
                isLocation: true,
                streetAddress: streetAddress || null,
                city: city || null,
                state: state || null,
                zipCode: zipCode || null,
                latitude: latitude || null,
                longitude: longitude || null,
              }
            : { isLocation: false }),
          note,
        };
        initEditModeValues.current = {
          dueDate: dataFormEdit.dueDate,
          completionDate: dataFormEdit.completionDate,
        };

        reset(dataFormEdit);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitForm = (dataForm: IFormTask) => {
    if (isEditMode && taskId) {
      const payload: IUpdateTask = { taskId, body: dataForm };
      handleUpdate(payload);
      return;
    }

    handleAdd(dataForm);
  };

  const handleAdd = (dataForm: IFormTask) => {
    setIsLoading(true);
    dispatch(createTask(dataForm))
      .unwrap()
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (data: IUpdateTask) => {
    setIsLoading(true);
    dispatch(updateTask(data))
      .unwrap()
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <BaseModal id='formTaskModalComponent' isOpen={isOpen} onClose={handleCancel}>
      <div className={cx('container')}>
        <div className={cx('header')}>
          {taskId ? t('admin_edit_task_title') : t('admin_add_task_title')}
        </div>

        <div className={cx('line')} />

        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitForm)}>
          <div className={cx('body')}>
            <Controller
              name={'name'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormInput
                  name={name}
                  label={t('common_form_task_field_name')}
                  value={value}
                  onChange={onChange}
                  required
                  errorMessage={errors?.name?.message}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name={'jobId'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseSelect
                    name={name}
                    label={t('common_form_task_field_job')}
                    placeholder={t('common_placeholder_select')}
                    options={jobOptions}
                    value={value?.toString() ?? EMPTY_STRING}
                    onChange={(data: IBaseOption) => {
                      onChange(data.value);
                    }}
                    isRequired
                    errorMessage={errors?.jobId?.message}
                  />
                )}
              />
              <Controller
                name={'assigneeIds'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <MultipleSelection
                    name={name}
                    height={35}
                    label={t('common_form_task_field_assignees')}
                    placeholder={t('common_placeholder_select')}
                    options={assigneeOptions}
                    value={value?.map(String) ?? []}
                    onChange={(optionsSelected: IBaseOption[], _) => {
                      const assigneeValues = optionsSelected.map((option) => Number(option.value));
                      onChange(assigneeValues);
                    }}
                    isRequired
                    errorMessage={errors.assigneeIds?.message}
                  />
                )}
              />
            </div>

            <Controller
              name={'isLocation'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <BaseCheckbox
                  name={name}
                  value={Boolean(value)}
                  label={t('common_form_task_field_location')}
                  onChange={onChange}
                />
              )}
            />

            {isShowLocation && (
              <>
                <div className={cx('twoCol')}>
                  <Controller
                    name={'streetAddress'}
                    control={control}
                    shouldUnregister={true}
                    render={({ field: { name, value, onChange } }) => (
                      <FormInput
                        name={name}
                        required
                        label={t('common_form_task_field_address')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                        errorMessage={errors.streetAddress?.message}
                      />
                    )}
                  />

                  <Controller
                    name={'city'}
                    control={control}
                    shouldUnregister={true}
                    render={({ field: { name, value, onChange } }) => (
                      <FormInput
                        name={name}
                        required
                        label={t('common_form_task_field_city')}
                        value={value || ''}
                        onChange={onChange}
                        errorMessage={errors.city?.message}
                      />
                    )}
                  />
                </div>

                <div className={cx('twoCol')}>
                  <Controller
                    name={'state'}
                    control={control}
                    shouldUnregister={true}
                    render={({ field: { name, value, onChange } }) => (
                      <BaseSelect
                        name={name}
                        isRequired
                        label={t('common_form_task_field_state')}
                        placeholder={t('common_placeholder_select')}
                        value={value ?? EMPTY_STRING}
                        options={optionsState || []}
                        onChange={(option: IBaseOption) => {
                          onChange(option.value);
                        }}
                        errorMessage={errors.state?.message}
                      />
                    )}
                  />

                  <Controller
                    name={'zipCode'}
                    control={control}
                    shouldUnregister={true}
                    render={({ field: { name, value, onChange } }) => (
                      <FormInput
                        name={name}
                        required
                        label={t('common_form_task_field_zip_code')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                        errorMessage={errors.zipCode?.message}
                      />
                    )}
                  />
                </div>
              </>
            )}

            <div className={cx('twoCol')}>
              <Controller
                name={'dueDate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    name={name}
                    label={t('common_form_task_field_due_date')}
                    placeholderText={t('common_placeholder_select_date')}
                    value={value}
                    onDateSelected={onChange}
                    isRequired
                    errorMessage={errors?.dueDate?.message}
                  />
                )}
              />

              <Controller
                name={'completionDate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    name={name}
                    label={t('common_form_task_field_completion_date')}
                    placeholderText={t('common_placeholder_select_date')}
                    value={value ?? EMPTY_STRING}
                    onDateSelected={onChange}
                    errorMessage={errors?.completionDate?.message}
                  />
                )}
              />
            </div>

            <Controller
              name={'note'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <BaseTextarea
                  name={name}
                  label={t('common_form_task_field_note')}
                  height={120}
                  maxLength={100}
                  value={value}
                  onTextareaChange={onChange}
                  errorMessage={errors?.note?.message}
                />
              )}
            />
          </div>

          <div className={cx('actions')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={handleCancel}
                typeStyle={ButtonTypeEnum.CANCEL}
              />
            </div>

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
            />
          </div>
        </form>

        {isLoading && <Loading />}
      </div>
    </BaseModal>
  );
};

export default FormTaskModal;
