// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  Status,
  Toolbar,
} from '~/components';
// Others
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  EMPTY_VALUE,
  POUND,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { AccountRoleCodesEnum, BaseTableEnum, CurrencyEnum, StorageEnum } from '~/utils/enum';
import { ColumnType, IPagination, IQueryBase, MoreActionItem } from '~/utils/interface/common';
import { IPurchaseOrder, IQueryListPO } from '~/utils/interface/purchaseOrder';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { formatCurrency } from '~/utils/helper';
import { getListPO } from '~/thunks/purchaseOrder/purchaseOrderThunk';
import { RootState } from '~/redux/store';
import { purchaseOrderActions } from '~/thunks/purchaseOrder/purchaseOrderSlice';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import styles from './ManagePo.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (params: {
  t: TFunction;
  onViewDetailAction: (id: string) => void;
}): ColumnType<IPurchaseOrder>[] => {
  const { t, onViewDetailAction } = params;

  return [
    {
      key: 'orderNumber',
      title: t('common_table_purchase_orders_po_number'),
      render: (_, record) => {
        return (
          <>
            <span className={cx('poNumber')}>{POUND}</span>
            {record.orderNumber}
          </>
        );
      },
    },
    {
      key: 'job',
      title: t('common_purchase_order_table_job'),
      render: (_, record) => (
        <>
          <span>{record.jobName || EMPTY_STRING}</span>
        </>
      ),
    },
    {
      key: 'total',
      title: t('common_table_purchase_orders_total_amount'),
      render: (_, record) => {
        return (
          <>{record.total ? formatCurrency(CurrencyEnum.USD, record.total) : DEFAULT_CURRENCY}</>
        );
      },
    },
    {
      key: 'status',
      title: t('common_table_purchase_orders_table_status'),
      render: (_, record) => {
        return record.status ? (
          <Status type='tag' status={record.status} />
        ) : (
          <span>{EMPTY_VALUE}</span>
        );
      },
    },
    {
      key: 'action',
      title: t('common_table_purchase_orders_table_action'),
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetailAction(record.id.toString()),
          },
        ];
        return <BaseMoreAction actions={actions} />;
      },
      width: '10%',
    },
  ];
};

const ManagePo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshPurchaseOrderList } = useAppSelector((state: RootState) => state.purchaseOrder);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState<IPurchaseOrder[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListPurchaseOrder(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!isRefreshPurchaseOrderList) return;

    handleGetListPurchaseOrder(paramObject);
    dispatch(purchaseOrderActions.setRefreshList(false));
  }, [isRefreshPurchaseOrderList]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListPurchaseOrder = (payload: IQueryListPO) => {
    loadingData?.show();

    dispatch(getListPO(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;
        responses && setPurchaseOrderList(responses);
        pagination && setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const goToCreate = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.createPo}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.createPo}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.createPo}`);
        break;
    }
  };

  const onViewDetailAction = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.purchaseOrder}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.purchaseOrder}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.purchaseOrder}/${id}`);
        break;
    }
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleChangePagination = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IPurchaseOrder) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.purchaseOrder}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.purchaseOrder}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.purchaseOrder}/${record.id}`);
        break;
    }
  };

  const handleSearchPurchaseOrder = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='managePoTemplate' className={cx('container')}>
      <Toolbar
        title={t('purchase_order_toolbar_title', {
          total: pagination?.totalItems || DEFAULT_NUMBER_ZERO,
        })}
        primaryBtn={{
          action: goToCreate,
          label: t('purchase_order_toolbar_btn_add'),
        }}
        onSearch={handleSearchPurchaseOrder}
      />

      <section className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns({ t, onViewDetailAction })}
            dataSource={purchaseOrderList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handleChangePagination}
          />
        </div>
      </section>

      {/* Modals */}
      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default ManagePo;
