// Libs
import classNames from 'classnames/bind';
import { Description, Field, Input, Label } from '@headlessui/react';
import { ChangeEvent, useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  REGEX,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './BasePhoneNumber.module.scss';

type Props = {
  name?: string;
  width?: number | string;
  height?: number | string;
  label?: string;
  required?: boolean;
  phoneNumber?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  errorMessage?: string;
  onChange?: (data: { phoneNumber: string }) => void;
};

const cx = classNames.bind(styles);

const BasePhoneNumber = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    width,
    height,
    label,
    required,
    phoneNumber = EMPTY_STRING,
    placeholder,
    maxLength,
    disabled,
    errorMessage,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [inputValue, setInputValue] = useState<string>(phoneNumber);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setInputValue(phoneNumber || EMPTY_STRING);
  }, [phoneNumber]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const digitsOnly = value
      .replace(REGEX.REPLACE_TEXT_ALL, EMPTY_STRING)
      .slice(DEFAULT_NUMBER_ZERO, maxLength);

    setInputValue(digitsOnly);
    onChange && onChange({ phoneNumber: digitsOnly });
  };
  //#endregion Handle Function

  return (
    <Field id='basePhoneNumberComponent' className={cx('container')} disabled={disabled}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('phoneNumberWrap')} style={{ width, height }}>
        {/* Country code here */}

        <Input
          placeholder={placeholder}
          value={inputValue}
          type='tel'
          name={name}
          className={cx('inputPhone')}
          disabled={disabled}
          onChange={handlePhoneNumberChange}
        />
      </div>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default BasePhoneNumber;
