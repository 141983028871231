// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseRichEditor,
  BaseSelect,
  ButtonDropdown,
  CommonConfirmModal,
  ExportProposalPdfButton,
  FormInput,
  FormLineItemContainer,
  FormSendDocument,
  ImportTermTemplateModal,
  ModalUnderDevelopment,
  SendProposalButton,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { mockDataPaymentTerms, mockDataTaxRate } from '~/mockData';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { createInvoice } from '~/thunks/invoice/invoiceThunk';
import {
  createProposal,
  getDetailProposal,
  sendProposal,
  updateProposal,
} from '~/thunks/proposal/proposalThunk';
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_PROPOSAL_FILE_NAME_PDF,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { adminRouteAbsolute } from '~/utils/constants/route';
import {
  ButtonTypeEnum,
  CurrencyEnum,
  InputTypeEnum,
  ModeTypeEnum,
  ProposalContentEnum,
  StatusQuickBookEnum,
  TextEditorEnum,
} from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBaseOption, ISingleSelectOption } from '~/utils/interface/common';
import { IFormCreateInvoice } from '~/utils/interface/invoices';
import { IUserProfile } from '~/utils/interface/profile';
import {
  ILinkPhase,
  IPayloadSendProposal,
  IProposal,
  IUpdateProposal,
} from '~/utils/interface/proposal';
import { ITermTemplate } from '~/utils/interface/termsAndConditions';
import { TLineItemBudgetDetail } from '~/utils/type/common';
import { proposalSchema } from './helper';
// Styles, images, icons
import styles from './JobProposalTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const JobProposalTab = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();

  const defaultData: IProposal = useMemo(() => {
    return {
      estimateStart: EMPTY_STRING,
      jobId: EMPTY_STRING,
      lineItems: [],
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    trigger,
    reset,
  } = useForm<IProposal>({
    resolver: yupResolver(proposalSchema(t)),
    defaultValues: defaultData,
  });
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useAppSelector((state: RootState) => state.job.jobDetails);
  const userInfo: IUserProfile = useAppSelector((state) => state.profileState.userProfile);
  //#endregion Selector

  //#region Watch
  const lineItems = useWatch({ control, name: 'lineItems' });
  //#endregion Watch

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(ModeTypeEnum.CREATE);
  const [proposalId, setProposalId] = useState<string>();
  const [isRefreshPage, setIsRefreshPage] = useState<boolean>(false);
  const [isShowTermTemplateModal, setIsShowTermTemplateModal] = useState<boolean>(false);
  const [proposalData, setProposalData] = useState<IProposal>();
  const [isShowErrorLineItem, setIsShowErrorLineItem] = useState<boolean>(false);
  const [isShowSendDocument, setIsShowDocument] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!jobId) return;

    setValue('jobId', jobId);
    handleGetDetailProposal();
  }, [jobId]);

  useEffect(() => {
    if (!isRefreshPage) return;

    handleGetDetailProposal();
    setIsRefreshPage(false);
  }, [isRefreshPage]);

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems]);
  //#endregion Implement Hook

  //#region Handle Function
  const filterProposalData = (data: IProposal): IProposal => {
    const filteredLineItems = data.lineItems.map(({ isShowBreakDown, isShowComment, ...rest }) => ({
      ...rest,
    }));

    return {
      ...data,
      lineItems: filteredLineItems,
    };
  };

  const handleCreateProposal = (data: IProposal) => {
    loadingData?.show();
    const dataUpdated = filterProposalData(data);

    dispatch(createProposal(dataUpdated))
      .unwrap()
      .then((_resp) => {
        setIsRefreshPage(true);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSendInvoice = () => {
    if (!proposalData || !jobId || !jobDetails?.client?.id) return;

    loadingData?.show();

    const payload: IFormCreateInvoice = {
      clientId: Number(jobDetails?.client?.id),
      jobId: Number(jobId),
      jobName: jobDetails?.name,
      openDate: proposalData?.estimateStart,
      dueDate: proposalData?.estimateEnd,
      total: proposalData?.value,
    };

    dispatch(createInvoice(payload))
      .unwrap()
      .then((res) => {
        navigate(`${adminRouteAbsolute.invoices}`);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSendForSignature = () => {
    // setIsShowDocument(true);

    setIsShowModalUnderDevelopment(true);
  };

  const handleEditProposal = (data: IProposal) => {
    if (!proposalId) return;

    loadingData?.show();

    const dataUpdated = filterProposalData(data);
    if (dataUpdated.id) delete dataUpdated.id;

    const payload: IUpdateProposal = {
      proposalId: proposalId,
      body: dataUpdated,
    };

    dispatch(updateProposal(payload))
      .unwrap()
      .then((_resp) => {
        setIsRefreshPage(true);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleImportTemplate = () => {
    setIsShowTermTemplateModal(true);
  };

  const handleGetDetailProposal = () => {
    loadingData?.show();

    dispatch(getDetailProposal(String(jobId)))
      .unwrap()
      .then((resp) => {
        const proposalData: IProposal = resp;
        if (!proposalData) return;
        setProposalData(proposalData);

        setProposalId(proposalData.id);
        const fields: TLineItemBudgetDetail[] = [
          'materials',
          'equipments',
          'labors',
          'miscellanea',
          'subcontractor',
        ];

        const lineItemUpdated = proposalData.lineItems?.map((lineItem) => {
          let isChecked = false;

          fields.forEach((field) => {
            proposalData.lineItems?.forEach((lineItem) => {
              const value = lineItem[field];
              const isObject = value && typeof value === 'object' && !Array.isArray(value);

              if (field === ProposalContentEnum.SUBCONTRACTORS && isObject) {
                const assignedWorks = value.assignedSubcontractors || [];
                const unassignedWorks = value.unassignedWorks || [];

                if (
                  assignedWorks.length > DEFAULT_NUMBER_ZERO ||
                  unassignedWorks.length > DEFAULT_NUMBER_ZERO
                ) {
                  isChecked = true;
                }
              }

              if (Array.isArray(value) && value.length > DEFAULT_NUMBER_ZERO) {
                isChecked = true;
              }
            });
          });

          const linkPhasesUpdated = lineItem.linkPhases?.filter(
            (phase) => phase.cost > DEFAULT_NUMBER_ZERO || phase.total > DEFAULT_NUMBER_ZERO
          );

          return {
            ...lineItem,
            subcontractor: {
              unassignedWorks: lineItem?.subcontractor?.unassignedWorks ?? [],
              assignedSubcontractors: lineItem?.subcontractor?.assignedSubcontractors ?? [],
              subTotal: lineItem?.subcontractor?.total ?? DEFAULT_NUMBER_ZERO,
            },
            isShowBreakDown: isChecked,
            linkPhases: linkPhasesUpdated?.map((item: ILinkPhase) => ({
              type: item.type,
              cost: item.cost,
              markup: item?.markup,
              total: item.total ?? DEFAULT_NUMBER_ZERO,
            })),
            isShowComment: !!lineItem.comment,
          };
        });

        // setMode(ModeTypeEnum.EDIT);
        proposalData && reset({ ...proposalData, lineItems: lineItemUpdated });
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeSelect = (option: ISingleSelectOption, name: any) => {
    setValue(name, option.value, { shouldDirty: true });
    trigger(name);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!lineItems || lineItems?.length == DEFAULT_NUMBER_ZERO) {
      setIsShowErrorLineItem(true);
      return;
    }

    if (!proposalId) {
      handleSubmit(handleCreateProposal)();
      return;
    } else {
      handleSubmit(handleEditProposal)();
      return;
    }
  };

  const calculateTotals = () => {
    const lineItems = watch('lineItems');
    if (!lineItems) return;

    // Calc Materials
    const materialsTotals = lineItems.reduce((totals, lineItem) => {
      return (totals +=
        lineItem?.materials?.reduce(
          (sum, item) => sum + (item.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    // Calc Labors
    const laborsTotals = lineItems.reduce((totals, lineItem) => {
      return (totals +=
        lineItem?.labors?.reduce(
          (sum, item) => sum + (item.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    // Calc SubContractors
    const subContractorsTotals = lineItems.reduce((totals, lineItem) => {
      if (lineItem.subcontractor) {
        const unassignedTotal =
          lineItem.subcontractor.unassignedWorks?.reduce(
            (sum, work) => sum + Number(work.value || DEFAULT_NUMBER_ZERO),
            DEFAULT_NUMBER_ZERO
          ) || DEFAULT_NUMBER_ZERO;

        const assignedTotal =
          lineItem.subcontractor.assignedSubcontractors?.reduce(
            (sum, subcontractor) => sum + Number(subcontractor.value || DEFAULT_NUMBER_ZERO),
            DEFAULT_NUMBER_ZERO
          ) || DEFAULT_NUMBER_ZERO;

        return totals + unassignedTotal + assignedTotal;
      }
      return totals;
    }, DEFAULT_NUMBER_ZERO);

    // Calc Equipments
    const equipmentsTotals = lineItems.reduce((totals, lineItem) => {
      return (totals +=
        lineItem?.equipments?.reduce(
          (sum, item) => sum + (item.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    // Calc Miscellanea
    const miscellaneaTotals = lineItems.reduce((totals, lineItem) => {
      return (totals +=
        lineItem?.miscellanea?.reduce(
          (sum, item) => sum + (item.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    // Calc Price
    const priceTotal = lineItems.reduce(
      (total, item) => total + (Number(item.price) ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    // Calc Linked Phase Total
    const linkedPhasesTotal = lineItems.reduce((totals, lineItem) => {
      return (totals +=
        lineItem?.linkPhases?.reduce(
          (sum, item) => sum + (item.total || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    const cost =
      materialsTotals +
      laborsTotals +
      equipmentsTotals +
      miscellaneaTotals +
      subContractorsTotals +
      linkedPhasesTotal;

    const profit = priceTotal - cost;

    setValue('cost', cost);
    setValue('value', priceTotal);
    setValue('profit', profit);
  };

  const handleCloseTermTemplateModal = () => {
    setIsShowTermTemplateModal(false);
  };

  const handleApplyTermTemplate = (template: ITermTemplate) => {
    if (!template) return;

    setValue('termsConditions', `${template.upperContent} ${template.lowerContent}`);
  };

  const handleCloseModalErrorLineItem = () => {
    setIsShowErrorLineItem(false);
  };

  const handleCloseSendDocument = () => {
    setIsShowDocument(false);
  };

  const handleSendProposal = async (pdfBlob: Blob) => {
    if (!jobId || !pdfBlob) return;
    loadingData?.show();

    try {
      const formData = new FormData();

      formData.append('document', pdfBlob, DEFAULT_PROPOSAL_FILE_NAME_PDF);

      const payload: IPayloadSendProposal<FormData> = { jobId, body: formData };
      await dispatch(sendProposal(payload)).unwrap();
    } catch (error) {
    } finally {
      loadingData?.hide();
    }
  };
  //#endregion Handle Function

  return (
    <>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          getValues,
          formState: { errors },
        } as UseFormReturn<IProposal>)}
      >
        <div className={cx('container')}>
          <form className={cx('headerForm')} onSubmit={handleFormSubmit}>
            <div className={cx('viewInfoHeader')}>
              <Controller
                name={'value'}
                render={({ field: { value } }) => (
                  <div className={cx('itemHeader')}>
                    <p className={cx('titleItemHeader')}>
                      {t('template_create_proposal_label_value')}
                    </p>
                    <p className={cx('valueItemHeader')}>
                      {formatCurrency(CurrencyEnum.USD, value)}
                    </p>
                  </div>
                )}
              />
              <Controller
                name={'cost'}
                render={({ field: { value } }) => (
                  <div className={cx('itemHeader')}>
                    <p className={cx('titleItemHeader')}>
                      {t('template_create_proposal_label_cost')}
                    </p>
                    <p className={cx('valueItemHeader')}>
                      {formatCurrency(CurrencyEnum.USD, value)}
                    </p>
                  </div>
                )}
              />
              <Controller
                name={'profit'}
                render={({ field: { value } }) => (
                  <div className={cx('itemHeader')}>
                    <p className={cx('titleItemHeader')}>
                      {t('template_create_proposal_label_profit')}
                    </p>
                    <p className={cx('valueItemHeader')}>
                      {formatCurrency(CurrencyEnum.USD, value)}
                    </p>
                  </div>
                )}
              />
            </div>

            {proposalId && (
              <div className={cx('viewActionEdit')}>
                <ButtonDropdown width={117} label={t('common_btn_save')} type='submit'>
                  {jobDetails?.client?.quickBookCustomerId &&
                    userInfo?.quickBook?.status === StatusQuickBookEnum.CONNECTED && (
                      <BaseButton
                        label={t('template_create_proposal_button_send_invoice')}
                        typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                        onClick={handleSendInvoice}
                        type='button'
                        width={170}
                      />
                    )}
                  <BaseButton
                    label={t('template_create_proposal_button_send_for_signature')}
                    typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                    onClick={handleSendForSignature}
                    type='button'
                    width={170}
                  />
                  <ExportProposalPdfButton />
                  <SendProposalButton onPdfGenerated={handleSendProposal} />
                </ButtonDropdown>
              </div>
            )}

            {!proposalId && (
              <div className={cx('viewActionEdit')}>
                <BaseButton
                  label={t('common_btn_save')}
                  width={117}
                  typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                  type='submit'
                />
              </div>
            )}
          </form>
          <div id='jobProposalTabComponent' className={cx('containerBody')}>
            <div className={cx('containerForm')}>
              <div className={cx('inputRow')}>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_contact_name')}
                  </p>
                  <Controller
                    name={`contract`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormInput
                        placeholder={t('template_create_proposal_placeholder_contact_name')}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_estimated_start_date')}
                    <span className={cx('required')}> {ASTERISK_SYMBOL}</span>
                  </p>
                  <Controller
                    name={'estimateStart'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseDatePicker
                        placeholderText={t('template_create_proposal_placeholder_estimated_date')}
                        onDateSelected={onChange}
                        value={value}
                        isRequired
                        errorMessage={errors.estimateStart?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={cx('inputRow')}>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_po')}
                  </p>
                  <Controller
                    name={'po'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormInput
                        type={InputTypeEnum.NUMBER}
                        placeholder={t('template_create_proposal_placeholder_po')}
                        onChange={onChange}
                        value={value ?? EMPTY_STRING}
                      />
                    )}
                  />
                </div>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_estimated_end_date')}
                  </p>
                  <Controller
                    name={'estimateEnd'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseDatePicker
                        placeholderText={t('template_create_proposal_placeholder_estimated_date')}
                        onDateSelected={onChange}
                        value={value ?? EMPTY_STRING}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={cx('inputRow')}>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_contract')}
                  </p>
                  <Controller
                    name={'attn'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormInput
                        type={InputTypeEnum.NUMBER}
                        placeholder={t('template_create_proposal_placeholder_contract')}
                        onChange={onChange}
                        value={value ?? EMPTY_STRING}
                      />
                    )}
                  />
                </div>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_payment_terms')}
                  </p>
                  <Controller
                    name={'paymentTerm'}
                    control={control}
                    render={({ field: { value, name } }) => (
                      <BaseSelect
                        options={mockDataPaymentTerms}
                        placeholder={t('template_create_proposal_placeholder_payment_terms')}
                        onChange={(optionSelected: IBaseOption) =>
                          handleChangeSelect(optionSelected, name)
                        }
                        value={value ?? EMPTY_STRING}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('containerContent')}>
              <p className={cx('titleContent')}>
                {t('template_create_proposal_title_project_description')}
              </p>
              <div className={cx('viewEditor')}>
                <Controller
                  name={'description'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseRichEditor
                      mode={mode}
                      type={TextEditorEnum.CUSTOM}
                      height={'100%'}
                      value={value ?? EMPTY_STRING}
                      onChange={(text: string) => onChange(text)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={cx('containerContent')}>
              <p className={cx('titleContent')}>{t('template_create_proposal_title_line_items')}</p>
              <FormLineItemContainer mode={mode} />
              <div className={cx('viewInfoLineItem')}>
                <div className={cx('viewInput')}>
                  <p className={cx('label', 'labelInput')}>
                    {t('template_create_proposal_label_tax_rate')}
                  </p>
                  <Controller
                    name={'taxRate'}
                    control={control}
                    render={({ field: { value, name } }) => (
                      <BaseSelect
                        options={mockDataTaxRate}
                        placeholder={t('template_create_proposal_placeholder_tax_rate')}
                        onChange={(optionSelected: IBaseOption) =>
                          handleChangeSelect(optionSelected, name)
                        }
                        value={value ?? EMPTY_STRING}
                      />
                    )}
                  />
                </div>
                <div className={cx('viewTextInfo')}>
                  <p className={cx('label')}>{t('template_create_proposal_label_contract_sum')}</p>
                  <p className={cx('value')}>
                    {formatCurrency(CurrencyEnum.USD, DEFAULT_NUMBER_ZERO)}
                  </p>
                </div>
                <div className={cx('viewTextInfo')}>
                  <p className={cx('label')}>
                    {t('template_create_proposal_label_taxes_contract_sum')}
                  </p>
                  <p className={cx('value')}>
                    {formatCurrency(CurrencyEnum.USD, DEFAULT_NUMBER_ZERO)}
                  </p>
                </div>
              </div>
            </div>
            <div className={cx('containerContent')}>
              <p className={cx('titleContent')}>
                {t('template_create_proposal_title_terms_conditions')}
              </p>
              <div className={cx('viewTemplate')}>
                <button
                  type='button'
                  className={cx('importTemplate')}
                  onClick={handleImportTemplate}
                >
                  {t('template_create_proposal_button_import_template')}
                </button>
              </div>
              <div className={cx('viewEditor')}>
                <Controller
                  name={'termsConditions'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseRichEditor
                      mode={mode}
                      height={'100%'}
                      onChange={(value: string) => onChange(value)}
                      value={value ?? EMPTY_STRING}
                      type={TextEditorEnum.CUSTOM}
                    />
                  )}
                />
              </div>
            </div>
            {isShowModalUnderDevelopment && (
              <ModalUnderDevelopment onClose={handleClickUnderDevelop} />
            )}
          </div>
        </div>

        <ImportTermTemplateModal
          isOpen={isShowTermTemplateModal}
          onClose={handleCloseTermTemplateModal}
          onApply={handleApplyTermTemplate}
        />

        <FormSendDocument isOpen={isShowSendDocument} onClose={handleCloseSendDocument} />
      </FormProvider>

      <CommonConfirmModal
        isOpen={isShowErrorLineItem}
        onClose={handleCloseModalErrorLineItem}
        title={t('proposal_line_item_content_modal')}
      />
    </>
  );
};

export default JobProposalTab;
