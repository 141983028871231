// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './ActivityShortContent.module.scss';

type Props = {
  children?: React.ReactNode;
  width?: number | string;
};

const cx = classNames.bind(styles);

const ActivityShortContent = (props: Props) => {
  //#region Destructuring Props
  const { children, width = 200 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='activityShortContentModuleComponent' style={{ width }} className={cx('container')}>
      {children}
    </div>
  );
};

export default ActivityShortContent;
