// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import notificationsApi from '~/apis/notifications';
// Others
import { GET_LIST_NOTIFICATIONS, MARKED_READ_NOTIFICATION } from '~/utils/constants/actionType';
import { IQueryBase } from '~/utils/interface/common';

export const getListNotifications = createAsyncThunk(
  GET_LIST_NOTIFICATIONS,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await notificationsApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markedReadNotification = createAsyncThunk(
  MARKED_READ_NOTIFICATION,
  async (notificationId: string, { rejectWithValue }) => {
    try {
      const res = await notificationsApi.markedRead(notificationId);
      return { data: res.data.data, id: notificationId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
