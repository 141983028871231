import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { CONTENT_TYPE_FORM_DATA, urlApiClient } from '~/utils/constants/common';
import { IClient } from '~/utils/interface/client';

const clientApi = {
  getListClient(params: ITableParams) {
    const url = `${urlApiClient.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IClient[]>>>(url, {
      params: params,
    });
  },

  getClientDetails(id: string) {
    const url = `${urlApiClient.getDetails}/${id}`;
    return axiosClient.get(url);
  },

  deleteClient(id: string) {
    const url = `${urlApiClient.delete}/${id}`;
    return axiosClient.delete(url);
  },

  createNewClient(formData: FormData) {
    const url = `${urlApiClient.create}`;
    return axiosClient.post(url, formData, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  updateClient(id: string, formData: FormData) {
    const url = `${urlApiClient.update}/${id}`;
    return axiosClient.put(url, formData, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  sendToQBClient(clientId: string) {
    const url = `${urlApiClient.sendToQB(clientId)}`;
    return axiosClient.put(url);
  },
};

export default clientApi;
