// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getDetailProfile, updateProfile } from '~/thunks/profile/profileThunk';
import { StatusQuickBookEnum } from '~/utils/enum';
import { IUserProfile } from '~/utils/interface/profile';

export interface ProfileState {
  userProfile: IUserProfile;
  isRefresh: boolean;
}

const initialState: ProfileState = {
  userProfile: {} as IUserProfile,
  isRefresh: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    connectedQuickBook(state) {
      state.userProfile.quickBook.status = StatusQuickBookEnum.CONNECTED;
    },
    disconnectedQuickBook(state) {
      state.userProfile.quickBook.status = StatusQuickBookEnum.DISCONNECTED;
    },
    setIsRefresh(state, action) {
      state.isRefresh = action.payload;
    },
  },
  extraReducers(builder) {
    // Get profile
    builder
      .addCase(getDetailProfile.pending, (state) => {
        state.userProfile = {} as IUserProfile;
      })
      .addCase(getDetailProfile.fulfilled, (state, action) => {
        state.userProfile = action?.payload?.data;
      })
      .addCase(getDetailProfile.rejected, (_state) => {});

    // Update profile
    builder
      .addCase(updateProfile.pending, (state) => {})
      .addCase(updateProfile.fulfilled, (state, action) => {})
      .addCase(updateProfile.rejected, (state) => {});
  },
});

export const profileActions = profileSlice.actions;
export const selectUserProfile = (state: RootState) => state.profileState.userProfile;
export const selectUserProfileRefresh = (state: RootState) => state.profileState.isRefresh;

const profileReducer = profileSlice.reducer;
export default profileReducer;
