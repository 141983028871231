import { CONTENT_TYPE_FORM_DATA, urlApiProposal } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IGetListLineItem,
  IProposal,
  IQueryGetListLineItem,
  IUpdateProposal,
  IPayloadSendDocument,
  IPayloadSendProposal,
} from '~/utils/interface/proposal';
import axiosClient from './axiosClient';

const proposalApi = {
  createProposal(payload: IProposal) {
    const url = `${urlApiProposal.createProposal}`;
    return axiosClient.post(url, payload);
  },

  getLineItemList(query: IQueryGetListLineItem) {
    const { params, jobId } = query;
    const url = `${urlApiProposal.getListLineItem(jobId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IGetListLineItem[]>>>(url, { params });
  },
  detailProposal(jobId: string) {
    const url = `${urlApiProposal.detailProposal(jobId)}`;
    return axiosClient.get(url);
  },
  updateProposal(payload: IUpdateProposal) {
    const { proposalId, body } = payload;

    const url = `${urlApiProposal.updateProposal(proposalId)}`;
    return axiosClient.put(url, body);
  },

  sendDocument(payload: IPayloadSendDocument<FormData>) {
    const { jobId, body } = payload;

    const url = `${urlApiProposal.sendDocument(jobId)}`;
    return axiosClient.post(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  sendProposal(payload: IPayloadSendProposal<FormData>) {
    const { jobId, body } = payload;

    const url = `${urlApiProposal.sendProposal(jobId)}`;
    return axiosClient.post(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default proposalApi;
