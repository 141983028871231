// Libs
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// Others
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH } from '~/utils/constants/common';
import { IQueryBase } from '~/utils/interface/common';
import { INotification } from '~/utils/interface/notifications';
import { getListNotifications, markedReadNotification } from './notificationsThunk';
import { NotificationStatusEnum } from '~/utils/enum';

export interface NotificationsState {
  notificationsUnread: number;
  notificationList: INotification[];
  queryParams: IQueryBase;
  refreshNotificationList: boolean;
}

const initialState: NotificationsState = {
  notificationsUnread: 0,
  notificationList: [],
  queryParams: {
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  },
  refreshNotificationList: false,
};

const notificationsSlice = createSlice({
  name: 'notificationsState',
  initialState,
  reducers: {
    setParamsNotification(state, action: PayloadAction<IQueryBase>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setUnreadNumberNotification(state, action: PayloadAction<number>) {
      state.notificationsUnread = action.payload;
    },
    refreshData(state, action: PayloadAction<boolean>) {
      state.refreshNotificationList = action.payload;
      state.queryParams = initialState.queryParams;
    },
    setRefreshState(state, action: PayloadAction<boolean>) {
      state.refreshNotificationList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list notifications
    builder
      .addCase(getListNotifications.pending, (state) => {})
      .addCase(getListNotifications.fulfilled, (state, action) => {
        const { notifications, numberNotificationsUnread } = action.payload.data;
        const { page } = state.queryParams;

        state.notificationList =
          page === DEFAULT_CURRENT_PAGE
            ? [...notifications?.responses]
            : [...state.notificationList, ...notifications?.responses];

        state.notificationsUnread = numberNotificationsUnread ?? 0;
      })
      .addCase(getListNotifications.rejected, (state) => {});

    // Marked read notification
    builder
      .addCase(markedReadNotification.pending, (state) => {})
      .addCase(markedReadNotification.fulfilled, (state, action) => {
        const index = state.notificationList.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.notificationList[index].status = NotificationStatusEnum.READ;
        }
        state.notificationsUnread = action.payload.data.numberNotificationsUnread;
      })
      .addCase(markedReadNotification.rejected, (state) => {});
  },
});

export const notificationActions = notificationsSlice.actions;

const notificationsReducer = notificationsSlice.reducer;
export default notificationsReducer;
