// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Components, Layouts, Pages
import {
  AdminAddContractJobModal,
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ConfirmModal,
  Status,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { RootState } from '~/redux/store';
import { jobActions } from '~/thunks/job/jobSlice';
import { changeStatusServiceJob, getListJob } from '~/thunks/job/jobThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  ButtonTypeEnum,
  GetListJobTypeEnum,
  ServiceJobStatusEnum,
  StorageEnum,
} from '~/utils/enum';
import { formatNumber, getFullName } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import { IContractJob } from '~/utils/interface/contractJob';
import { IChangeStatusJobPayload, IGetListJobReq } from '~/utils/interface/job';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './ContractJobs.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetails: (id: string) => void,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  onClosed: (id: string) => void,
  onRejected: (id: string) => void,
  onActive: (id: string) => void
): ColumnType<IContractJob>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={handleSelectAll}
    //     />
    //   ),
    //   render(_, record: IContractJob) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'jobName',
      title: t('admin_manage_contract_jobs_table_job_name'),
      dataIndex: 'jobName',
      render: (_, record) => <span>{record?.jobName || EMPTY_STRING}</span>,
    },
    {
      key: 'client',
      title: t('admin_manage_contract_jobs_table_client'),
      dataIndex: 'client',
      render: (_, record) => (
        <span>
          {getFullName({
            firstName: record?.client?.firstName,
            lastName: record?.client?.lastName,
          })}
        </span>
      ),
      width: '16%',
    },
    {
      key: 'phaseLeft',
      title: t('admin_manage_contract_jobs_table_phase_left'),
      dataIndex: 'phaseLeft',
      render: (_, record) => (
        <span>{record?.phaseLeft ? `${record?.phaseLeft}` : EMPTY_STRING}</span>
      ),
    },
    {
      key: 'status',
      title: t('admin_manage_contract_jobs_table_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record?.status} />,
    },
    {
      key: 'profit',
      title: t('admin_manage_contract_jobs_table_profit'),
      dataIndex: 'profit',
      render: (_, record) => (
        <span>
          {record?.profit ? t('common_percent_value', { value: record?.profit }) : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_contract_jobs_table_action'),

      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view_details'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetails(record.id),
          },
          {
            label: t('common_action_active'),
            icon: (
              <img
                src={icons.commonIconActive}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onActive(record.id),
          },

          {
            label: t('common_action_rejected_contract_job'),
            icon: (
              <img
                src={icons.commonIconCloseRed}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => onRejected(record.id),
          },
          {
            label: t('common_action_closed_contract_job'),
            icon: (
              <img
                src={icons.commonIconCloseRed}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => onClosed(record.id),
          },
        ];
        return <BaseMoreAction actions={actions} width={260} />;
      },

      width: '10%',
    },
  ];
};

const ManageContractJobs = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshJobList } = useAppSelector((state: RootState) => state.job);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IGetListJobReq>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    type: GetListJobTypeEnum.CONTRACT,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowAddContractJob, setIsShowAddContractJob] = useState<boolean>(false);
  const [contractJobsList, setContractJobsList] = useState<IContractJob[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  const [isShowConfirmClosed, setIsShowConfirmClosed] = useState<boolean>(false);
  const [isShowConfirmRejected, setIsShowConfirmRejected] = useState<boolean>(false);
  const [isShowConfirmActive, setIsShowConfirmActive] = useState<boolean>(false);
  const [contractJobIdSelected, setContractJobIdSelected] = useState<string>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isRefreshJobList) return;
    handleGetListContractJob(paramObject);
    dispatch(jobActions.setRefreshList(false));
  }, [isRefreshJobList]);

  useEffect(() => {
    handleGetListContractJob(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (contractJobsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = contractJobsList.every(
      (contractJob: IContractJob) => contractJob.isSelected
    );
    setIsSelectAll(allSelected);

    const selectedIds = contractJobsList
      .filter((contractJob: IContractJob) => contractJob.isSelected)
      .map((contractJob: IContractJob) => contractJob.id);

    //@Todo: Perform interactions with the contract job id list
    console.log('Selected Contract Job IDs:', selectedIds);
  }, [contractJobsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListContractJob = (payload: IGetListJobReq) => {
    loadingData?.show();

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setContractJobsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddContractJob = () => {
    setIsShowAddContractJob(true);
  };

  const handleCloseAddContractJob = () => {
    setIsShowAddContractJob(false);
  };

  const changeServiceJobStatus = (status: ServiceJobStatusEnum) => {
    if (!contractJobIdSelected) return;

    const statusType: IChangeStatusJobPayload = { status };

    loadingData?.show();

    dispatch(changeStatusServiceJob({ id: contractJobIdSelected, status: statusType }))
      .unwrap()
      .then(() => {
        return dispatch(jobActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        if (isShowConfirmActive) {
          setIsShowConfirmActive(false);
        }

        if (isShowConfirmClosed) {
          setIsShowConfirmClosed(false);
        }

        if (isShowConfirmRejected) {
          setIsShowConfirmRejected(false);
        }
      });
  };

  const handleViewDetails = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.contractJobs}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.contractJobs}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.contractJobs}/${id}`);
        break;

      default:
        break;
    }
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IGetListJobReq = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSelectItem = (id: string) => {
    setContractJobsList((prevList: IContractJob[]) =>
      prevList.map((contractJob: IContractJob) =>
        contractJob.id === id
          ? { ...contractJob, isSelected: !contractJob.isSelected }
          : contractJob
      )
    );
  };

  const handleSelectAll = () => {
    setContractJobsList((prevList) =>
      prevList.map((contractJob: IContractJob) => ({
        ...contractJob,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleSearchContractJob = (value: string) => {
    setSearchKey(value);
  };

  const handleOnClosed = (id: string) => {
    setContractJobIdSelected(id);
    setIsShowConfirmClosed(true);
  };

  const handleOnRejected = (id: string) => {
    setContractJobIdSelected(id);
    setIsShowConfirmRejected(true);
  };

  const handleOnActive = (id: string) => {
    setContractJobIdSelected(id);
    setIsShowConfirmActive(true);
  };

  const handleCancelModal = () => {
    setContractJobIdSelected(EMPTY_STRING);

    if (isShowConfirmRejected) {
      setIsShowConfirmRejected(false);
    }

    if (isShowConfirmClosed) {
      setIsShowConfirmClosed(false);
    }

    if (isShowConfirmActive) {
      setIsShowConfirmActive(false);
    }
  };

  const handleActionModal = () => {
    if (!contractJobIdSelected) return;
    if (isShowConfirmClosed) {
      changeServiceJobStatus(ServiceJobStatusEnum.CLOSED);
    }

    if (isShowConfirmRejected) {
      changeServiceJobStatus(ServiceJobStatusEnum.REJECTED);
    }

    if (isShowConfirmActive) {
      changeServiceJobStatus(ServiceJobStatusEnum.ACTIVE);
    }
  };

  const handleCLickRow = (record: IContractJob) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.contractJobs}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.contractJobs}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.contractJobs}/${record.id}`);
        break;

      default:
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='manageContractJobsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_contract_jobs_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleAddContractJob,
          label: t('admin_manage_contract_jobs_btn_add'),
        }}
        onSearch={handleSearchContractJob}
      />

      <div className={cx('tableSection')}>
        <div className={cx('actions')}></div>

        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewDetails,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleOnClosed,
              handleOnRejected,
              handleOnActive
            )}
            dataSource={contractJobsList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowConfirmRejected && (
        <ConfirmModal
          title={t('common_modal_do_you_want_to_title', {
            name: t('common_action_rejected_contract_job'),
          })}
          titleAction={t('common_btn_reject')}
          onCancel={handleCancelModal}
          onAction={handleActionModal}
        />
      )}

      {isShowConfirmClosed && (
        <ConfirmModal
          title={t('common_modal_do_you_want_to_title', {
            name: t('common_action_closed_contract_job'),
          })}
          titleAction={t('common_btn_close')}
          onCancel={handleCancelModal}
          onAction={handleActionModal}
        />
      )}

      {isShowConfirmActive && (
        <ConfirmModal
          title={t('common_modal_do_you_want_to_title', {
            name: t('common_action_active'),
          })}
          titleAction={t('common_btn_active')}
          typeStyleAction={ButtonTypeEnum.SOLID_PRIMARY}
          onCancel={handleCancelModal}
          onAction={handleActionModal}
        />
      )}

      {isShowAddContractJob && <AdminAddContractJobModal onClose={handleCloseAddContractJob} />}
    </div>
  );
};

export default ManageContractJobs;
