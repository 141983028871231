// Others
import { urlApiQuickBook } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse, IQueryBase } from '~/utils/interface/common';
import {
  ICustomerQuickBook,
  IQueryGetTokenQuickBook,
  IQuickBookItem,
  IResponseGetAuthURL,
} from '~/utils/interface/quickBook';
import axiosClient from './axiosClient';

const quickBookApi = {
  getAuthUrl() {
    const url = `${urlApiQuickBook.getAuthUrl}`;
    return axiosClient.get<BaseResponse<IResponseGetAuthURL>>(url);
  },

  disconnect() {
    const url = `${urlApiQuickBook.disconnect}`;
    return axiosClient.post<BaseResponse>(url);
  },

  getTokenQuickBook(payload: IQueryGetTokenQuickBook) {
    const { code, state, realmId } = payload;
    const url = `${urlApiQuickBook.getToken}?code=${code}&state=${state}&realmId=${realmId}`;
    return axiosClient.get<BaseResponse>(url);
  },

  getListQuickBookCustomer(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListCustomer}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICustomerQuickBook[]>>>(url, {
      params: params,
    });
  },

  getListQuickBookItems(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListItem}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IQuickBookItem[]>>>(url, { params });
  },
};

export default quickBookApi;
