// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';

// Others
import { GET_DETAIL_PROFILE, UPDATE_PROFILE } from '~/utils/constants/actionType';
import profileApi from '~/apis/profile';

export const getDetailProfile = createAsyncThunk(
  GET_DETAIL_PROFILE,
  async (_, { rejectWithValue }) => {
    try {
      const res = await profileApi.getDetailProfile();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE,
  async (body: FormData, { rejectWithValue }) => {
    try {
      const res = await profileApi.updateProfile(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
