// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import moment from 'moment';
// Components, Layouts, Pages
// Others
import { INotification } from '~/utils/interface/notifications';
import { BusinessTypeEnum, NotificationStatusEnum } from '~/utils/enum';
import {
  EMPTY_STRING,
  HOURS_IN_A_DAY,
  MINUTES_IN_HOUR,
  ONE_SECOND,
  SECONDS_IN_A_MINUTE,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './Notification.module.scss';
import icons from '~/assets/icons';

type NotificationProps = {
  data: INotification;
  onClick: (notification: INotification) => void;
};

const cx = classNames.bind(styles);

const Notification = (props: NotificationProps) => {
  //#region Destructuring Props
  const { data, onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNotificationClicked = () => {
    onClick(data);
  };

  const renderIcon = (businessType: string) => {
    switch (businessType) {
      case BusinessTypeEnum.CONTRACT_JOB:
      case BusinessTypeEnum.SERVICE_JOB:
        return icons.commonIconNotificationJob;

      case BusinessTypeEnum.CLIENT:
      case BusinessTypeEnum.VENDOR:
        return icons.commonIconNotificationUser;

      case BusinessTypeEnum.INVOICE:
        return icons.commonIconNotificationInvoice;

      case BusinessTypeEnum.TASK:
      case BusinessTypeEnum.SCHEDULE:
        return icons.commonIconNotificationTask;

      case BusinessTypeEnum.PO:
        return icons.commonIconNotificationPO;

      default:
        break;
    }
  };

  const timeElapsed = (dateStr: string | null) => {
    if (!dateStr) return EMPTY_STRING;

    const now = moment();
    const past = moment(dateStr);
    const diffInSeconds = now.diff(past, 'seconds');
    const diffInMinutes = Math.floor(diffInSeconds / SECONDS_IN_A_MINUTE);
    const diffInHours = Math.floor(diffInMinutes / MINUTES_IN_HOUR);
    const pastDays = past.startOf('day');
    const diffInDays = now.diff(pastDays, 'days');

    if (diffInSeconds < ONE_SECOND) {
      return EMPTY_STRING;
    }

    if (diffInSeconds < SECONDS_IN_A_MINUTE) {
      return `${t('notification_seconds_ago_label', { time: diffInSeconds })}`;
    }

    if (diffInMinutes < MINUTES_IN_HOUR) {
      return `${t('notification_minutes_ago_label', { time: diffInMinutes })}`;
    }

    if (diffInHours < HOURS_IN_A_DAY) {
      return `${t('notification_hours_ago_label', { time: diffInHours })}`;
    }

    return `${t('notification_days_ago_label', { time: diffInDays })}`;
  };

  //#endregion Handle Function

  return (
    <div id='notificationComponent' onClick={handleNotificationClicked}>
      <div
        className={cx(
          'notificationContainer',
          data.status === NotificationStatusEnum.UNREAD && 'unRead'
        )}
      >
        <div className={cx('notificationImage')}>
          {renderIcon(data.businessType) && (
            <img
              src={renderIcon(data.businessType) || EMPTY_STRING}
              alt={t('common_image_notification')}
            />
          )}
        </div>

        <div className={cx('notificationContentBox')}>
          <div className={cx('notificationContent')}>
            <div className={cx('notificationTitleBox')}>
              <div className={cx('notificationTitle')}>{data.title}</div>
              <span className={cx('notificationTime')}>{timeElapsed(data.createdAt)}</span>
            </div>

            <div className={cx('notificationDescription')}>{data.content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
