// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { NotificationContent } from '~/components';
// Others
import { useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { DEFAULT_NUMBER_ZERO, MAX_LENGTH_NOTIFICATION } from '~/utils/constants/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './ReceivedNotification.module.scss';

type Props = {
  hasMore: boolean;
  onLoadMore: () => void;
};

const cx = classNames.bind(styles);

const ReceivedNotification = (props: Props) => {
  //#region Destructuring Props
  const { hasMore, onLoadMore } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  const { notificationsUnread } = useAppSelector((state: RootState) => state.notificationsState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderTotalNNotification = (total: number) => {
    if (total <= DEFAULT_NUMBER_ZERO) return String(DEFAULT_NUMBER_ZERO);

    if (total <= MAX_LENGTH_NOTIFICATION) return String(total);

    return `${String(MAX_LENGTH_NOTIFICATION)}+`;
  };
  //#endregion Handle Function

  return (
    <Popover>
      <div className={cx('receivedNotification')}>
        <PopoverButton className={cx('receivedNotificationBell')}>
          <img src={icons.adminIconHeaderNotification} alt={t('common_img_text_alt')} />

          {notificationsUnread !== DEFAULT_NUMBER_ZERO && (
            <div className={cx('receivedNotificationNumberWrap')}>
              <span className={cx('receivedNotificationNumber')}>
                {renderTotalNNotification(notificationsUnread)}
              </span>
            </div>
          )}
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom', gap: '8px' }}
          className={cx('receivedNotificationContainer')}
        >
          {({ close }) => (
            <NotificationContent hasMore={hasMore} onClose={close} onLoadMore={onLoadMore} />
          )}
        </PopoverPanel>
      </div>
    </Popover>
  );
};

export default ReceivedNotification;
