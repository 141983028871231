// Libs
import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';

// Components, Layouts, Pages
import {
  BaseImageCircle,
  BasePagination,
  BaseTable,
  InvoiceDetailModal,
  ModalUnderDevelopment,
  Status,
  ThreeDotOptions,
  Toolbar,
} from '~/components';

// Others
import { IGetListInvoiceReq, IInvoices } from '~/utils/interface/invoices';
import { BaseTableEnum, CurrencyEnum, WebSocketEventEnum } from '~/utils/enum';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ColumnType, IPagination, IQueryBase } from '~/utils/interface/common';
import { formatCurrency, getFullName } from '~/utils/helper';
import { LoadingData, WebsocketContext } from '~/context';
import { getListInvoice } from '~/thunks/invoice/invoiceThunk';
import useDebounce from '~/utils/hooks/useDebounce';
import { wssJoinInvoice, wssLeaveInvoice } from '~/utils/helpers/wss';

// Styles, images, icons
import styles from './Invoices.module.scss';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<'translation'>,
  handleViewInvoiceDetail: (id: string) => void,
  handleViewQuickBooks: (url: string) => void
): ColumnType<IInvoices>[] => {
  return [
    {
      key: 'id',
      title: t('invoices_table_label_invoice_id'),
      dataIndex: 'id',
      render: (_, record) => {
        return <div>{record?.id || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'clientName',
      title: t('invoices_table_label_client_name'),
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {record?.client?.firstName || record?.client?.lastName ? (
            <>
              <BaseImageCircle
                firstText={record?.client?.firstName}
                secondText={record?.client?.firstName}
                url={record?.client?.thumbnailUrl || record?.client?.avatarUrl}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record?.client?.firstName,
                lastName: record?.client?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'email',
      title: t('invoices_table_label_email'),
      render: (_, record) => {
        return <div>{record?.client.email || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'total',
      title: t('invoices_table_label_invoice_amount'),
      render: (_, record) => (
        <div>
          <span>
            {record?.total ? formatCurrency(CurrencyEnum.USD, record.total) : DEFAULT_CURRENCY}
          </span>
        </div>
      ),
    },
    {
      key: 'status',
      title: t('invoices_table_label_status'),
      render: (_, record) => {
        return record.status ? (
          <Status type='tag' status={record.status} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
    {
      key: 'action',
      title: t('invoices_table_label_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          // onView={() => handleViewInvoiceDetail(record.id)}
          onViewQuickBooks={() => handleViewQuickBooks(record.externalLinkQB)}
        />
      ),
    },
  ];
};

const ManageInvoices = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { wss, wsData } = useContext(WebsocketContext);
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [invoicesList, setInvoicesList] = useState<IInvoices[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowInvoiceDetail, setIsShowInvoiceDetail] = useState<boolean>(false);
  const [invoiceSelected, setInvoiceSelected] = useState<string>(EMPTY_STRING);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  // const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  // const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };

    setSearchParams(newParam);
    handleGetListInvoice(paramObject, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!wss) return;
    wssJoinInvoice(wss);

    return () => {
      wssLeaveInvoice(wss);
    };
  }, [wss]);

  useEffect(() => {
    if (!wsData) return;

    switch (wsData?.type) {
      case WebSocketEventEnum.SEND_INVOICE:
        handleGetListInvoice(paramObject);
        break;
      default:
        break;
    }
  }, [wsData]);
  //#endregion Implement Hook

  //#region Handle Function

  const handleShowModalUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangePagination = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
  };

  const handleViewInvoiceDetail = (id: string) => {
    setInvoiceSelected(id);
    setIsShowInvoiceDetail(true);
  };

  const handleGetListInvoice = (payload: IGetListInvoiceReq, displayLoading?: boolean) => {
    displayLoading && loadingData?.show();

    dispatch(getListInvoice(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;
        responses && setInvoicesList(responses);
        pagination && setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        displayLoading && loadingData?.hide();
      });
  };

  const handleCloseInvoiceDetail = () => {
    setInvoiceSelected(EMPTY_STRING);
    setIsShowInvoiceDetail(false);
  };

  const handleInvoiceSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleConnectQuickBook = (url: string) => {
    window.open(url);
  };

  const handleCLickRow = (record: IInvoices) => {
    window.open(record.externalLinkQB);
  };
  //#endregion Handle Function

  return (
    <div id='invoicesPage' className={cx('container')}>
      <Toolbar
        title={t('invoices_toolbar_title', {
          total: pagination?.totalItems || DEFAULT_NUMBER_ZERO,
        })}
        // primaryBtn={{
        //   action: handleSyncToQuickBooks,
        //   label: t('invoices_sync_to_quick_books_label'),
        // }}
        onSearch={handleInvoiceSearch}
      />

      <section className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(t, handleViewInvoiceDetail, handleConnectQuickBook)}
            dataSource={invoicesList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handleChangePagination}
          />
        </div>
      </section>

      {isShowModalUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
      )}

      <InvoiceDetailModal
        invoiceId={invoiceSelected}
        isOpen={isShowInvoiceDetail}
        onClose={handleCloseInvoiceDetail}
      />
    </div>
  );
};

export default ManageInvoices;
