// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { IJobDetails, IJobResp } from '~/utils/interface/job';
import {
  addNewJob,
  changeStatusServiceJob,
  convertToContractJob,
  createNewContractJob,
  createServiceJob,
  getActivityServiceJob,
  deleteServiceJob,
  getJobAssignees,
  getJobDetails,
  getListJob,
  getOptionFixtureLocations,
  getOptionProduct,
  updateServiceJob,
} from './jobThunk';
import { RootState } from '~/redux/store';
import { IProductOption } from '~/utils/interface/estimate';
import { IBaseOption } from '~/utils/interface/common';

export interface JobState {
  jobResp: IJobResp;
  jobDetails: IJobDetails;
  isRefreshJobList: boolean;
  isRefreshDetailContract: boolean;
  productMaterials?: IProductOption[];
  fixtureLocations?: IBaseOption[];
}

const initialState: JobState = {
  jobResp: {} as IJobResp,
  jobDetails: {} as IJobDetails,
  isRefreshJobList: false,
  isRefreshDetailContract: false,
};

const jobSlice = createSlice({
  name: 'jobState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshJobList = action.payload;
    },
    setRefreshDetailContract(state, action) {
      state.isRefreshDetailContract = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list job
    builder
      .addCase(getListJob.pending, (state) => {})
      .addCase(getListJob.fulfilled, (state, action) => {
        state.jobResp = action.payload.data;
      })
      .addCase(getListJob.rejected, (state) => {});

    // Get job details
    builder
      .addCase(getJobDetails.pending, (state) => {})
      .addCase(getJobDetails.fulfilled, (state, action) => {
        state.jobDetails = action.payload?.data;
      })
      .addCase(getJobDetails.rejected, (state) => {});

    // Add new job
    builder
      .addCase(addNewJob.pending, (state) => {})
      .addCase(addNewJob.fulfilled, (state, action) => {})
      .addCase(addNewJob.rejected, (state) => {});

    // Get job assignees
    builder
      .addCase(getJobAssignees.pending, (state) => {})
      .addCase(getJobAssignees.fulfilled, (state, action) => {})
      .addCase(getJobAssignees.rejected, (state) => {});

    // Create new contract job
    builder
      .addCase(createNewContractJob.pending, (state) => {})
      .addCase(createNewContractJob.fulfilled, (state, action) => {})
      .addCase(createNewContractJob.rejected, (state) => {});

    // Create Service job
    builder
      .addCase(createServiceJob.pending, (state) => {})
      .addCase(createServiceJob.fulfilled, (state, action) => {})
      .addCase(createServiceJob.rejected, (state) => {});

    // Delete Service Job
    builder
      .addCase(deleteServiceJob.pending, (state) => {})
      .addCase(deleteServiceJob.fulfilled, (state, action) => {})
      .addCase(deleteServiceJob.rejected, (state) => {});

    // Change Status Service Job
    builder
      .addCase(changeStatusServiceJob.pending, (state) => {})
      .addCase(changeStatusServiceJob.fulfilled, (state, action) => {})
      .addCase(changeStatusServiceJob.rejected, (state) => {});

    // Convert To Contract Job
    builder
      .addCase(convertToContractJob.pending, (state) => {})
      .addCase(convertToContractJob.fulfilled, (state, action) => {})
      .addCase(convertToContractJob.rejected, (state) => {});

    // Get logs activity service job
    builder
      .addCase(getActivityServiceJob.pending, (state) => {})
      .addCase(getActivityServiceJob.fulfilled, (state, action) => {})
      .addCase(getActivityServiceJob.rejected, (state) => {});

    //** Helper for flow Service job **//
    // Get options product by budget
    builder
      .addCase(getOptionProduct.pending, (state) => {})
      .addCase(getOptionProduct.fulfilled, (state, action) => {
        const dataRes = action.payload.responses;
        const productOptionList: IProductOption[] = dataRes.map((item) => ({
          label: item.name,
          value: item.id,
          unitCost: item.unitCost,
        }));
        state.productMaterials = productOptionList;
      })
      .addCase(getOptionProduct.rejected, (state) => {});

    // Get option fixture location
    builder
      .addCase(getOptionFixtureLocations.pending, (state) => {})
      .addCase(getOptionFixtureLocations.fulfilled, (state, action) => {
        const dataRes = action.payload.responses;
        const fixtureLocationOptionList: IBaseOption[] = dataRes.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        state.fixtureLocations = fixtureLocationOptionList;
      })
      .addCase(getOptionFixtureLocations.rejected, (state) => {});

    // Update service job
    builder
      .addCase(updateServiceJob.pending, (state) => {})
      .addCase(updateServiceJob.fulfilled, (state, action) => {})
      .addCase(updateServiceJob.rejected, (state) => {});
  },
});

export const jobActions = jobSlice.actions;

// Get list job
export const selectJobResp = (state: RootState) => state.job.jobResp;
export const selectJobDetails = (state: RootState) => state.job.jobDetails;

const jobReducer = jobSlice.reducer;

export default jobReducer;
