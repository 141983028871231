// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseSelect,
  BaseTextarea,
  FormInput,
  Loading,
  RadioBox,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getListClient } from '~/thunks/client/clientThunk';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { createNewContractJob } from '~/thunks/job/jobThunk';
import { jobActions } from '~/thunks/job/jobSlice';
import { IClient } from '~/utils/interface/client';
import { IBaseOption, IQueryBase } from '~/utils/interface/common';
import { ICreateNewContractJob } from '~/utils/interface/job';
import { IAccount, IQueryAccount } from '~/utils/interface/account';
import {
  contractJobGarageHandOptions,
  contractJobProjectTypeOptions,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsState,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  AccountTypeEnum,
  AddJobBusinessTypeEnum,
  ButtonTypeEnum,
  CreateNewContractJobEnum,
  StatusEnum,
} from '~/utils/enum';
import { getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './AdminAddContractJobModal.module.scss';

type Props = {
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      title: yup.string().required(t('admin_manage_contract_jobs_job_name_required')),
      clientId: yup.number().required(t('admin_manage_contract_jobs_client_name_required')),
      type: yup.string().required(t('admin_manage_contract_jobs_project_type_required')),
      planNumber: yup.string().required(t('admin_manage_contract_jobs_plan_number_required')),
      lotNo: yup.string().required(t('admin_manage_contract_jobs_lots_number_required')),
      elevation: yup.string().required(t('admin_manage_contract_jobs_elevation_required')),
      garageHand: yup.string().required(t('admin_manage_contract_jobs_garage_hand_required')),
      note: yup.string().required(t('admin_manage_contract_jobs_note_required')),
      managerId: yup.number().required(t('admin_manage_contract_jobs_project_manager_required')),
      dueDate: yup.string().optional(),
      estimatorId: yup.number().optional(),
      streetAddress: yup.string().required(t('admin_manage_contract_jobs_address_required')),
      city: yup.string().required(t('admin_manage_contract_jobs_city_required')),
      state: yup.string().required(t('admin_manage_contract_jobs_state_required')),
      zipCode: yup.string().required(t('admin_manage_contract_jobs_zip_code_required')),
      latitude: yup.number().optional(),
      longitude: yup.number().optional(),
      isSyncQuickBook: yup.boolean().required(),
      businessType: yup.string().required(),
      expiredProposalDate: yup.string().optional(),
    })
    .required();
};

const AdminAddContractJobModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<ICreateNewContractJob>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      businessType: AddJobBusinessTypeEnum.CONTRACT,
      isSyncQuickBook: false,
      garageHand: contractJobGarageHandOptions[DEFAULT_NUMBER_ZERO].value,
    },
  });

  const stateSelected = watch('state');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientOptions, setClientOptions] = useState<IClient[]>([]);
  const [managementOptions, setManagementOptions] = useState<IAccount[]>([]);
  const [estimatorOptions, setEstimatorOptions] = useState<IAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetClientList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payloadProjectManage: IQueryAccount = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [AccountRoleCodesEnum.PROJECT_MANAGER],
      status: StatusEnum.ACTIVE,
    };

    const payloadEstimator: IQueryAccount = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.TECHNICIAN,
        AccountRoleCodesEnum.PROJECT_MANAGER,
      ],
      status: StatusEnum.ACTIVE,
    };

    handleGetListProjectManage(payloadProjectManage);
    handleGetListEstimator(payloadEstimator);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const option = optionsState?.find((item) => item?.value === stateSelected);

    if (option) {
      setValue('latitude', option?.latitude);
      setValue('longitude', option?.longitude);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetClientList = () => {
    const payload: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    dispatch(getListClient(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;

        setClientOptions(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListProjectManage = (payload: IQueryAccount) => {
    if (!payload) return;

    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;

        setManagementOptions(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListEstimator = (payload: IQueryAccount) => {
    if (!payload) return;

    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;

        setEstimatorOptions(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleCloseModal = () => {
    onClose();
    reset();
    setIsLoading(false);
  };

  const handleFillAddress = (clientId: string) => {
    if (clientOptions?.length <= DEFAULT_NUMBER_ZERO || !clientId) return;

    const clientSelected = clientOptions.find((client) => client?.id === clientId);

    setValue('streetAddress', clientSelected?.address ?? EMPTY_STRING);
    setValue('city', clientSelected?.city ?? EMPTY_STRING);
    setValue('state', clientSelected?.state ?? EMPTY_STRING);
    setValue('zipCode', clientSelected?.zipCode ?? EMPTY_STRING);
  };

  const handleCreateNewContractJob = (data: ICreateNewContractJob) => {
    setIsLoading(true);

    dispatch(createNewContractJob(data))
      .unwrap()
      .then((res) => {
        handleCloseModal();
        return dispatch(jobActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='adminAddContractJobModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={handleCloseModal}
        /> */}

        <div className={cx('title')}>{t('admin_add_contract_job_title')}</div>

        <form className={cx('form')} onSubmit={handleSubmit(handleCreateNewContractJob)}>
          <div className={cx('body')}>
            <div className={cx('twoColumns')}>
              <Controller
                name={CreateNewContractJobEnum.NAME}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_contract_job_title_label')}
                    value={field.value || ''}
                    onChange={field.onChange}
                    required
                    errorMessage={errors.title?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.SYNC_WITH_QUICK_BOOKS}
                control={control}
                render={({ field }) => (
                  <div className={cx('asyncWithQuickBooks')}>
                    <BaseCheckbox
                      label={t('admin_add_contract_job_sync_with_quick_books_label')}
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
            </div>

            <div className={cx('twoColumns')}>
              <Controller
                name={CreateNewContractJobEnum.CLIENT}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    label={t('admin_add_contract_job_client_label')}
                    placeholder={t('common_placeholder_select')}
                    value={field.value?.toString()}
                    options={
                      clientOptions.map((item) => ({
                        label: getFullName({ ...item }),
                        value: item.id,
                      })) || []
                    }
                    onChange={({ value }: IBaseOption) => {
                      field.onChange(value);
                      handleFillAddress(value);
                    }}
                    isRequired
                    errorMessage={errors.clientId?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.PROJECT_TYPE}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    label={t('admin_add_contract_job_project_type_label')}
                    placeholder={t('common_placeholder_select')}
                    value={field.value}
                    options={contractJobProjectTypeOptions || []}
                    onChange={({ value }: IBaseOption) => {
                      field.onChange(value);
                    }}
                    isRequired
                    errorMessage={errors.type?.message}
                  />
                )}
              />
            </div>

            <div className={cx('groupTitle')}>{t('admin_add_contract_group_detail')}</div>

            <div className={cx('threeColumns')}>
              <Controller
                name={CreateNewContractJobEnum.PLAN_NUMBER}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_contract_job_plan_number_label')}
                    value={field.value?.toString() || ''}
                    type='number'
                    onChange={field.onChange}
                    required
                    errorMessage={errors.planNumber?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.LOT_NUMBER}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_contract_job_lot_no_label')}
                    value={field.value?.toString() || ''}
                    type='number'
                    onChange={field.onChange}
                    required
                    errorMessage={errors.lotNo?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.ELEVATION}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_contract_job_elevation_label')}
                    value={field.value || ''}
                    type='number'
                    onChange={field.onChange}
                    required
                    errorMessage={errors.elevation?.message}
                  />
                )}
              />
            </div>

            <div className={cx('garageHand')}>
              <Controller
                name={CreateNewContractJobEnum.GARAGE_HAND}
                control={control}
                render={({ field }) => (
                  <RadioBox
                    label={t('admin_add_contract_job_garage_hand_label')}
                    value={field.value}
                    options={contractJobGarageHandOptions}
                    onChange={field.onChange}
                    required
                    errorMessage={errors.garageHand?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoColumns')}>
              <Controller
                name={CreateNewContractJobEnum.ADDRESS}
                control={control}
                render={({ field }) => (
                  <FormInput
                    required
                    label={t('admin_add_contract_job_street_address_label')}
                    value={field.value || ''}
                    onChange={field.onChange}
                    errorMessage={errors.streetAddress?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.CITY}
                control={control}
                render={({ field }) => (
                  <FormInput
                    required
                    label={t('admin_add_contract_job_city_label')}
                    value={field.value || ''}
                    onChange={field.onChange}
                    errorMessage={errors.city?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.STATE}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    isRequired
                    label={t('admin_add_contract_job_state_label')}
                    placeholder={t('common_placeholder_select')}
                    value={field.value}
                    options={optionsState || []}
                    onChange={(option: IBaseOption) => {
                      field.onChange(option.value);
                    }}
                    errorMessage={errors.state?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.ZIP}
                control={control}
                render={({ field }) => (
                  <FormInput
                    required
                    label={t('admin_add_contract_job_zip_label')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.zipCode?.message}
                  />
                )}
              />
            </div>

            <Controller
              name={CreateNewContractJobEnum.GENERAL_NOTE}
              control={control}
              render={({ field }) => (
                <BaseTextarea
                  label={t('admin_add_contract_job_add_note_label')}
                  value={field.value || ''}
                  gap={10}
                  height={120}
                  px={10}
                  py={10}
                  onTextareaChange={field.onChange}
                  required
                  errorMessage={errors.note?.message}
                />
              )}
            />

            <div className={cx('groupTitle')}>{t('admin_add_contract_group_other')}</div>

            <div className={cx('twoColumns')}>
              <Controller
                name={CreateNewContractJobEnum.PROJECT_MANAGER}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    label={t('admin_add_contract_job_project_manager_label')}
                    placeholder={t('common_placeholder_select')}
                    value={field.value?.toString()}
                    options={
                      managementOptions.map((item) => ({
                        label: getFullName({ ...item }),
                        value: item.id?.toString(),
                      })) || []
                    }
                    onChange={({ value }: IBaseOption) => {
                      field.onChange(value);
                    }}
                    isRequired
                    errorMessage={errors.managerId?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.BID_DUE_DATE}
                control={control}
                render={({ field }) => (
                  <BaseDatePicker
                    label={t('admin_add_contract_job_bid_due_date_label')}
                    value={field.value}
                    onDateSelected={field.onChange}
                    errorMessage={errors.dueDate?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoColumns')}>
              <Controller
                name={CreateNewContractJobEnum.ESTIMATOR}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    label={t('admin_add_contract_job_estimator_label')}
                    placeholder={t('common_placeholder_select')}
                    value={field.value?.toString()}
                    options={
                      estimatorOptions.map((item) => ({
                        label: getFullName({ ...item }),
                        value: item.id?.toString(),
                      })) || []
                    }
                    onChange={({ value }: IBaseOption) => {
                      field.onChange(value);
                    }}
                    errorMessage={errors.estimatorId?.message}
                  />
                )}
              />

              <Controller
                name={CreateNewContractJobEnum.EXPIRED_PROPOSAL_DATE}
                control={control}
                render={({ field }) => (
                  <BaseDatePicker
                    label={t('admin_add_contract_job_expired_proposal_date_label')}
                    value={field.value}
                    onDateSelected={field.onChange}
                    isShowTooltip={true}
                    errorMessage={errors.dueDate?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('actions')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={handleCloseModal}
                typeStyle={ButtonTypeEnum.CANCEL}
              />
            </div>

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
            />
          </div>
        </form>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminAddContractJobModal;
