// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { ProductSpreadsheet, Toolbar } from '~/components';
// Others
import { dataViewExcel, mockDataFixtureLocationBudget, mockDataProductBudget } from '~/mockData';
import { IBudgetCategory } from '~/utils/interface/common';
import { TProductGridColumn } from '~/utils/type/common';
import { DEFAULT_BUDGET_CATEGORY_EXCEL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ViewExcel.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction): TProductGridColumn<IBudgetCategory>[] => {
  return [
    {
      columnId: 'productName',
      dataIndex: 'productName',
      name: t('view_excel_table_title_item'),
      render: (value) => {
        // return { type: 'text', text: value?.toString() || EMPTY_STRING };

        return {
          type: 'dropdown',
          selectedValue: value?.toString(),
          values: mockDataProductBudget,
        };
      },
      width: 200,
    },
    {
      columnId: 'fixtureLocationName',
      dataIndex: 'fixtureLocationName',
      name: t('view_excel_table_title_fixture_location'),
      render: (value) => {
        // return { type: 'text', text: value?.toString() || EMPTY_STRING };

        return {
          type: 'dropdown',
          selectedValue: value?.toString(),
          values: mockDataFixtureLocationBudget,
        };
      },
      width: 200,
    },
    {
      columnId: 'quantity',
      dataIndex: 'quantity',
      name: t('view_excel_table_title_quantity'),
      render: (value) => {
        return { type: 'number', value: Number(value) };
      },
      width: 200,
    },
    {
      columnId: 'unitCost',
      dataIndex: 'unitCost',
      name: t('view_excel_table_title_unit_cost'),
      render: (value) => {
        return { type: 'number', value: Number(value) };
      },
      width: 200,
      disabled: true,
    },
    {
      columnId: 'subTotal',
      dataIndex: 'subTotal',
      name: t('view_excel_table_title_subtotal'),
      render: (value) => {
        return { type: 'number', value: Number(value) };
      },
      width: 200,
      disabled: true,
    },
  ];
};

const ViewExcel = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [data, setData] = useState<IBudgetCategory[]>(dataViewExcel);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newArr = data.map((item) => ({ ...item, subTotal: item.quantity * item.unitCost }));
    setData(newArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const hasProductSpreadsheetChanged = (
    currentData: IBudgetCategory[],
    newData: IBudgetCategory[]
  ): boolean => {
    if (currentData.length !== newData.length) return true;

    return currentData?.some((item, index) => {
      const other = newData[index];

      return (
        item?.productName !== other?.productName ||
        item?.fixtureLocationName !== other?.fixtureLocationName ||
        item?.quantity !== other?.quantity ||
        item?.unitCost !== other?.unitCost ||
        item?.subTotal !== other?.subTotal
      );
    });
  };

  const handleDataChange = (updatedData: IBudgetCategory[]) => {
    const recalculatedData = updatedData.map((item) => {
      const selectedOption = mockDataProductBudget.find(
        (option) => option.value === item.productName
      );
      const unitCost = selectedOption ? selectedOption.unitCost : item.unitCost;
      const subTotal = item.quantity * unitCost;

      return {
        ...item,
        unitCost,
        subTotal,
      };
    });

    if (hasProductSpreadsheetChanged(recalculatedData, data)) {
      setData(recalculatedData);
    }
  };
  //#endregion Handle Function

  return (
    <div id='viewExcelPage' className={cx('container')}>
      <Toolbar title={t('contract_job_beta_title')} />

      <div className={cx('body')}>
        <div className={cx('bodyScroll')}>
          <div className={cx('section')}>
            <ProductSpreadsheet
              title={t('template_add_estimate_label_category_material')}
              columns={columns(t)}
              dataSource={data}
              defaultDataSource={DEFAULT_BUDGET_CATEGORY_EXCEL}
              onChange={handleDataChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewExcel;
