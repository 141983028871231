// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { NEUTRAL400 } from '~/utils/constants/color';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '~/utils/constants/component';
import { EMPTY_STRING } from '~/utils/constants/common';
import { getInitialsName } from '~/utils/helper';
// Styles, images, icons
import styles from './BaseImageCircle.module.scss';

type Props = {
  url?: string;
  firstText?: string;
  secondText?: string;
  width?: number | string;
  height?: number | string;
  fontSize?: number;
  backgroundColor?: string;
  margin?: number | string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const cx = classNames.bind(styles);

const BaseImageCircle = (props: Props) => {
  //#region Destructuring Props
  const {
    url,
    firstText,
    secondText,
    width = DEFAULT_IMAGE_WIDTH,
    height = DEFAULT_IMAGE_HEIGHT,
    fontSize,
    backgroundColor = NEUTRAL400,
    margin,
    onMouseEnter,
    onMouseLeave,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const commonProps = {
    className: cx('avatar'),
    style: { width, height, margin, backgroundColor },
    onMouseEnter,
    onMouseLeave,
  };
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (url) {
      setImageUrl(url);
      setIsImageError(false);
    }

    if (!url) {
      setImageUrl(EMPTY_STRING);
    }
  }, [url]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleImageError = () => {
    setIsImageError(true);
  };

  const handleImageSuccess = () => {
    setIsImageError(false);
  };
  //#endregion Handle Function

  return isImageError || !imageUrl ? (
    <div {...commonProps} style={{ ...commonProps.style, fontSize }}>
      {getInitialsName(firstText, secondText)}
    </div>
  ) : (
    <img
      {...commonProps}
      alt={t('common_img_text_alt')}
      src={imageUrl}
      onError={handleImageError}
      onLoad={handleImageSuccess}
    />
  );
};

export default BaseImageCircle;
