// Libs
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// Components, Layouts, Pages
import { DatePickerFieldControl } from '~/components';
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
import { DatePickerFieldControlProps } from '~/components/common/baseDatePicker/fieldControl/DatePickerFieldControl';
import { generateGuid } from '~/utils/helper';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
// Styles, images, icons
import 'react-datepicker/dist/react-datepicker.css';
import './BaseDatePicker.scss';
import { icons } from '~/assets';

type Props = Omit<DatePickerFieldControlProps, 'value' | 'label'> & {
  value?: Date | string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  isShowTooltip?: boolean;
  onDateSelected?: (date: string, name?: string) => void;
};

const BaseDatePicker = (props: Props) => {
  //#region Destructuring Props
  const {
    value,
    label,
    placeholderText,
    isRequired,
    width,
    height,
    errorMessage,
    name,
    disabled = false,
    isShowTooltip = false,
    onDateSelected,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      return;
    }

    const date = moment(value).toDate();
    setSelectedDate(date);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSelectDate = (date: Date | null) => {
    if (!date) return;
    const dateString = moment(date).format('YYYY-MM-DD');

    setSelectedDate(date);
    onDateSelected && onDateSelected(dateString, name ?? '');
  };

  const uniqueId = `${generateGuid()}`;
  //#endregion Handle Function

  return (
    <div id='baseDatePickerComponent' className='baseDatePickerContainer' style={{ width }}>
      {label && (
        <div className='labelField'>
          <div className='labelFieldContainer'>
            <span className='valueLabel'>{label}</span>
            {isRequired && <span className={'viewStar'}>{ASTERISK_SYMBOL}</span>}

            {isShowTooltip && (
              <div
                key={uniqueId}
                data-tooltip-id={uniqueId}
                data-tooltip-place='top'
                data-tooltip-variant='light'
              >
                <img
                  src={icons.commonIconQuestion}
                  alt={t('common_img_text_alt')}
                  className='iconQuestion'
                />

                <Tooltip id={uniqueId} className='tooltipWrap'>
                  <span className='tooltipText'>
                    {t('admin_add_contract_job_expired_proposal_date_tooltip')}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}

      <div className='baseDatePickerContent'>
        <DatePicker
          onSelect={handleSelectDate}
          selected={selectedDate}
          showPopperArrow={false}
          disabled={disabled}
          customInput={
            <DatePickerFieldControl
              width={width}
              height={height}
              placeholderText={placeholderText}
              errorMessage={errorMessage}
            />
          }
        />
      </div>
    </div>
  );
};

export default BaseDatePicker;
