// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  ActivityContainer,
  ActivityContent,
  ActivityDot,
  ActivityLine,
  ActivitySeparator,
  ActivityShortContent,
  DetailLogActivityModal,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getActivityServiceJob } from '~/thunks/job/jobThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ActionIconEnum, DateFormatEnum, TimeFormatEnum, TypeJobEnum } from '~/utils/enum';
import { getFullName, renderLabelActionType } from '~/utils/helper';
import { IActivity } from '~/utils/interface/activity';
import { IQueryBase } from '~/utils/interface/common';
import { IQueryLogsActivity } from '~/utils/interface/job';
import { selectJobDetails } from '~/thunks/job/jobSlice';
// Styles, images, icons
import styles from './JobLogsTab.module.scss';
import { icons } from '~/assets';

type Props = {
  typeJob: TypeJobEnum;
};

const cx = classNames.bind(styles);

const JobLogsTab = (props: Props) => {
  //#region Destructuring Props
  const { typeJob } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useSelector(selectJobDetails);
  //#endregion Selector

  //#region Declare State
  const [params, setParams] = useState<IQueryBase>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [activityList, setActivityList] = useState<IActivity[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const [detailActivity, setDetailActivity] = useState<IActivity | null>();
  //#endregion Declare State

  //#region Declare useMemo
  const groupedActivities = useMemo(() => {
    if (!activityList || activityList?.length <= 0) return [];
    const grouped: Record<string, typeof activityList> = {};

    activityList.forEach((activity) => {
      const activityDate = moment(activity.createdAt).format(DateFormatEnum['YYYY-MM-DD']);
      if (!grouped[activityDate]) {
        grouped[activityDate] = [];
      }
      grouped[activityDate].push(activity);
    });

    return Object.entries(grouped).map(([date, activities]) => ({ date, activities }));
  }, [activityList]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    if (!params) return;
    fetchActivity(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchActivity = async (params: IQueryBase) => {
    if (!jobId || !params || !typeJob) return;
    const payload: IQueryLogsActivity = { jobId, typeJob, ...params };

    try {
      const resp = await dispatch(getActivityServiceJob(payload)).unwrap();
      if (!resp?.data) return;
      const { responses } = resp.data;
      setActivityList((prev) =>
        params.page === DEFAULT_CURRENT_PAGE ? [...responses] : [...prev, ...responses]
      );

      if (responses.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {}
  };

  const loadMore = () => {
    setParams((prev: IQueryBase) => ({ ...prev, page: prev.page + 1 }));
  };

  const closeDetail = () => {
    setDetailActivity(null);
    setIsShowDetail(false);
  };

  const openDetail = (data: IActivity) => {
    setDetailActivity(data);
    setIsShowDetail(true);
  };

  const renderIcon = (actionType: string) => {
    let icon;
    let actionIconName;

    switch (true) {
      case actionType.includes(ActionIconEnum.CREATED):
        icon = icons.adminIconCreateLogsTabServiceJob;
        actionIconName = ActionIconEnum.CREATED;
        break;

      case actionType.includes(ActionIconEnum.UPLOADED):
        icon = icons.adminIconDocumentLogsTabServiceJob;
        actionIconName = ActionIconEnum.UPLOADED;
        break;

      case actionType.includes(ActionIconEnum.CHANGED):
        icon = icons.adminIconStatusLogsTabServiceJob;
        actionIconName = ActionIconEnum.CHANGED;
        break;

      case actionType.includes(ActionIconEnum.SENT):
        icon = icons.adminIconInvoiceLogsTabServiceJob;
        actionIconName = ActionIconEnum.SENT;
        break;

      case actionType.includes(ActionIconEnum.CONVERTED):
        icon = icons.adminIconConventContractLogsTabServiceJob;
        actionIconName = ActionIconEnum.CONVERTED;
        break;

      case actionType.includes(ActionIconEnum.UPDATED):
        icon = icons.commonIconEdit;
        actionIconName = ActionIconEnum.UPDATED;
        break;

      case actionType.includes(ActionIconEnum.DELETED):
        icon = icons.commonIconTrash;
        actionIconName = ActionIconEnum.DELETED;
        break;

      default:
        break;
    }

    return (
      <div className={cx('dotLogWrap', actionIconName?.toLowerCase())}>
        {actionIconName && (
          <img className={cx('actionIcon')} src={icon} alt={t('common_img_text_alt')} />
        )}
      </div>
    );
  };
  //#endregion Handle Function

  return (
    <div id='serviceJobLogsTab' className={cx('container')}>
      <InfiniteScroll
        dataLength={activityList.length}
        next={loadMore}
        hasMore={hasMore}
        height={500}
        loader={EMPTY_STRING}
      >
        <>
          {groupedActivities.map(({ date, activities }) => (
            <Fragment key={date}>
              <ActivityContainer>
                <ActivityShortContent width={150}>
                  <div className={cx('dateGroup')}>
                    {moment(date).format(DateFormatEnum['DD MM YYYY'])}
                  </div>
                </ActivityShortContent>

                <ActivitySeparator>
                  <ActivityDot />
                  <ActivityLine />
                </ActivitySeparator>
              </ActivityContainer>

              {activities.map((activity, index) => (
                <ActivityContainer key={index}>
                  <ActivityShortContent width={150}>
                    <div className={cx('timeLog')}>
                      {moment(activity.createdAt).format(TimeFormatEnum['h:mm a'])}
                    </div>
                  </ActivityShortContent>

                  <ActivitySeparator>
                    <ActivityDot>{renderIcon(activity.actionType)}</ActivityDot>

                    {index !== activities.length - 1 && <ActivityLine />}
                  </ActivitySeparator>

                  <ActivityContent>
                    <div className={cx('mainContent')}>
                      <div className={cx('content')}>
                        {renderLabelActionType(
                          activity.actionModel,
                          activity.actionType,
                          jobDetails.name
                        ) || EMPTY_STRING}
                      </div>
                      <div className={cx('subContentGroup')}>
                        <div className={cx('createBy')}>
                          {getFullName({
                            firstName: activity?.createdBy?.firstName,
                            lastName: activity?.createdBy?.lastName,
                          })}
                        </div>
                        <button
                          type='button'
                          className={cx('btnDetail')}
                          onClick={() => openDetail(activity)}
                        >
                          {t('admin_tab_logs_service_job_label_view_detail')}
                        </button>
                      </div>
                    </div>
                  </ActivityContent>
                </ActivityContainer>
              ))}
            </Fragment>
          ))}
        </>
      </InfiniteScroll>

      <DetailLogActivityModal isOpen={isShowDetail} onClose={closeDetail} data={detailActivity} />
    </div>
  );
};

export default JobLogsTab;
