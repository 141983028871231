// Libs
import classNames from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment, MutableRefObject, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { Notification } from '~/components';
// Others
import {
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NEW_NOTIFICATION_THRESHOLD,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  BusinessTypeEnum,
  BusinessTypeJobEnum,
  DateFormatEnum,
  StorageEnum,
} from '~/utils/enum';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { notificationActions } from '~/thunks/notifications/notificationsSlice';
import { markedReadNotification } from '~/thunks/notifications/notificationsThunk';
import { INotification } from '~/utils/interface/notifications';
// Styles, images, icons
import styles from './NotificationContent.module.scss';

type Props = {
  hasMore: boolean;
  onLoadMore: () => void;
  onClose: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;
};

const cx = classNames.bind(styles);

const NotificationContent = (props: Props) => {
  //#region Destructuring Props
  const { hasMore, onLoadMore, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { notificationList } = useAppSelector((state: RootState) => state.notificationsState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Declare useMemo
  const newNotification = useMemo(() => {
    const list = notificationList.filter((notificationItem: INotification) => {
      const createdAt = moment(notificationItem.createdAt);
      const diffInMinutes = moment().diff(moment(createdAt), 'minutes');
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      return isToday && diffInMinutes < NEW_NOTIFICATION_THRESHOLD;
    });

    return list;
  }, [notificationList]);

  const todayNotification = useMemo(() => {
    const list = notificationList.filter((notificationItem: INotification) => {
      const createdAt = moment(notificationItem.createdAt);
      const diffInMinutes = moment().diff(moment(createdAt), 'minutes');
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      return isToday && diffInMinutes >= NEW_NOTIFICATION_THRESHOLD;
    });

    return list;
  }, [notificationList]);

  const groupedOtherNotification = useMemo(() => {
    const grouped: Record<string, typeof notificationList> = {};

    notificationList.forEach((notificationItem) => {
      const createdAt = moment(notificationItem.createdAt);
      const formattedDate = moment(createdAt).format(DateFormatEnum['DD MM YYYY']);
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      if (isToday) return;

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(notificationItem);
    });

    return Object.entries(grouped).map(([date, notifications]) => ({ date, notifications }));
  }, [notificationList]);
  //#endregion Declare useMemo

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNotificationClicked = async (notification: INotification, closeFn: Function) => {
    if (!notification?.id) return;

    try {
      await dispatch(markedReadNotification(notification.id)).unwrap();
      handleShowDetail(notification);

      closeFn();
    } catch (error) {}
  };

  const handleShowDetail = (notification: INotification) => {
    if (!notification || !role) return;
    const { businessId, businessType, metaData } = notification;

    switch (businessType) {
      case BusinessTypeEnum.CONTRACT_JOB:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.contractJobs}/${businessId}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.contractJobs}/${businessId}`);
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.contractJobs}/${businessId}`);
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.SERVICE_JOB:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.serviceJobs}/${businessId}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.serviceJobs}/${businessId}`);
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.serviceJobs}/${businessId}`);
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.CLIENT:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.clients}`, { state: { clientId: businessId } });
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.clients}`, { state: { clientId: businessId } });
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.clients}`, { state: { clientId: businessId } });
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.VENDOR:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.manageVendors}`, {
              state: { vendorIdSate: businessId },
            });
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.manageVendors}`, {
              state: { vendorIdSate: businessId },
            });
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.manageVendors}`, {
              state: { vendorIdSate: businessId },
            });
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.PO:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.purchaseOrder}/${businessId}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.purchaseOrder}/${businessId}`);
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.purchaseOrder}/${businessId}`);
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.TASK:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.task}`, { state: { taskIdSate: businessId } });
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.task}`, { state: { taskIdSate: businessId } });
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.task}`, { state: { taskIdSate: businessId } });
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.INVOICE:
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.invoices}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.invoices}`);
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.invoices}`);
            break;

          default:
            break;
        }
        return;

      case BusinessTypeEnum.SCHEDULE:
        if (!metaData) return;
        const { jobId, jobType } = metaData;
        let baseRoute = null;

        if (role === AccountRoleCodesEnum.ADMIN) {
          baseRoute = adminRouteAbsolute;
        } else if (role === AccountRoleCodesEnum.STAFF) {
          baseRoute = staffRouteAbsolute;
        } else if (role === AccountRoleCodesEnum.PROJECT_MANAGER) {
          baseRoute = projectManageRouteAbsolute;
        }

        if (baseRoute) {
          const jobRoute =
            jobType === BusinessTypeJobEnum.CONTRACT
              ? baseRoute.contractJobs
              : baseRoute.serviceJobs;

          navigate(`${jobRoute}/${jobId}`);
        }
        return;

      default:
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='notificationContentComponent'>
      <div className={cx('arrow')} />
      <div className={cx('titleGroup')}>
        <span className={cx('notificationTitle')}>{t('common_notification_title')}</span>
      </div>

      <InfiniteScroll
        dataLength={notificationList.length}
        next={onLoadMore}
        hasMore={hasMore}
        height={450}
        loader={EMPTY_STRING}
      >
        <div className={cx('receivedNotificationScroll')}>
          {notificationList && notificationList.length > DEFAULT_NUMBER_ZERO ? (
            <div className={cx('receivedNotificationItem')}>
              {newNotification.length > 0 && (
                <div className={cx('notificationGroup')}>
                  <div className={cx('notificationGroupTitle')}>{t('common_notification_new')}</div>
                  <div className={cx('receivedNotificationList')}>
                    {newNotification.map((notification: INotification, index: number) => (
                      <Notification
                        key={index}
                        data={notification}
                        onClick={() => handleNotificationClicked(notification, onClose)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {todayNotification.length > DEFAULT_NUMBER_ZERO && (
                <div className={cx('notificationGroup')}>
                  <div className={cx('notificationGroupTitle')}>
                    {t('common_notification_today')}
                  </div>
                  <div className={cx('receivedNotificationList')}>
                    {todayNotification.map((notification: INotification, index: number) => (
                      <Notification
                        key={index}
                        data={notification}
                        onClick={() => handleNotificationClicked(notification, onClose)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {groupedOtherNotification.map(({ date, notifications }) => (
                <Fragment key={date}>
                  <div className={cx('notificationGroup')}>
                    <div className={cx('notificationGroupTitle')}>{date}</div>
                    <div className={cx('receivedNotificationList')}>
                      {notifications.map((notification: INotification, index: number) => (
                        <Notification
                          key={index}
                          data={notification}
                          onClick={() => handleNotificationClicked(notification, onClose)}
                        />
                      ))}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className={cx('noData')}>{t('common_no_notification')}</div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default NotificationContent;
