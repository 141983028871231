// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect, useContext } from 'react';
import { TFunction } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseImageCircle,
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  Toolbar,
  FormDocumentModal,
  ConfirmModal,
} from '~/components';
// Context
import { LoadingData } from '~/context';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { IJobDocument } from '~/utils/interface/job';
import { ColumnType, IPagination, IQueryBase, MoreActionItem } from '~/utils/interface/common';
import {
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  EMPTY_VALUE,
  OBJECT,
} from '~/utils/constants/common';
import { IFileDocumentJobInfo, IGetDocumentJobReq } from '~/utils/interface/document';
import { BaseTableEnum, ManageJobsTabsEnum } from '~/utils/enum';
import {
  convertToTitleCase,
  formattedTime,
  getAvatarWithName,
  getFileNameFromUri,
  getFullName,
} from '~/utils/helper';
import {
  deleteDocumentJob,
  downloadDocumentJob,
  getListDocumentJob,
} from '~/thunks/document/documentThunk';
import { documentActions, selectIsRefreshListDocument } from '~/thunks/document/documentSlice';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import styles from './JobDocumentsTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleDownloadDocument: (item: IFileDocumentJobInfo) => void,
  handleUpdateDocument: (item: IJobDocument) => void,
  handDeleteDocument: (item: IJobDocument) => void
): ColumnType<IJobDocument>[] => {
  return [
    {
      key: 'name',
      title: t('admin_manage_jobs_tabs_documents_table_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'type',
      title: t('admin_manage_jobs_tabs_documents_table_type'),
      dataIndex: 'type',
      render: (_, record) => <span>{convertToTitleCase(record.type) || EMPTY_VALUE}</span>,
    },
    {
      key: 'creator',
      title: t('admin_manage_jobs_tabs_documents_table_created_by'),
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName(
            { firstName: record?.creator?.firstName, lastName: record?.creator?.lastName },
            record?.creator?.thumbnail
          ) ? (
            <>
              <BaseImageCircle
                url={record?.creator?.thumbnail ?? record?.creator?.avatar}
                firstText={record?.creator?.firstName}
                secondText={record?.creator?.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record?.creator?.firstName,
                lastName: record?.creator?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'createdAt',
      title: t('admin_manage_jobs_tabs_documents_table_created'),
      render: (_, record) => (
        <span>
          {record?.createdAt ? (
            <div className={cx('date')}>
              <img
                src={icons.commonIconCalender}
                alt={t('common_img_text_alt')}
                className={cx('icon')}
              />
              <span>{formattedTime(record?.createdAt)}</span>
            </div>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </span>
      ),
    },
    {
      key: 'lastUpdated',
      title: t('admin_manage_jobs_tabs_documents_table_last_update'),

      render: (_, record) => (
        <span>
          {record?.lastUpdated ? (
            <div className={cx('date')}>
              <img
                src={icons.commonIconCalender}
                alt={t('common_img_text_alt')}
                className={cx('icon')}
              />
              <span>{formattedTime(record?.lastUpdated)}</span>
            </div>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </span>
      ),
    },
    {
      key: 'remark',
      title: t('admin_manage_jobs_tabs_documents_table_remark'),
      render: (_, record) => {
        return record?.remark ? (
          <span>{convertToTitleCase(record.remark)}</span>
        ) : (
          <div>{EMPTY_VALUE}</div>
        );
      },
    },
    {
      key: 'action',
      title: t('admin_manage_jobs_tabs_documents_table_action'),
      width: '10%',
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_download'),
            icon: (
              <img
                src={icons.commonIconDownload}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () =>
              handleDownloadDocument({
                documentId: record?.fileId,
                documentExtension: 'application/msword',
                documentName: getFileNameFromUri(record?.documentUrl ?? EMPTY_STRING),
              }),
          },
          {
            label: t('common_btn_update'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleUpdateDocument(record),
          },
          {
            label: t('common_btn_delete'),
            hasOtherColor: true,
            icon: (
              <img
                src={icons.commonIconTrash}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handDeleteDocument(record),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const JobDocumentsTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshListDocument = useAppSelector(selectIsRefreshListDocument);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  const [documentsList, setDocumentsList] = useState<IJobDocument[]>([]);
  const [isShowModalDocument, setIsShowModalDocument] = useState<boolean>(false);
  const [itemDocuments, setItemDocuments] = useState<IJobDocument>();
  const [isShowModalEditDocument, setIsShowModalEditDocument] = useState<boolean>(false);
  const [isShowDeleteDocument, setIsShowDeleteDocument] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!jobId) return;

    const payload: IGetDocumentJobReq = {
      ...paramObject,
      jobId: jobId,
    };

    handleGetListDocument(payload);

    if (isRefreshListDocument) {
      dispatch(documentActions.setRefreshListDocument(false));
    }
  }, [paramObject, isRefreshListDocument]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });

    const newParam: Record<string, string> = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : {
          tab: ManageJobsTabsEnum.DOCUMENTS,
        };

    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListDocument = (payload: IGetDocumentJobReq) => {
    loading?.show();

    dispatch(getListDocumentJob(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setDocumentsList(res?.data?.responses);
        setPagination(res?.data?.pagination);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleDocumentsSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleShowModalDocument = () => {
    setIsShowModalDocument(true);
  };

  const handleCloseModalDocument = () => {
    isShowModalDocument && setIsShowModalDocument(false);
    isShowModalEditDocument && setIsShowModalEditDocument(false);
  };

  const handleDownloadDocument = (item: IFileDocumentJobInfo) => {
    loading?.show();

    dispatch(downloadDocumentJob(item.documentId))
      .unwrap()
      .then((res) => {
        const data: ArrayBuffer = res.data;

        if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
          const array: number[] = Object.values(data);
          const uint8Array: Uint8Array = new Uint8Array(array);
          const blob: Blob = new Blob([uint8Array]);

          const url: string = window.URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
          link.href = url;
          link.download = item.documentName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleUpdateDocument = (item: IJobDocument) => {
    setItemDocuments(item);
    setIsShowModalEditDocument(true);
  };

  const handDeleteDocument = (item: IJobDocument) => {
    setIsShowDeleteDocument(true);
    setItemDocuments(item);
  };

  const handleShowModalUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleDeleteDocument = () => {
    if (!itemDocuments?.id) return;
    loading?.show();

    dispatch(deleteDocumentJob(itemDocuments.id))
      .unwrap()
      .then((res) => {
        handleCloseModelDelete();
        dispatch(documentActions.setRefreshListDocument(true));
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCloseModelDelete = () => {
    isShowDeleteDocument && setIsShowDeleteDocument(false);
  };

  const handleCLickRow = (record: IJobDocument) => {
    setItemDocuments(record);
    setIsShowModalEditDocument(true);
  };
  //#endregion Handle Function

  return (
    <div id='jobDocumentsTab' className={cx('container')}>
      <Toolbar
        onSearch={handleDocumentsSearch}
        primaryBtn={{
          action: handleShowModalDocument,
          label: t('template_documents_jobs_btn_add_document'),
        }}
        rounded={false}
      />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(t, handleDownloadDocument, handleUpdateDocument, handDeleteDocument)}
            dataSource={documentsList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
      {isShowModalUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
      )}

      {(isShowModalDocument || isShowModalEditDocument) && (
        <FormDocumentModal
          onClose={handleCloseModalDocument}
          isOpen={isShowModalDocument || isShowModalEditDocument}
          {...(isShowModalEditDocument && { documentId: itemDocuments?.id })}
        />
      )}

      {isShowDeleteDocument && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleCloseModelDelete}
          onAction={handleDeleteDocument}
        />
      )}
    </div>
  );
};

export default JobDocumentsTab;
