// Others
import axiosClient from './axiosClient';
import { urlApiAccountant } from '~/utils/constants/common';
import { IAccount, IQueryAccount } from '~/utils/interface/account';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';

const accountantApi = {
  getAccountList(params: IQueryAccount) {
    const url = `${urlApiAccountant.getAccountList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IAccount[]>>>(url, { params });
  },

  getAccountMultipleRole(params: IQueryAccount) {
    const url = `${urlApiAccountant.getAccountMultipleRole}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IAccount[]>>>(url, { params });
  },
};

export default accountantApi;
