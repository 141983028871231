// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseModal,
  BaseRichEditor,
  BaseSelect,
  FieldMaterialServiceJob,
  FormInput,
  Loading,
  RadioBox,
} from '~/components';
// Others
import {
  DEFAULT_DATA_FROM_JOB_SERVICE,
  DEFAULT_MATERIAL_ITEM_JOB_SERVICE,
  formSchema,
} from '~/components/specific/formServiceJobModal/helper';
import { useAppDispatch } from '~/redux/hooks';
import { getListClient } from '~/thunks/client/clientThunk';
import {
  createServiceJob,
  getJobDetails,
  getOptionFixtureLocations,
  getOptionProduct,
  updateServiceJob,
} from '~/thunks/job/jobThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsState,
  serviceJobTypeOptions,
} from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  ModeTypeEnum,
  ServiceJobTypeEnum,
  TextEditorEnum,
  TypeProductEnum,
} from '~/utils/enum';
import { getFullName } from '~/utils/helper';
import { IClient } from '~/utils/interface/client';
import { IBaseOption, IQueryBase } from '~/utils/interface/common';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import { IFormServiceJob, IJobDetails, IUpdateServiceJob } from '~/utils/interface/job';
import { IQueryListProduct } from '~/utils/interface/product';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormServiceJobModal.module.scss';

type Props = {
  jobId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

export type InitEditModeValues = Pick<
  IFormServiceJob,
  'serviceRequest' | 'serviceDate' | 'materials'
>;

const FormServiceJobModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, jobId, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEditMode = useMemo(() => !!jobId, [jobId]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormServiceJob>({
    resolver: yupResolver(formSchema({ t })),
    defaultValues: DEFAULT_DATA_FROM_JOB_SERVICE,
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'materials' });
  const serviceType = useWatch({ control, name: 'type' });
  const stateSelected = useWatch({ name: `state`, control });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientList, setClientList] = useState<IClient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Declare useMemo
  const clientOptions: IBaseOption[] = useMemo(() => {
    if (clientList?.length === DEFAULT_NUMBER_ZERO) return [];
    return clientList?.map((client: IClient) => ({
      label: getFullName({ firstName: client?.firstName, lastName: client?.lastName }),
      value: client.id,
    }));
  }, [clientList]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // Modal Open: fetch data for components Select
    fetchClientList();
    fetchMaterialProduct();
    fetchFixtureLocation();

    // Modal Open - Edit mode: fetch detail and reset Form
    if (jobId) {
      handleGetJobDetails(jobId);
      return;
    }

    // Modal Open - Add mode: reset Form
    reset(DEFAULT_DATA_FROM_JOB_SERVICE);
  }, [isOpen, jobId]);

  /** When Field State change -> set: lat, lng by State selected **/
  useEffect(() => {
    const option = optionsState?.find((item) => item?.value === stateSelected);
    setValue('latitude', option ? option?.latitude : undefined);
    setValue('longitude', option ? option?.longitude : undefined);
  }, [stateSelected]);

  useEffect(() => {
    if (!serviceType) return;
    if (serviceType === ServiceJobTypeEnum.FIXED_PRICE) {
      setValue('materials', [DEFAULT_MATERIAL_ITEM_JOB_SERVICE]);
      return;
    }

    setValue('materials', []);
  }, [serviceType]);
  //#endregion Implement Hook

  //#region Handle Function

  const handleGetJobDetails = (id: string) => {
    setIsLoading(true);

    dispatch(getJobDetails({ id: id }))
      .unwrap()
      .then((res) => {
        const jobDetailsRes: IJobDetails = res?.data;

        if (!jobDetailsRes) return;

        const {
          jobName,
          type,
          client,
          streetAddress,
          city,
          state,
          zipCode,
          latitude,
          longitude,
          serviceRequest,
          serviceDate,
          materials,
        } = jobDetailsRes;

        const dataFormEdit: IFormServiceJob = {
          title: jobName,
          clientId: Number(client?.id),
          streetAddress: streetAddress || EMPTY_STRING,
          city: city || EMPTY_STRING,
          state: state || EMPTY_STRING,
          zipCode: zipCode || EMPTY_STRING,
          latitude,
          longitude,
          type,
          serviceRequest: serviceRequest || EMPTY_STRING,
          serviceDate: serviceDate || EMPTY_STRING,
          materials: materials || [],
        };

        reset(dataFormEdit);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchClientList = () => {
    const payload: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    dispatch(getListClient(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;
        setClientList(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const fetchMaterialProduct = () => {
    const payload: IQueryListProduct = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      type: TypeProductEnum.MATERIAL_SUPPLIER,
    };

    dispatch(getOptionProduct(payload));
  };

  const fetchFixtureLocation = () => {
    const payload: IQueryListFixtureLocations = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    dispatch(getOptionFixtureLocations(payload));
  };

  const handleFillAddress = (clientId: string) => {
    if (clientList?.length <= DEFAULT_NUMBER_ZERO || !clientId) return;

    const clientSelected = clientList.find((client) => client?.id === clientId);

    setValue('streetAddress', clientSelected?.address ?? EMPTY_STRING);
    setValue('city', clientSelected?.city ?? EMPTY_STRING);
    setValue('state', clientSelected?.state ?? EMPTY_STRING);
    setValue('zipCode', clientSelected?.zipCode ?? EMPTY_STRING);
    setValue('latitude', Number(clientSelected?.latitude) || null);
    setValue('longitude', Number(clientSelected?.longitude) || null);
  };

  const handleCancel = () => {
    reset(DEFAULT_DATA_FROM_JOB_SERVICE);
    onClose();
  };

  const handleSubmitForm = (dataForm: IFormServiceJob) => {
    if (isEditMode && jobId) {
      const payload: IUpdateServiceJob = { jobId, body: dataForm };
      handleUpdate(payload);
      return;
    }

    handleAdd(dataForm);
  };

  const handleAdd = (dataForm: IFormServiceJob) => {
    setIsLoading(true);
    dispatch(createServiceJob(dataForm))
      .unwrap()
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (data: IUpdateServiceJob) => {
    setIsLoading(true);
    dispatch(updateServiceJob(data))
      .unwrap()
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <BaseModal
      id='formServiceJobModalComponent'
      minWidth={1140}
      isOpen={isOpen}
      onClose={handleCancel}
    >
      <FormProvider {...({ control, setValue } as UseFormReturn<IFormServiceJob>)}>
        <div className={cx('container')}>
          <div className={cx('header')}>
            {jobId
              ? t('common_form_service_job_title_edit')
              : t('common_form_service_job_title_add')}
          </div>
          <div className={cx('line')} />

          <form className={cx('form')} onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={cx('body')}>
              <div className={cx('twoCol')}>
                <Controller
                  name={'title'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <FormInput
                      required
                      name={name}
                      label={t('common_form_service_job_field_job_name')}
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.title?.message}
                    />
                  )}
                />
                <Controller
                  name={'clientId'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseSelect
                      name={name}
                      label={t('common_form_service_job_field_client')}
                      placeholder={t('common_placeholder_select')}
                      options={clientOptions ?? []}
                      value={value?.toString() ?? EMPTY_STRING}
                      onChange={(data: IBaseOption) => {
                        const clientId = +data.value;
                        onChange(clientId);
                        handleFillAddress(data.value);
                      }}
                      isRequired
                      errorMessage={errors?.clientId?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('groupTitle')}>
                {t('common_form_service_job_field_group_title_detail')}
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name={'streetAddress'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <FormInput
                      name={name}
                      label={t('common_form_service_job_field_street')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.streetAddress?.message}
                    />
                  )}
                />

                <Controller
                  name={'city'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <FormInput
                      name={name}
                      label={t('common_form_service_job_field_city')}
                      value={value || ''}
                      onChange={onChange}
                      errorMessage={errors.city?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name={'state'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseSelect
                      name={name}
                      label={t('common_form_service_job_field_state')}
                      placeholder={t('common_placeholder_select')}
                      value={value ?? EMPTY_STRING}
                      options={optionsState || []}
                      onChange={(option: IBaseOption) => {
                        onChange(option.value);
                      }}
                      errorMessage={errors.state?.message}
                    />
                  )}
                />

                <Controller
                  name={'zipCode'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <FormInput
                      name={name}
                      label={t('common_form_service_job_field_zip_code')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.zipCode?.message}
                    />
                  )}
                />
              </div>

              <div>
                <div className={cx('editorLabel')}>
                  {t('common_form_service_job_field_service_request')}
                </div>

                <div className={cx('editorContent')}>
                  <Controller
                    name={'serviceRequest'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseRichEditor
                        mode={ModeTypeEnum.CREATE}
                        height={270}
                        onChange={(value: string) => onChange(value)}
                        value={value ?? EMPTY_STRING}
                        type={TextEditorEnum.CUSTOM}
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <Controller
                  name={'type'}
                  control={control}
                  render={({ field: { value, name, onChange } }) => (
                    <RadioBox
                      name={name}
                      label={t('common_form_service_job_field_type')}
                      value={value}
                      options={serviceJobTypeOptions}
                      isVertical={true}
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              {serviceType === ServiceJobTypeEnum.FIXED_PRICE && (
                <>
                  {fields.map((field, index) => (
                    <FieldMaterialServiceJob
                      key={field.id}
                      fieldIndex={index}
                      removeField={remove}
                    />
                  ))}

                  <button
                    type='button'
                    onClick={() => append(DEFAULT_MATERIAL_ITEM_JOB_SERVICE)}
                    className={cx('btnAddField', 'group')}
                  >
                    <img
                      src={icons.commonIconAddField}
                      alt={t('common_img_text_alt')}
                      className={cx('iconAddField')}
                    />
                    <span className={cx('btnTextAdd')}>
                      {t('common_form_service_job_btn_add_material_row')}
                    </span>
                  </button>
                </>
              )}

              <div className={cx('groupTitle')}>
                {t('common_form_service_job_field_group_title_schedule')}
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name={'serviceDate'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseDatePicker
                      label={t('common_form_service_job_field_service_date')}
                      placeholderText={t('common_placeholder_select_date')}
                      name={name}
                      value={value}
                      onDateSelected={onChange}
                      errorMessage={errors?.serviceDate?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div className={cx('actions')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={handleCancel}
                typeStyle={ButtonTypeEnum.CANCEL}
              />

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                type='submit'
              />
            </div>
          </form>
        </div>
      </FormProvider>
      {isLoading && <Loading />}
    </BaseModal>
  );
};

export default FormServiceJobModal;
