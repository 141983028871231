// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { NavTabTypeEnum, SearchParamsEnum } from '~/utils/enum';
import { INavTabItem } from '~/utils/interface/common';
// Styles, images, icons
import styles from './NavTab.module.scss';

type Props = {
  typeStyle?: NavTabTypeEnum;
  items: INavTabItem[];
  searchParams: SearchParamsEnum;
  onClick?: (params: string) => void;
};

const cx = classNames.bind(styles);

const NavTab = (props: Props) => {
  //#region Destructuring Props
  const { typeStyle, items, searchParams, onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Handle Function
  const getNavLink = (navParams: string) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set(searchParams, navParams);
    navigate(`${location.pathname}?${currentParams.toString()}`);
  };

  const handleNavigate = (nav: INavTabItem) => {
    if (onClick) {
      onClick(nav.params);
      return;
    }

    getNavLink(nav.params);
  };
  //#endregion Handle Function

  return (
    <div id='navTabComponent' className={cx('navTabContainer', typeStyle)}>
      {items.map((nav) => {
        if (!nav.name) {
          return null;
        }

        return (
          <button
            onClick={() => handleNavigate(nav)}
            key={nav.params}
            className={cx(
              'navLink',
              location.search.includes(nav.params) && 'navLinkActive',
              typeStyle
            )}
          >
            <span
              className={cx(
                'textStyle',
                location.search.includes(nav.params) && 'textStyleActive',
                typeStyle
              )}
            >
              {t(nav.name)}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default NavTab;
