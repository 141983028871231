// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseImageCircle,
  BaseModal,
  BaseRichEditor,
  BaseTable,
  Status,
  StatusQuickBook,
} from '~/components';
// Others
import { EMPTY_STRING, REGEX, REPLACE_SPACE_FORMAT } from '~/utils/constants/common';
import {
  ActivityNameEnum,
  BaseTableEnum,
  ButtonTypeEnum,
  DateFormatEnum,
  ModeTypeEnum,
  ThemeTextEditorEnum,
} from '~/utils/enum';
import {
  convertProjectTypeOfJob,
  convertToTitleCase,
  formatDate,
  getAvatarWithName,
  getFullName,
  renderLabelActionType,
} from '~/utils/helper';
import { IActivity, IActivityDetail } from '~/utils/interface/activity';
import { ColumnType } from '~/utils/interface/common';
// Styles, images, icons
import styles from './DetailLogActivityModal.module.scss';
import './DetailLogActivityModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data?: IActivity | null;
};

const cx = classNames.bind(styles);

const renderValue = (t: TFunction, name: string, value: any) => {
  if (!value && typeof value !== 'boolean') return <>{EMPTY_STRING}</>;

  switch (name) {
    case ActivityNameEnum.JOB_STATUS:
      return <Status type='tag' status={value} />;

    case ActivityNameEnum.JOB_CLIENT:
    case ActivityNameEnum.DOCUMENT_CREATED_BY:
    case ActivityNameEnum.JOB_MANAGER:
    case ActivityNameEnum.JOB_ESTIMATOR:
      return (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName(
            { firstName: value?.firstName, lastName: value?.lastName },
            value?.thumbnailUrl
          ) ? (
            <>
              <BaseImageCircle
                url={value?.thumbnailUrl ?? value?.avatarUrl}
                firstText={value?.firstName}
                secondText={value?.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({ firstName: value?.firstName, lastName: value?.lastName })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      );

    case ActivityNameEnum.SERVICE_DATE:
    case ActivityNameEnum.DOCUMENT_CREATED_AT:
    case ActivityNameEnum.DOCUMENT_LAST_UPDATED_AT:
    case ActivityNameEnum.JOB_EXPIRED_PROPOSAL_DATE:
    case ActivityNameEnum.JOB_DUE_DATE:
      return formatDate(value, DateFormatEnum['DD MM YYYY']);

    case ActivityNameEnum.JOB_STATE:
    case ActivityNameEnum.JOB_CITY:
    case ActivityNameEnum.JOB_ZIP_CODE:
    case ActivityNameEnum.JOB_STREET_ADDRESS:
    case ActivityNameEnum.JOB_TITLE:
    case ActivityNameEnum.DOCUMENT_NAME:
    case ActivityNameEnum.JOB_NOTE:
    case ActivityNameEnum.JOB_ELEVATION:
    case ActivityNameEnum.JOB_LOT_NO:
    case ActivityNameEnum.JOB_PLAN_NUMBER:
      return value;

    case ActivityNameEnum.DOCUMENT_TYPE:
    case ActivityNameEnum.DOCUMENT_REMARK:
    case ActivityNameEnum.JOB_GARAGE_HAND:
      return <>{convertToTitleCase(value) || EMPTY_STRING}</>;

    case ActivityNameEnum.JOB_TYPE:
      return <>{t(convertProjectTypeOfJob(value)) || EMPTY_STRING}</>;

    case ActivityNameEnum.JOB_SERVICE_REQUEST:
      return (
        <div className={cx('editorServiceRequest')}>
          <BaseRichEditor
            mode={ModeTypeEnum.VIEW}
            theme={ThemeTextEditorEnum.BUBBLE}
            value={value}
          />
        </div>
      );

    case ActivityNameEnum.DOCUMENT:
      return (
        <a
          target='_blank'
          href={value ?? EMPTY_STRING}
          className={cx('documentLink')}
          rel='noreferrer'
        >
          {t('detail_modal_log_activity_default_document_link')}
        </a>
      );

    case ActivityNameEnum.JOB_IS_SYNC_QUICK_BOOK:
      return <StatusQuickBook isStatusQuickBook={value} />;

    default:
      return <></>;
  }
};

const formatName = (name: string): string => {
  if (!name) return EMPTY_STRING;
  return name
    .replace(REGEX.REPLACE_SPACE_REGEX, REPLACE_SPACE_FORMAT)
    .replace(REGEX.CAPITALIZE_FIRST_REGEX, (match) => match.toUpperCase());
};

const columns = (t: TFunction): ColumnType<IActivityDetail>[] => {
  return [
    {
      key: 'name',
      title: t('detail_modal_log_activity_name_label'),
      dataIndex: 'name',
      render(_, record: IActivityDetail) {
        return <div>{formatName(record.name)}</div>;
      },
      width: '20%',
    },
    {
      key: 'oldValue',
      title: t('detail_modal_log_activity_old_value_label'),
      dataIndex: 'oldValue',
      render(_, record: IActivityDetail) {
        return <div>{renderValue(t, record.name, record.oldValue) || EMPTY_STRING}</div>;
      },
      width: '40%',
    },
    {
      key: 'newValue',
      title: t('detail_modal_log_activity_new_value_label'),
      dataIndex: 'newValue',
      render(_, record: IActivityDetail) {
        return <div>{renderValue(t, record.name, record.newValue) || EMPTY_STRING}</div>;
      },
      width: '40%',
    },
  ];
};

const DetailLogActivityModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const filteredData =
    data?.detail?.filter(
      (item) =>
        item.name !== ActivityNameEnum.JOB_MATERIALS && item.name !== ActivityNameEnum.BUSINESS_TYPE
    ) ?? [];

  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <BaseModal
      id='detailLogActivityModalComponent'
      minWidth={1140}
      height={'80vh'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={cx('container')}>
        <div className={cx('header')}>{t('detail_modal_log_activity_title')}</div>
        <div className={cx('line')} />

        <div className={cx('mainContentWrap')}>
          <section className={cx('body')}>
            <div className={cx('generalInfo')}>
              <div className={cx('rowInfo')}>
                <span className={cx('value')}>
                  {renderLabelActionType(
                    data?.actionModel || EMPTY_STRING,
                    data?.actionType || EMPTY_STRING
                  ) || EMPTY_STRING}
                </span>
              </div>
              <div className={cx('rowInfo')}>
                <span className={cx('key')}>{`${t(
                  'detail_modal_log_activity_updated_by_label'
                )}:`}</span>
                <span className={cx('value')}>
                  {getFullName({
                    firstName: data?.createdBy?.firstName,
                    lastName: data?.createdBy?.lastName,
                  })}
                </span>
              </div>
              <div className={cx('rowInfo')}>
                <span className={cx('key')}>{`${t(
                  'detail_modal_log_activity_updated_at_label'
                )}:`}</span>
                <span className={cx('value')}>
                  {formatDate(data?.createdAt || EMPTY_STRING, DateFormatEnum['DD MM YYYY h:mm a'])}
                </span>
              </div>
            </div>

            <div className={cx('table')}>
              <BaseTable
                typeStyle={BaseTableEnum.COLOR_TABLE}
                columns={columns(t)}
                dataSource={filteredData}
              />
            </div>
          </section>

          <section className={cx('actions')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              onClick={onClose}
              typeStyle={ButtonTypeEnum.CANCEL}
            />
          </section>
        </div>
      </div>
    </BaseModal>
  );
};

export default DetailLogActivityModal;
