// Libs
import i18n from '~/utils/i18n';
// Components, Layouts, Pages
// Others
import { IClientsRes } from '~/utils/interface/client';
import {
  IBaseOption,
  IBudgetCategory,
  IHeaderProfile,
  IStatisticTop,
} from '~/utils/interface/common';
import { IContractJobsRes } from '~/utils/interface/contractJob';
import { IDocumentResp } from '~/utils/interface/document';
import {
  ICustomerInfo,
  IInventoryResp,
  IJobDocumentsTabResp,
  IMaterialUsedResp,
  IPaymentResp,
  IProposalResp,
} from '~/utils/interface/mock';

import { IServiceJobsRes } from '~/utils/interface/serviceJobs';
import { ITaskDetails } from '~/utils/interface/task';
// Styles, images, icons
import { images } from '~/assets';

export const headerProfileData: IHeaderProfile = {
  firstName: 'Carolina',
  lastName: 'Herrera',
  avatarUrl: images.adminHeaderImageAvatar,
};

export const userInfo = { fullName: 'Carolina', img: images.adminHeaderImageAvatar };

export const accessAccountLogin = 'alphabravo.dev@gmail.com';
export const passwordAccountLogin = '12345@aA';

export const defaultNumberHeaderNotification = '3';

export const statisticCardTotalStaff = {
  title: i18n.t('admin_report_total_staff'),
  value: 65,
};

export const statisticCardTotalClients = {
  title: i18n.t('admin_report_total_clients'),
  value: 30,
};

export const thisMonthTopStaffData: IStatisticTop[] = [
  {
    id: 1,
    avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    firstName: 'Samuel',
    lastName: 'Smiths',
    rateSuccess: '96% Success Rate',
  },
  {
    id: 2,
    avatarUrl:
      'https://www.getreskilled.com/wp-content/uploads/2017/02/maintenance-technician-getreskilled.com_.jpg',
    firstName: 'Will',
    lastName: 'Johnson',
    rateSuccess: '95% Success Rate',
  },
  {
    id: 3,
    avatarUrl:
      'https://plus.unsplash.com/premium_photo-1661644887413-169caed7ca7b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    firstName: 'John',
    lastName: 'McHand',
    rateSuccess: '80% Success Rate',
  },
  {
    id: 4,
    avatarUrl:
      'https://images.unsplash.com/photo-1621905252507-b35492cc74b4?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    firstName: 'Ray',
    lastName: 'Rachel',
    rateSuccess: '72% Success Rate',
  },
  {
    id: 5,
    avatarUrl:
      'https://plus.unsplash.com/premium_photo-1663065017334-0e6ce39f8511?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    firstName: 'Ted',
    lastName: 'Wilson',
    rateSuccess: '70% Success Rate',
  },
];

export const statisticReportDataDonutChart = {
  series: [186, 47, 23, 48, 12],
  seriesPercent: [186, 47, 23, 48, 12],
};

export const statisticReportDataDonutChartJob = {
  series: [150, 150],
  seriesPercent: [150, 150],
};

export const mockDataStackedColumnChartProjectsCreated = [
  {
    name: 'Contract Job',
    data: [44, 55, 41, 67, 22, 43, 0, 23, 20, 8, 13, 27],
  },
  {
    name: 'Service Job',
    data: [13, 23, 20, 8, 13, 27, 0, 23, 20, 8, 13, 27],
  },
];

export const mockDataTotalStackedColumnChartProjectsCreated = 558;

export const mockDataStackedColumnChartEstimate = [
  {
    name: 'Contract Job',
    data: [1000, 150, 160, 180, 120, 50, 0, 75, 150, 130, 150, 140],
  },
  {
    name: 'Service Job',
    data: [50, 75, 150, 130, 150, 140, 0, 100, 150, 160, 180, 100],
  },
];

export const mockDataTotalStackedColumnChartProjectsEstimated = 3690;

export const mockAnalyticsAndReportingData = [
  {
    name: 'Proposal',
    data: [82, 62, 47, 63, 47, 82, 47, 47, 82, 82, 2, 2],
  },
  {
    name: 'Job win',
    data: [61, 43, 37, 43, 37, 61, 36, 36, 61, 61, 2, 2],
  },
];

export const overviewCategoriesStatistics = [
  'Jan 2024',
  'Feb 2024',
  'Mar 2024',
  'Apr 2024',
  'May 2024',
  'Jun 2024',
  'Jul 2024',
  'Aug 2024',
  'Sep 2024',
  'Oct 2024',
  'Nov 2024',
  'Dec 2024',
];

export const commonOverviewOptionsStatistics = [
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

export const dataManageEmployee = {
  responses: [
    {
      id: '#Ab1235844',
      status: 'ACTIVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
    {
      id: '#Ab1235845',
      status: 'ACTIVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
    {
      id: '#Ab1235846',
      status: 'ACTIVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
    {
      id: '#Ab1235847',
      status: 'ON LEAVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
    {
      id: '#Ab1235848',
      status: 'ACTIVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
    {
      id: '#Ab1235849',
      status: 'ACTIVE',
      email: 'email@gmail.com',
      firstName: 'Enola',
      lastName: 'Duong',
      joinedDate: '27 Jul 23',
      phoneNumber: '0912345678',
      position: 'TESTER',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const dataManageTechnician = {
  responses: [
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ON LEAVE',
    },
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ACTIVE',
    },
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ACTIVE',
    },
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ON LEAVE',
    },
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ON LEAVE',
    },
    {
      id: '#Ab1235844',
      firstName: 'Enola',
      lastName: 'Duong',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      phoneNumber: '0912345678',
      email: 'email@gmail.com',
      joinedDate: '27 Jul 23',
      experience: '4 years',
      jobSuccessRate: '80%',
      status: 'ACTIVE',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const dataManageJobs = {
  responses: [
    {
      id: '2',
      priority: 'HIGH',
      startDate: '2024-06-17',
      dueDate: '2024-06-25',
      duration: {
        unit: 'DAYS',
        value: 8,
      },
      percentCompleted: 0,
      status: 'PENDING',
      assignees: [
        {
          id: 63,
          lastName: 'Vu',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Thanh',
          thumbnailUrl: null,
        },
        {
          id: 65,
          lastName: 'Johnson',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Emily',
          thumbnailUrl: null,
        },
        {
          id: 66,
          lastName: 'Brown',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Michael',
          thumbnailUrl: null,
        },
      ],
    },
    {
      id: '3',
      priority: 'MEDIUM',
      startDate: '2024-06-20',
      dueDate: '2024-06-30',
      duration: {
        unit: 'DAYS',
        value: 10,
      },
      percentCompleted: 0,
      status: 'PENDING',
      assignees: [
        {
          id: 67,
          lastName: 'Davis',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Sarah',
          thumbnailUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
        },
      ],
    },
    {
      id: '4',
      priority: 'LOW',
      startDate: '2024-06-20',
      dueDate: '2024-06-23',
      duration: {
        unit: 'DAYS',
        value: 3,
      },
      percentCompleted: 65,
      status: 'PENDING',
      assignees: [
        {
          id: 63,
          lastName: 'Vu',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Thanh',
          thumbnailUrl: null,
        },
        {
          id: 65,
          lastName: 'Johnson',
          avatarUrl: 'https://localhost:5000/api/assets/1719333601678_avatar_default.png',
          firstName: 'Emily',
          thumbnailUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
        },
      ],
    },
    {
      id: '5',
      priority: 'MEDIUM',
      startDate: '2024-06-20',
      dueDate: '2024-06-23',
      duration: {
        unit: 'DAYS',
        value: 3,
      },
      percentCompleted: 0,
      status: 'COMPLETED',
      assignees: [
        {
          id: 65,
          lastName: 'Johnson',
          avatarUrl:
            'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
          firstName: 'Emily',
          thumbnailUrl: null,
        },
      ],
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 1,
    totalPages: 1,
  },
};

export const adminNotesData = {
  responses: [],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 3,
  },
};

export const dataManageNotes = {
  responses: [
    {
      id: '1',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
    {
      id: '2',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
    {
      id: '3',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
    {
      id: '4',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
    {
      id: '5',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
    {
      id: '6',
      firstName: 'Enola',
      lastName: 'Duong',
      createdAt: '27 Jul 23',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Tile',
      jobId: 'Ab1235844',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockCreateEmployeePayload = {
  role: '00002',
  email: 'dev1@alphabravodevelopment.com',
  countryCode: '+84',
  phoneNumber: '0912345678',
  firstName: 'Dev',
  lastName: 'Vu',
  type: 'EMPLOYEE',
  position: 'TESTER',
};

export const mockNoteDetailData = {
  id: '1',
  name: 'Note Title',
  jobName: 'Job 1',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id lectus sollicitudin, elementum nunc vitae, pellentesque turpis. Duis erat nunc, commodo id mauris et, consectetur vulputate ante. Morbi ut varius nulla. Nulla vitae diam enim. Vestibulum pulvinar enim nulla, in bibendum arcu commodo porttitor. Integer non efficitur lacus. Curabitur sed eros aliquam, rhoncus neque id, varius massa.Sed tempor, erat a laoreet malesuada, nisi ipsum suscipit mi, ac porttitor ipsum odio eu neque. Mauris malesuada pretium neque, sed commodo neque iaculis et. Ut congue, turpis a facilisis egestas, ipsum mi luctus metus, at egestas felis arcu id lectus. Sed scelerisque imperdiet dui. Vivamus consequat in neque sit amet dapibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus pretium turpis ut tortor vulputate, feugiat ultricies sem gravida. Vestibulum pretium tincidunt justo, sit amet pretium nibh. Nullam interdum, libero quis elementum posuere, sapien leo placerat leo, eu rhoncus mauris erat sit amet purus. Maecenas efficitur mattis dolor, quis mattis diam lobortis vitae.',
};

export const mockJobSelectionData: IBaseOption[] = [
  {
    value: '1',
    label: 'Job 1',
  },
  {
    value: '2',
    label: 'Job 2',
  },
  {
    value: '3',
    label: 'Job 3',
  },
  {
    value: '4',
    label: 'Job 4',
  },
];

export const mockDocumentListData: IDocumentResp = {
  responses: [],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockTimeclockListData = {
  responses: [
    {
      id: '1',
      resource: {
        id: '1',
        avatarUrl:
          'https://plus.unsplash.com/premium_photo-1664536392896-cd1743f9c02c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Cloudy',
        lastName: 'Man',
      },
      date: '2024-06-25T01:00:00.000Z',
      job: 'Noble Plumbing (ABD)',
      checkIn: '2024-06-25T01:00:00.000Z',
      checkOut: '2024-06-25T08:00:00.000Z',
      client: {
        id: '1',
        avatar:
          'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
        contactName: 'McDowell',
        phoneNumber: '0987654321',
        email: 'mcdowell@info.com',
        other: 'youtube.com/@mc-dowell',
        note: 'Level 1',
      },
      totalTime: 60,
    },
    {
      id: '2',
      resource: {
        id: '1',
        avatarUrl:
          'https://plus.unsplash.com/premium_photo-1664541336692-e931d407ba88?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
      },
      date: '2024-06-25T01:00:00.000Z',
      job: 'Noble Plumbing (ABD)',
      checkIn: '2024-06-25T01:00:00.000Z',
      checkOut: '2024-06-25T08:00:00.000Z',
      client: {
        id: '1',
        avatar:
          'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
        contactName: 'McDowell',
        phoneNumber: '0987654321',
        email: 'mcdowell@info.com',
        other: 'youtube.com/@mc-dowell',
        note: 'Level 1',
      },
      totalTime: 180,
    },
    {
      id: '3',
      resource: {
        id: '1',
        avatarUrl:
          'https://plus.unsplash.com/premium_photo-1673287635678-8d812deb4fc2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Shay',
        lastName: 'Frederick',
      },
      date: '2024-06-25T01:00:00.000Z',
      job: 'Noble Plumbing (ABD)',
      checkIn: '2024-06-25T01:00:00.000Z',
      checkOut: '2024-06-25T08:00:00.000Z',
      client: {
        id: '1',
        avatar:
          'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
        contactName: 'McDowell',
        phoneNumber: '0987654321',
        email: 'mcdowell@info.com',
        other: 'youtube.com/@mc-dowell',
        note: 'Level 1',
      },
      totalTime: 40,
    },
    {
      id: '4',
      resource: {
        id: '1',
        avatarUrl:
          'https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Amira',
        lastName: 'Snow',
      },
      date: '2024-06-25T01:00:00.000Z',
      job: 'Noble Plumbing (ABD)',
      checkIn: '2024-06-25T01:00:00.000Z',
      checkOut: '2024-06-25T08:00:00.000Z',
      client: {
        id: '1',
        avatar:
          'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
        contactName: 'McDowell',
        phoneNumber: '0987654321',
        email: 'mcdowell@info.com',
        other: 'youtube.com/@mc-dowell',
        note: 'Level 1',
      },
      totalTime: 80,
    },
    {
      id: '5',
      resource: {
        id: '1',
        avatarUrl:
          'https://plus.unsplash.com/premium_photo-1691784778805-e1067ac42e01?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Ember',
        lastName: 'Hendricks',
      },
      date: '2024-06-25T01:00:00.000Z',
      job: 'Noble Plumbing (ABD)',
      checkIn: '2024-06-25T01:00:00.000Z',
      checkOut: '2024-06-25T08:00:00.000Z',
      client: {
        id: '1',
        avatar:
          'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        firstName: 'Karla',
        lastName: 'McDowell',
        contactName: 'McDowell',
        phoneNumber: '0987654321',
        email: 'mcdowell@info.com',
        other: 'youtube.com/@mc-dowell',
        note: 'Level 1',
      },
      totalTime: 120,
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 1,
  },
};

export const mockPaymentListData: IPaymentResp = {
  responses: [
    {
      id: '1',
      clientFirstName: 'Jimmy',
      clientLastName: 'McGree',
      clientAvatarUrl:
        'https://s.cafebazaar.ir/images/icons/com.vicman.newprofilepic-85a6652d-f9bb-42a6-bf0e-37fbaf381a4e_512x512.png?x-img=v1/resize,h_256,w_256,lossless_false/optimize',
      createdAt: '27 Jul 23',
      invoiceDate: '27 Jul 23',
      dueDate: '27 Jul 23',
      payment: 1500,
      status: 'PAID',
    },
    {
      id: '2',
      clientFirstName: 'Abraham',
      clientLastName: 'Galanger',
      createdAt: '27 Jul 23',
      invoiceDate: '27 Jul 23',
      dueDate: '27 Jul 23',
      payment: 1000,
      status: 'PENDING',
    },
    {
      id: '3',
      clientFirstName: 'Louis',
      clientLastName: 'Johnson',
      createdAt: '27 Jul 23',
      invoiceDate: '27 Jul 23',
      dueDate: '27 Jul 23',
      payment: 700,
      status: 'PENDING',
    },
    {
      id: '4',
      clientFirstName: 'Alex',
      clientLastName: 'Ming',
      clientAvatarUrl: 'https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg',
      createdAt: '27 Jul 23',
      invoiceDate: '27 Jul 23',
      dueDate: '27 Jul 23',
      payment: 200,
      status: 'PAID',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockProposalListData: IProposalResp = {
  responses: [
    {
      id: '1',
      name: 'Vinhomes Project',
      clientFirstName: 'John',
      clientLastName: 'McBurgers',
      clientAvatarUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRij6dtiHizH96qpCOe8WeXXP3yLyQJkPdGVg&s',
      estDate: '27 Jul 23',
      estCost: 3000,
      status: 'Accepted',
    },
    {
      id: '2',
      name: 'Sun Project',
      clientFirstName: 'Adam',
      clientLastName: 'Blacks',
      clientAvatarUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRij6dtiHizH96qpCOe8WeXXP3yLyQJkPdGVg&s',
      estDate: '27 Jul 23',
      estCost: 7000,
      status: 'On hold',
    },
    {
      id: '3',
      name: 'Golden Bay Project',
      clientFirstName: 'Jack',
      clientLastName: 'Camabel',
      clientAvatarUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRij6dtiHizH96qpCOe8WeXXP3yLyQJkPdGVg&s',
      estDate: '27 Jul 23',
      estCost: 4000,
      status: 'In Progress',
    },
    {
      id: '4',
      name: 'Coco Bay Project',
      clientFirstName: 'Anna',
      clientLastName: 'Callanhand',
      clientAvatarUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRij6dtiHizH96qpCOe8WeXXP3yLyQJkPdGVg&s',
      estDate: '27 Jul 23',
      estCost: 10000,
      status: '',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockInventoryListData: IInventoryResp = {
  responses: [
    {
      id: '1',
      imageUrl:
        'bmjj:https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'Screw Driver',
      vendor: 'Amazon',
      skuNo: 120312,
      quantity: 150,
      location: 'Cau Giay Warehouse',
      createdAt: '27 Jul 23',
      cost: 100,
      status: 'Active',
    },
    {
      id: '2',
      imageUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'Hand Screw',
      vendor: 'Alibaba',
      skuNo: 98734,
      quantity: 200,
      location: 'Cau Dien Warehouse',
      createdAt: '27 Jul 23',
      cost: 500,
      status: 'Out of Stock',
    },
    {
      id: '3',
      imageUrl: 'https://demos.creative-tim.comn/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'Hand Screw',
      vendor: 'Alibaba',
      skuNo: 98734,
      quantity: 200,
      location: 'Cau Dien Warehouse',
      createdAt: '27 Jul 23',
      cost: 500,
      status: 'Out of Stock',
    },
    {
      id: '4',
      imageUrl: 'https://demos.creative-tim.comn/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'Hand Screw',
      vendor: 'Alibaba',
      skuNo: 98734,
      quantity: 200,
      location: 'Cau Dien Warehouse',
      createdAt: '27 Jul 23',
      cost: 500,
      status: 'Out of Stock',
    },
    {
      id: '5',
      imageUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'Hand Screw',
      vendor: 'Alibaba',
      skuNo: 98734,
      quantity: 200,
      location: 'Cau Dien Warehouse',
      createdAt: '27 Jul 23',
      cost: 500,
      status: 'Out of Stock',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockMaterialUsedData: IMaterialUsedResp = {
  responses: [
    {
      id: '1',
      name: 'PE Tube',
      vendorName: 'Wolseley ',
      createdAt: '27 Jul 24',
      remark: 'In Progress',
    },
    {
      id: '2',
      name: 'Cerment',
      vendorName: 'Ferguson Enterprises',
      createdAt: '27 Jul 24',
      remark: 'On Hold',
    },
    {
      id: '3',
      name: 'Mortar',
      vendorName: 'Grainger',
      createdAt: '27 Jul 24',
      remark: 'In Progress',
    },
    {
      id: '4',
      name: 'Oak Wook',
      vendorName: 'Ikea',
      createdAt: '27 Jul 24',
      remark: 'Closed',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const mockDocumentsListData: IJobDocumentsTabResp = {
  responses: [
    {
      id: '1',
      avatarUrl:
        'bmjj:https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Title',
      firstName: 'Natalia',
      type: 'Contract',
      created: '27 Jul 23',
      updateAt: '1 July 24',
      remark: 'Signed',
    },
    {
      id: '2',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Title',
      firstName: 'Admin',
      type: 'Project Files',
      created: '27 Jul 23',
      updateAt: '1 July 24',
      remark: 'Signed',
    },
    {
      id: '3',
      avatarUrl:
        'https://demos.creative-tim.comn/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Title',
      firstName: 'Jennifer',
      type: 'Invoice',
      created: '27 Jul 23',
      updateAt: '1 July 24',
      remark: 'Paid',
    },
    {
      id: '4',
      avatarUrl:
        'https://demos.creative-tim.comn/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Title',
      firstName: 'Miller',
      type: 'Project Files',
      created: '27 Jul 23',
      updateAt: '1 July 24',
      remark: 'Signed',
    },
    {
      id: '5',
      avatarUrl: 'https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg',
      name: 'File Title',
      firstName: 'Enola',
      type: 'Project Files',
      created: '27 Jul 23',
      updateAt: '1 July 24',
      remark: 'Signed',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 1,
    totalPages: 1,
  },
};

export const jobDetailsSummaryMockupData = {
  jobStatus: 'ACTIVE',
  jobType: 'Fixed price',
  projectManager: {
    firstName: 'Darrell',
    lastName: 'Steward',
  },
  lotNumber: 'LOT-2454619',
  location: {
    address: '44229 North 43rd Drive',
    city: 'Phoenix',
    state: 'AZ',
    postalCode: '85087',
    country: 'US',
    lat: 21.037235382970778,
    lng: 105.76024226774803,
  },
};

export const documentJobDetails = {
  id: '6',
  name: 'Sample',
  type: 'FORM',
  remark: 'ASSIGNED',
  created: '18 Jul 2024',
  lastModified: '18 Jul 2024',
  views: '0',
  documentUrl: 'https://ancla-plumbing-dev.s3.amazonaws.com/1721333178076_sample.pdf',
  creator: {
    id: 1,
    firstName: 'Alphabravo',
    lastName: 'Admin',
    avatarUrl: 'http://localhost:5000/api/assets/1718971931443_avatar_default.png',
    thumbnailUrl: null,
  },
};

export const detailClientsMockData = {
  id: '1',
  firstName: 'Thang',
  lastName: 'Bui',
  avatar:
    'https://plus.unsplash.com/premium_photo-1689629870780-5d0e655383e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  contactName: 'Bui Cong Thang',
  phoneNumber: '098123467',
  email: 'thangbc@mail.com',
  other: 'Link Facebook',
  notes:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque fugit odit mollitia obcaecati sit exercitationem tempora dolor magnam quod! Rem voluptatem molestias dignissimos sed est, voluptas minus eveniet. Nostrum, possimus.',
};

export const typeJobMockupData = 'Fixed Price';

export const dataManageVendors = {
  responses: [
    {
      id: '#Ab12358',
      name: 'Enola',
      countryCode: '+84',
      phoneNumber: '0912345678',
      type: '',
    },
    {
      id: '#Ab1235845',
      name: 'Enola',
      countryCode: '+84',
      phoneNumber: '0912345678',
      type: '',
    },
  ],
  pagination: {
    page: 1,
    limit: 10,
    totalItems: 5,
    totalPages: 5,
  },
};

export const optionType = [
  {
    value: 'MATERIAL_SUPPLIER',
    label: 'Material supplier',
  },
  {
    value: 'FOOD_SUPPLIER',
    label: 'Food supplier',
  },
  {
    value: 'TOOL_SUPPLIER',
    label: 'Tool supplier',
  },
];

export const assigneeMock = [
  {
    id: 6,
    firstName: 'Oleo',
    lastName: 'Bone',
    status: 'AVAILABLE',
    avatarUrl:
      'https://tse1.mm.bing.net/th/id/OET.7252da000e8341b2ba1fb61c275c1f30?w=594&h=594&c=7&rs=1&o=5&pid=1.9',
  },
  {
    id: 7,
    firstName: 'Marvin',
    lastName: 'Mckinney',
    status: 'OFFLINE',
    avatarUrl:
      'https://tse1.mm.bing.net/th/id/OET.7252da000e8341b2ba1fb61c275c1f30?w=594&h=594&c=7&rs=1&o=5&pid=1.9',
  },
  {
    id: 8,
    firstName: 'Albert',
    lastName: 'Flores',
    status: 'AVAILABLE',
    avatarUrl:
      'https://tse1.mm.bing.net/th/id/OET.7252da000e8341b2ba1fb61c275c1f30?w=594&h=594&c=7&rs=1&o=5&pid=1.9',
  },
  {
    id: 9,
    firstName: 'Jacob',
    lastName: 'Jones',
    status: 'AVAILABLE',
    avatarUrl:
      'https://tse1.mm.bing.net/th/id/OET.7252da000e8341b2ba1fb61c275c1f30?w=594&h=594&c=7&rs=1&o=5&pid=1.9',
  },
  {
    id: 10,
    firstName: 'Oleo',
    lastName: 'Bone',
    status: 'OFFLINE',
    avatarUrl:
      'https://tse1.mm.bing.net/th/id/OET.7252da000e8341b2ba1fb61c275c1f30?w=594&h=594&c=7&rs=1&o=5&pid=1.9',
  },
];

export const dataCustomerInfo: ICustomerInfo = {
  id: '1',
  contactName: 'Mike Tyson',
  location: '111/1 Street name Florida, USA',
  lot: 'Lot no. 12',
  phoneNumber: '123456789',
  email: 'example@example.com',
  tagCustomer: [
    {
      id: '1',
      content: 'Premium',
    },
    {
      id: '2',
      content: 'Customer',
    },
    {
      id: '3',
      content: 'armorial',
    },
  ],
  billCustomerName: 'Customer Name',
  billLocation: '111/1 Street name Florida, USA',
  customerName: 'Customer Name',
  propertyPhotoUrl:
    'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS38-H-F0A2XvuBLXNep-F_SimpAowkODmWFP8RQAm2KEdSadnB',
};

export const dataScheduleMock = [
  {
    id: '58',
    clientId: '',
    clientFirstName: '',
    clientLastName: '',
    jobId: '4',
    jobTitle: 'Good Job',
    note: '',
    startDate: '27 Aug 2024',
    endDate: '27 Aug 2024',
    startTime: '2024-08-27T11:00:00.000Z',
    endTime: '2024-08-27T11:30:00.000Z',
    assignees: [
      {
        id: 37,
        lastName: 'Vu',
        avatarUrl: 'https://ancla-plumbing-dev.s3.amazonaws.com/1724267135299_motobike.jpeg',
        firstName: 'Son',
      },
    ],
  },
  {
    id: '70',
    clientId: '',
    clientFirstName: '',
    clientLastName: '',
    jobId: '52',
    jobTitle: 'Work mal',
    note: 'Job Plumbing',
    startDate: '26 Aug 2024',
    endDate: '26 Aug 2024',
    startTime: '2024-08-26T08:00:00.000Z',
    endTime: '2024-08-26T11:30:00.000Z',
    assignees: [
      {
        id: 31,
        lastName: 'Technician',
        avatarUrl: 'https://ancla-plumbing-dev.s3.amazonaws.com/1724574152989_Ancla logo.svg',
        firstName: 'Samantha',
      },
    ],
  },
  {
    id: '48',
    clientId: '',
    clientFirstName: '',
    clientLastName: '',
    jobId: '53',
    jobTitle: 'Work mal',
    note: '',
    startDate: '29 Aug 2024',
    endDate: '29 Aug 2024',
    startTime: '2024-08-29T07:30:00.985Z',
    endTime: '2024-08-29T12:30:00.408Z',
    assignees: [
      {
        id: 3,
        lastName: 'Vu',
        avatarUrl: '',
        firstName: 'Thanh',
      },
    ],
  },
  {
    id: '67',
    clientId: '',
    clientFirstName: '',
    clientLastName: '',
    jobId: '27',
    jobTitle: 'Urgent Job',
    note: '',
    startDate: '30 Aug 2024',
    endDate: '30 Aug 2024',
    startTime: '2024-08-30T09:30:00.000Z',
    endTime: '2024-08-30T12:30:00.000Z',
    assignees: [
      {
        id: 31,
        lastName: 'Technician',
        avatarUrl: 'https://ancla-plumbing-dev.s3.amazonaws.com/1724574152989_Ancla logo.svg',
        firstName: 'Samantha',
      },
    ],
  },
];

export const mockDataAddTaskStatus = [
  {
    label: 'On going',
    value: 'ON_GOING',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Canceled',
    value: 'CANCELED',
  },
];

export const mockDataAddTaskCity = [
  { label: 'New York', value: 'New York' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Chicago', value: 'Chicago' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'San Jose', value: 'San Jose' },
];

export const mockDataAddTaskState = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
];

export const mockDataAddTaskZipCode = [
  { label: '10001', value: '10001' },
  { label: '90001', value: '90001' },
  { label: '60601', value: '60601' },
  { label: '77001', value: '77001' },
  { label: '85001', value: '85001' },
  { label: '19101', value: '19101' },
  { label: '78201', value: '78201' },
  { label: '92101', value: '92101' },
  { label: '75201', value: '75201' },
  { label: '95101', value: '95101' },
];

export const mockDataJobDetails = {
  id: '1',
  name: 'Sendero 50’s Lot 158',
  status: 'ACTIVE',
  type: 'Fixed price',
  client: {
    id: '1',
    firstName: 'Darrell',
    lastName: 'Steward',
    avatar: '',
    thumbnail: '',
  },
  projectManager: {
    id: '1',
    firstName: 'Darrell',
    lastName: 'Steward',
    avatar: '',
    thumbnail: '',
  },
  bidDueDate: '2024-08-30T00:00:00.000Z',
  estimator: {
    id: '1',
    firstName: 'Darrell',
    lastName: 'Steward',
    avatar: '',
    thumbnail: '',
  },
  address: '44229 North 43rd Drive',
  city: 'Phoenix',
  state: 'AZ',
  zip: '85087',
  country: 'US',
  latitude: 21.037243812017014,
  longitude: 105.76029885706717,
};

export const mockCategoryProductOptions = [
  {
    value: '70885 Blue Monster 1/2" x 1429 Winston',
    label: '70885 Blue Monster 1/2" x 1429 Winston',
  },
];

export const mockDataPurchaseOrder = [
  {
    id: '1',
    purchaseOrderBy: {
      id: '1',
      avatar:
        'https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      firstName: 'Kelvin',
      lastName: 'Dang',
      thumbnail:
        'https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    item: '',
    description: 'Description',
    price: 15,
    quantity: '10',
    subTotal: 24,
    status: 'IN_PROGRESS',
  },
];

export const mockDataChangeOrder = [
  {
    id: '1',
    changeOrderBy: {
      id: '1',
      avatar:
        'https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      firstName: 'Kelvin',
      lastName: 'Dang',
      thumbnail:
        'https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    description: 'Description',
    dueDate: '2024-06-25T08:00:00.000Z',
    price: 24,
    quantity: '10',
    status: 'ON_HOLD',
    subTotal: 23,
    to: 'To',
  },
];

export const mockDataPaymentTerms: IBaseOption[] = [
  { label: 'Consignment', value: 'CONSIGNMENT' },
  { label: 'Due on receipt', value: 'DUE_ON_RECEIPT' },
  { label: 'Net 10', value: 'NET_10' },
  { label: 'Net 15', value: 'NET_15' },
  { label: 'Net 30', value: 'NET_30' },
  { label: 'Net 60', value: 'NET_60' },
  { label: '1% 10 Net 30', value: 'ONE_PERCENT_10_NET_30' },
  { label: '2% 10 Net 30', value: 'TWO_PERCENT_10_NET_30' },
];

export const mockDataTaxRate: IBaseOption[] = [
  { label: '(Non taxable) 0%', value: '0' },
  { label: '10%', value: '10' },
  { label: '15%', value: '15' },
  { label: '20%', value: '20' },
];

export const mockDataContractItem: IBaseOption[] = [
  { value: '1', label: 'TEST - KYLE - PROPOSAL - LINE ITEM - 1' },
];

export const mockDataInvoiceDetailPhoneNumber = '(480) 974-8689';
export const mockDataInvoiceDetailEmail = 'billing@anclaplumbing.com';

export const dataViewExcel: IBudgetCategory[] = [
  {
    productName: 'Product 1',
    fixtureLocationName: 'Living Room',
    quantity: 1,
    unitCost: 120,
    subTotal: 120,
  },
  {
    productName: 'Product 2',
    fixtureLocationName: 'Living Room',
    quantity: 1,
    unitCost: 130,
    subTotal: 130,
  },
  {
    productName: 'Product 3',
    fixtureLocationName: 'Living Room',
    quantity: 1,
    unitCost: 140,
    subTotal: 140,
  },
  {
    productName: 'Product 4',
    fixtureLocationName: 'Living Room',
    quantity: 1,
    unitCost: 150,
    subTotal: 150,
  },
  {
    productName: 'Product 5',
    fixtureLocationName: 'Living Room',
    quantity: 2,
    unitCost: 160,
    subTotal: 160,
  },
];

export const mockDataProductBudget = [
  { label: 'Option A', value: 'A', unitCost: 100 },
  { label: 'Option B', value: 'B', unitCost: 300 },
];

export const mockDataFixtureLocationBudget = [
  { label: 'Option C', value: 'C' },
  { label: 'Option D', value: 'D'},
];
