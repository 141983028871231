// Libs
import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import fixtureLocationApi from '~/apis/fixtureLocation';
// Others
import jobApi from '~/apis/job';
import productApi from '~/apis/product';
import {
  ADD_NEW_JOB,
  CHANGE_STATUS_SERVICE_JOB,
  CONVERT_TO_CONTRACT_JOB,
  CREATE_NEW_CONTRACT_JOB,
  CREATE_NEW_SERVICE_JOB,
  DELETE_SERVICE_JOB,
  GET_FIXTURE_LOCATION_OPTION_SERVICE_JOB,
  GET_JOB_ASSIGNEES,
  GET_JOB_DETAILS,
  GET_LIST_JOB,
  GET_LOGS_ACTIVITY_SERVICE_JOB,
  GET_MATERIAL_PRODUCT_SERVICE_JOB,
  UPDATE_SERVICE_JOB,
} from '~/utils/constants/actionType';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import {
  IChangeStatusJobPayload,
  ICreateNewContractJob,
  IFormServiceJob,
  IGetJobDetailsReq,
  IGetListJobReq,
  IQueryLogsActivity,
  IUpdateServiceJob,
} from '~/utils/interface/job';
import { IQueryListProduct } from '~/utils/interface/product';

export const getListJob = createAsyncThunk(GET_LIST_JOB, async (payload: IGetListJobReq) => {
  try {
    const res = await jobApi.getListJob(payload);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const getJobDetails = createAsyncThunk(
  GET_JOB_DETAILS,
  async ({ id, params }: { id: string; params?: IGetJobDetailsReq }) => {
    try {
      const res = await jobApi.getJobDetails(id, params);
      return res.data;
    } catch (error) {
      return isRejectedWithValue(error);
    }
  }
);

export const addNewJob = createAsyncThunk(ADD_NEW_JOB, async (formData: FormData) => {
  try {
    const res = await jobApi.addNewJob(formData);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const getJobAssignees = createAsyncThunk(GET_JOB_ASSIGNEES, async (id: number) => {
  try {
    const res = await jobApi.getJobAssignees(id);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const createNewContractJob = createAsyncThunk(
  CREATE_NEW_CONTRACT_JOB,
  async (body: ICreateNewContractJob) => {
    try {
      const res = await jobApi.createNewContractJob(body);
      return res.data;
    } catch (error) {
      return isRejectedWithValue(error);
    }
  }
);

export const createServiceJob = createAsyncThunk(
  CREATE_NEW_SERVICE_JOB,
  async (body: IFormServiceJob, { rejectWithValue }) => {
    try {
      const res = await jobApi.createServiceJob(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusServiceJob = createAsyncThunk(
  CHANGE_STATUS_SERVICE_JOB,
  async ({ id, status }: { id: string; status: IChangeStatusJobPayload }, { rejectWithValue }) => {
    try {
      const res = await jobApi.changeStatusServiceJob(id, status);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const convertToContractJob = createAsyncThunk(
  CONVERT_TO_CONTRACT_JOB,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await jobApi.convertToContractJob(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getActivityServiceJob = createAsyncThunk(
  GET_LOGS_ACTIVITY_SERVICE_JOB,
  async (payload: IQueryLogsActivity, { rejectWithValue }) => {
    try {
      const res = await jobApi.getLogsActivity(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteServiceJob = createAsyncThunk(
  DELETE_SERVICE_JOB,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await jobApi.deleteServiceJob(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/** Get list product **/
export const getOptionProduct = createAsyncThunk(
  GET_MATERIAL_PRODUCT_SERVICE_JOB,
  async (payload: IQueryListProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.getListProducts(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/** Get list fixture location **/
export const getOptionFixtureLocations = createAsyncThunk(
  GET_FIXTURE_LOCATION_OPTION_SERVICE_JOB,
  async (payload: IQueryListFixtureLocations, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.getListFixtureLocations(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateServiceJob = createAsyncThunk(
  UPDATE_SERVICE_JOB,
  async (payload: IUpdateServiceJob, { rejectWithValue }) => {
    try {
      const res = await jobApi.updateServiceJob(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
