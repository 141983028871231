import axiosClient from '~/apis/axiosClient';
import { CONTENT_TYPE_FORM_DATA, urlApiProfile } from '~/utils/constants/common';
import { BaseResponse } from '~/utils/interface/common';
import { IUserProfile } from '~/utils/interface/profile';

const profileApi = {
  getDetailProfile: () => {
    const url = `${urlApiProfile.getProfile}`;
    return axiosClient.get<BaseResponse<IUserProfile>>(url);
  },

  updateProfile: (formData: FormData) => {
    const url = `${urlApiProfile.updateProfile}`;
    return axiosClient.put<BaseResponse>(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },
};

export default profileApi;
