// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { FormPurchaseOrder, Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './CreatePo.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CreatePo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { purchaseId } = useParams();
  const { state: jobId }: Location<string> = useLocation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='createPoTemplate' className={cx('container')}>
      <Toolbar title={t('create_purchase_order_toolbar_title')} />

      <div className={cx('body')}>
        <FormPurchaseOrder purchaseId={purchaseId} jobId={jobId} />
      </div>
    </div>
  );
};

export default CreatePo;
