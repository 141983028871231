// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect } from 'react';
// Components, Layouts, Pages
import { BaseSelect, FormInput } from '~/components';
// Others
import { IFormCreatePurchaseOrder } from '~/utils/interface/purchaseOrder';
import { IBaseOption } from '~/utils/interface/common';
import { IProduct } from '~/utils/interface/product';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
// Styles, images, icons
import styles from './ProductPurchaseOrder.module.scss';
import { icons } from '~/assets';

type Props = {
  index: number;
  products: IProduct[];
  onRemoveItem: (index: number) => void;
};

const cx = classNames.bind(styles);

const ProductPurchaseOrder = (props: Props) => {
  //#region Destructuring Props
  const { index, products = [], onRemoveItem } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IFormCreatePurchaseOrder>();

  const watchQuantity = watch(`products.${index}.quantity`);
  const watchUnitCost = watch(`products.${index}.unitCost`);
  const watchSubtotal = watch(`products.${index}.subTotal`);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!watchUnitCost || !watchQuantity) {
      setValue(`products.${index}.subTotal`, 0);
      return;
    }

    setValue(`products.${index}.subTotal`, watchQuantity * watchUnitCost);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchQuantity, watchUnitCost]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='productPurchaseOrderComponent' className={cx('container')}>
      <div className={cx('twoCol')}>
        <Controller
          name={`products.${index}.productId`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              label={t('create_purchase_label_product')}
              placeholder={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              options={
                products?.map((option) => ({
                  label: option?.name || EMPTY_STRING,
                  value: option?.id,
                })) || []
              }
              onChange={(optionSelected: IBaseOption) => {
                onChange(optionSelected?.value);
                setValue(`products.${index}.productName`, optionSelected?.label);

                const findProduct = products?.find((item) => item.id === optionSelected?.value);
                setValue(`products.${index}.unitCost`, Number(findProduct?.unitCost) || 0);
              }}
            />
          )}
        />

        <div className={cx('fiveCol')}>
          <Controller
            name={`products.${index}.quantity`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('create_purchase_label_quantity')}
                value={value || EMPTY_STRING}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  if (!value) {
                    onChange(DEFAULT_NUMBER_ZERO);
                    return;
                  }

                  if (!isNaN(Number(value))) {
                    onChange(value);
                  }
                }}
              />
            )}
          />

          <Controller
            name={`products.${index}.unitCost`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('create_purchase_label_unit_cost')}
                value={formatCurrency(CurrencyEnum.USD, value ?? 0)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  if (!isNaN(Number(value))) {
                    onChange(value);
                  }
                }}
                disabled
              />
            )}
          />

          <div className={cx('itemReadOnly')}>
            <p className={cx('itemLabel')}>{t('create_purchase_label_total')}</p>

            <div className={cx('itemValue')}>
              {formatCurrency(CurrencyEnum.USD, watchSubtotal ?? 0)}
            </div>
          </div>

          <div className={cx('removeItemWrap')}>
            <div className={cx('removeItem')}>
              <img
                src={icons.commonIconRemoveField}
                alt={t('common_img_text_alt')}
                className={cx('removeItemImg')}
                onClick={() => onRemoveItem(index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPurchaseOrder;
