// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './StatusQuickBook.module.scss';

type Props = {
  isStatusQuickBook: boolean;
};

const cx = classNames.bind(styles);

const StatusQuickBook = (props: Props) => {
  //#region Destructuring Props
  const { isStatusQuickBook } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div className={cx('quickBookContainer')}>
      <div className={cx(isStatusQuickBook ? 'greenCircle' : 'grayCircle')} />
    </div>
  );
};

export default StatusQuickBook;
