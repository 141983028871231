// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { StatusType } from '~/utils/type/common';
import { StatusEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Status.module.scss';

type Props = {
  status: string;
  type?: StatusType;
};

const cx = classNames.bind(styles);

const Status = (props: Props) => {
  //#region Destructuring Props
  const { status, type = 'text' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderStatusByType = (type: StatusType, status: string) => {
    if ((type = 'tag')) {
      return <span>{status}</span>;
    }

    return status;
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case StatusEnum.ACTIVE:
        return (
          <span className={cx('active', type)}>
            {renderStatusByType(type, t('common_status_active'))}
          </span>
        );

      case StatusEnum.INACTIVE:
        return (
          <span className={cx('inactive', type)}>
            {renderStatusByType(type, t('common_status_inactive'))}
          </span>
        );

      case StatusEnum.PENDING:
        return (
          <span className={cx('pending', type)}>
            {renderStatusByType(type, t('common_status_pending'))}
          </span>
        );

      case StatusEnum.LEAD:
        return (
          <span className={cx('lead', type)}>
            {renderStatusByType(type, t('common_status_lead'))}
          </span>
        );

      case StatusEnum.INVOICED:
        return (
          <span className={cx('invoiced', type)}>
            {renderStatusByType(type, t('common_status_invoiced'))}
          </span>
        );

      case StatusEnum.COMPLETED:
        return (
          <span className={cx('completed', type)}>
            {renderStatusByType(type, t('common_status_completed'))}
          </span>
        );

      case StatusEnum.REJECTED:
        return (
          <span className={cx('rejected', type)}>
            {renderStatusByType(type, t('common_status_rejected'))}
          </span>
        );

      case StatusEnum.CLOSED:
        return (
          <span className={cx('default', type)}>
            {renderStatusByType(type, t('common_status_closed'))}
          </span>
        );

      case StatusEnum.PAID:
        return (
          <span className={cx('paid', type)}>
            {renderStatusByType(type, t('common_status_paid'))}
          </span>
        );

      case StatusEnum.ASSIGNED:
        return (
          <span className={cx('assigned', type)}>
            {renderStatusByType(type, t('common_status_assigned'))}
          </span>
        );

      case StatusEnum.SIGNED:
        return (
          <span className={cx('signed', type)}>
            {renderStatusByType(type, t('common_status_signed'))}
          </span>
        );

      case StatusEnum.BIDDING:
        return (
          <span className={cx('bidding', type)}>
            {renderStatusByType(type, t('common_status_bidding'))}
          </span>
        );

      case StatusEnum.OUT_FOR_SIGNATURE:
        return (
          <span className={cx('outForSignature', type)}>
            {renderStatusByType(type, t('common_status_out_for_signature'))}
          </span>
        );

      case StatusEnum.PROPOSAL_EXPIRED:
        return (
          <span className={cx('default', type)}>
            {renderStatusByType(type, t('common_status_proposal_expired'))}
          </span>
        );

      case StatusEnum.PENDING_CHANGES:
        return (
          <span className={cx('default', type)}>
            {renderStatusByType(type, t('common_status_pending_changes'))}
          </span>
        );

      case StatusEnum.SCHEDULED:
        return (
          <span className={cx('scheduled', type)}>
            {renderStatusByType(type, t('common_status_scheduled'))}
          </span>
        );

      case StatusEnum.CLOSED_WITHOUT_INVOICING:
        return (
          <span className={cx('default', type)}>
            {renderStatusByType(type, t('common_status_closed_without_invoicing'))}
          </span>
        );

      case StatusEnum.CLOSED_WITHOUT_PERFORMING:
        return (
          <span className={cx('closedWithoutPerforming', type)}>
            {renderStatusByType(type, t('common_status_closed_without_performing'))}
          </span>
        );

      case StatusEnum.ON_GOING:
        return (
          <span className={cx('onGoing', type)}>
            {renderStatusByType(type, t('common_status_on_going'))}
          </span>
        );

      case StatusEnum.CANCELED:
        return (
          <span className={cx('canceled', type)}>
            {renderStatusByType(type, t('common_status_canceled'))}
          </span>
        );

      case StatusEnum.INPROGRESS:
      case StatusEnum.IN_PROGRESS:
        return (
          <span className={cx('inProgress', type)}>
            {renderStatusByType(type, t('common_status_in_progress'))}
          </span>
        );

      case StatusEnum.APPROVED:
        return (
          <span className={cx('approved', type)}>
            {renderStatusByType(type, t('common_status_approved'))}
          </span>
        );

      case StatusEnum.ON_HOLD:
        return (
          <span className={cx('onHold', type)}>
            {renderStatusByType(type, t('common_status_on_hold'))}
          </span>
        );

      case StatusEnum.DUE:
        return (
          <span className={cx('due', type)}>
            {renderStatusByType(type, t('common_status_due'))}
          </span>
        );

      case StatusEnum.NO_BALANCE_DUE:
        return (
          <span className={cx('noBalanceDue', type)}>
            {renderStatusByType(type, t('common_status_no_balance_due'))}
          </span>
        );

      case StatusEnum.NEW:
        return (
          <span className={cx('new', type)}>
            {renderStatusByType(type, t('common_status_new'))}
          </span>
        );

      case StatusEnum.PAUSE:
      case StatusEnum.PAUSED:
        return (
          <span className={cx('paused', type)}>
            {renderStatusByType(type, t('common_status_paused'))}
          </span>
        );

      default:
        return EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <div id='statusComponent' className={cx('container')}>
      {renderStatus(status)}
    </div>
  );
};

export default Status;
