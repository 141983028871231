// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './RadioBox.module.scss';

interface IRadioOption {
  label: string;
  value: string;
}

type Props = {
  label?: string;
  name?: string;
  options?: IRadioOption[];
  value?: string;
  required?: boolean;
  errorMessage?: string;
  isVertical?: boolean;
  onChange?: (value: string) => void;
};

const cx = classNames.bind(styles);

const RadioBox = (props: Props) => {
  //#region Destructuring Props
  const { label, name, value, options, isVertical, required, errorMessage, onChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedValue, setSelectedValue] = useState<string>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    setSelectedValue(value);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleRadioChange = (value: string) => {
    if (!value) return;

    setSelectedValue(value);
    onChange && onChange(value);
  };
  //#endregion Handle Function
  return (
    <div id='radioBoxComponent' className={cx('container', isVertical && 'radioVertical')}>
      {label && (
        <div className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      {options?.map((option) => (
        <label key={option.value} htmlFor={option.value} className={cx('radioGroup')}>
          <input
            type='radio'
            name={name}
            id={option.value}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => handleRadioChange(option.value)}
            className={cx('radio')}
          />
          <div className={cx('radioMark', { checked: selectedValue === option.value })}></div>

          <span className={cx('optionLabel')}>{t(option.label)}</span>
        </label>
      ))}

      {errorMessage && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default RadioBox;
