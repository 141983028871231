// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  FormInput,
  BaseSelect,
  UploadFile,
  BaseModal,
  Loading,
} from '~/components/';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  createDocumentJob,
  getDocumentJobDetails,
  updateDocumentJob,
} from '~/thunks/document/documentThunk';
import { IBaseOption } from '~/utils/interface/common';
import { IAddDocumentReq } from '~/utils/interface/document';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_UPLOAD_ACCEPT_IMAGE_AND_PDF,
  documentRemarkOptions,
  documentsTypeOptions,
  EMPTY_STRING,
  NAME_VALIDATE_TEST_EMPTY,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  InputTypeEnum,
  JobDocumentNameEnum,
  StatusEnum,
} from '~/utils/enum';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { compareDataUpdate, getFullName } from '~/utils/helper';
import { documentActions } from '~/thunks/document/documentSlice';
import { IQueryAccount } from '~/utils/interface/account';
// Styles, images, icons
import styles from './FormDocumentModal.module.scss';

type Props = {
  documentId?: string;
  isOpen: boolean;
  isView?: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction, documentId?: string) => {
  return yup
    .object()
    .shape({
      name: yup.string().required(t('admin_manage_jobs_add_document_name_required')),
      type: yup.string().required(t('admin_manage_jobs_add_document_type_required')),
      document: yup
        .mixed<File>()
        .test(
          NAME_VALIDATE_TEST_EMPTY,
          t('admin_manage_jobs_add_document_select_document_required'),
          function (value) {
            if (!documentId) {
              return value instanceof File && value.size > DEFAULT_NUMBER_ZERO;
            }
            return true;
          }
        ),
    })
    .required();
};

const FormDocumentModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, isView, documentId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();

  const initialValues: IAddDocumentReq = useMemo(() => {
    return {
      name: EMPTY_STRING,
      document: new File([], EMPTY_STRING),
      type: EMPTY_STRING,
      createdAt: EMPTY_STRING,
      createdBy: EMPTY_STRING,
      lastUpdated: EMPTY_STRING,
      remark: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IAddDocumentReq>({
    resolver: yupResolver(schema(t, documentId)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [linkDocument, setLinkDocument] = useState<string | undefined>(EMPTY_STRING);
  const [isResetFile, setIsResetFile] = useState<boolean>(false);
  const [optionCreator, setOptionCreator] = useState<IBaseOption[]>([]);
  const [initialDocumentUpdate, setInitialDocumentUpdate] =
    useState<IAddDocumentReq>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListAccount({
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.ADMIN,
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.TECHNICIAN,
        AccountRoleCodesEnum.PROJECT_MANAGER,
      ],
      status: StatusEnum.ACTIVE,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!documentId) return;

    handleGetDocumentJobDetails(documentId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListAccount = (payload: IQueryAccount) => {
    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;

        responses &&
          setOptionCreator(
            responses.map((res) => ({
              label: getFullName({ firstName: res.firstName, lastName: res.lastName }),
              value: res.id.toString(),
            }))
          );
      })
      .catch((error) => {});
  };

  const handleGetDocumentJobDetails = (id: string) => {
    setIsLoading(true);
    dispatch(getDocumentJobDetails(id))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setLinkDocument(res.data?.documentUrl);
        const dataDetail: IAddDocumentReq = {
          name: res.data.name,
          type: res.data.type,
          createdAt: res.data.createdAt,
          createdBy: res.data.createdBy,
          remark: res.data.remark,
          lastUpdated: res.data.lastUpdated,
        };

        reset(dataDetail);
        setInitialDocumentUpdate(dataDetail);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseModal = () => {
    setIsResetFile(true);
    onClose();
    reset();
    linkDocument && setLinkDocument(EMPTY_STRING);
    setIsLoading(false);
  };

  const handleActionDocument = (data: IAddDocumentReq) => {
    if (!jobId) return;
    if (documentId) return handleEditDocument(data);

    return handleCreateDocument(data, jobId);
  };

  const handleCreateDocument = (data: IAddDocumentReq, jobIdData: string) => {
    const formData = new FormData();

    formData.append(JobDocumentNameEnum.JOB_ID, jobIdData);

    Object.keys(data).forEach((key) => {
      const value = data[key as keyof IAddDocumentReq];

      if (value === EMPTY_STRING || value === null || value === undefined) {
        return;
      }

      if (key === JobDocumentNameEnum.DOCUMENT && value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, value as string);
      }
    });

    setIsLoading(true);
    dispatch(createDocumentJob(formData))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        handleCloseModal();
        dispatch(documentActions.setRefreshListDocument(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditDocument = (data: IAddDocumentReq) => {
    if (!documentId) return;

    const dataCompared = compareDataUpdate(initialDocumentUpdate, data);
    const formData = new FormData();

    Object.keys(dataCompared).forEach((key) => {
      const value = data[key as keyof IAddDocumentReq];

      if (value === EMPTY_STRING || value === null || value === undefined) {
        return;
      }

      if (key === JobDocumentNameEnum.DOCUMENT && value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, value as string);
      }
    });

    setIsLoading(true);
    dispatch(
      updateDocumentJob({
        documentId: documentId,
        formData: formData,
      })
    )
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        handleCloseModal();
        dispatch(documentActions.setRefreshListDocument(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <BaseModal id='formDocumentModalComponent' isOpen={isOpen} onClose={handleCloseModal}>
      <div className={cx('title')}>
        {documentId
          ? t('admin_manage_jobs_update_document_title')
          : t('admin_manage_jobs_add_document_title')}
      </div>

      <form onSubmit={handleSubmit(handleActionDocument)} className={cx('formDocument')}>
        <div className={cx('bodyContainer')}>
          <Controller
            name={JobDocumentNameEnum.DOCUMENT}
            control={control}
            render={({ field }) => (
              <UploadFile
                name={JobDocumentNameEnum.DOCUMENT}
                resetFile={isResetFile}
                height={35}
                onFileChange={(name, file) => field.onChange(file)}
                errorMessage={errors.document?.message}
                fileUrl={linkDocument}
                isRequired
                accept={DEFAULT_UPLOAD_ACCEPT_IMAGE_AND_PDF}
              />
            )}
          />

          <div className={cx('body')}>
            <Controller
              name={JobDocumentNameEnum.NAME}
              control={control}
              render={({ field }) => (
                <FormInput
                  name={JobDocumentNameEnum.NAME}
                  label={t('admin_manage_jobs_add_document_name')}
                  type={InputTypeEnum.TEXT}
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={errors.name?.message}
                  required
                />
              )}
            />

            <Controller
              name={JobDocumentNameEnum.TYPE}
              control={control}
              render={({ field }) => (
                <BaseSelect
                  name={JobDocumentNameEnum.TYPE}
                  label={t('admin_manage_jobs_add_document_type')}
                  placeholder={t('common_placeholder_select')}
                  options={documentsTypeOptions || []}
                  value={field.value}
                  errorMessage={errors.type?.message}
                  onChange={({ value }: IBaseOption) => {
                    field.onChange(value);
                  }}
                  isRequired
                />
              )}
            />

            <Controller
              name={JobDocumentNameEnum.CREATE_BY}
              control={control}
              render={({ field }) => (
                <BaseSelect
                  name={JobDocumentNameEnum.CREATE_BY}
                  label={t('admin_manage_jobs_add_document_created_by')}
                  placeholder={t('admin_manage_jobs_add_document_creator_name_placeholder')}
                  options={optionCreator}
                  value={field.value}
                  onChange={({ value }: IBaseOption) => {
                    field.onChange(value);
                  }}
                />
              )}
            />

            <Controller
              name={JobDocumentNameEnum.CREATED_AT}
              control={control}
              render={({ field }) => (
                <BaseDatePicker
                  name={JobDocumentNameEnum.CREATED_AT}
                  label={t('admin_manage_jobs_add_document_created')}
                  placeholderText={t('common_placeholder_select_date')}
                  onDateSelected={(date) => field.onChange(date)}
                  value={field.value}
                />
              )}
            />

            <Controller
              name={JobDocumentNameEnum.LAST_UPDATED}
              control={control}
              render={({ field }) => (
                <BaseDatePicker
                  name={JobDocumentNameEnum.LAST_UPDATED}
                  label={t('admin_manage_jobs_add_document_last_update')}
                  placeholderText={t('common_placeholder_select_date')}
                  onDateSelected={(date) => field.onChange(date)}
                  value={field.value}
                />
              )}
            />

            <Controller
              name={JobDocumentNameEnum.REMARK}
              control={control}
              render={({ field }) => (
                <BaseSelect
                  name={JobDocumentNameEnum.REMARK}
                  label={t('admin_manage_jobs_add_document_remark')}
                  placeholder={t('common_placeholder_select')}
                  options={documentRemarkOptions || []}
                  value={field.value}
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className={cx('actions')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              onClick={handleCloseModal}
              typeStyle={ButtonTypeEnum.CANCEL}
              type='button'
            />
          </div>

          <BaseButton
            label={t(documentId ? 'common_btn_save' : 'common_btn_add')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={documentId ? !isDirty : false}
          />
        </div>

        {isLoading && <Loading />}
      </form>
    </BaseModal>
  );
};

export default FormDocumentModal;
