// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  createPurchaseOrder,
  getDetailPurchaseOrder,
  getListPO,
  getListPurchaseOrderByJob,
} from './purchaseOrderThunk';

export interface PurchaseOrderState {
  isRefreshPurchaseOrderList: boolean;
}

const initialState: PurchaseOrderState = {
  isRefreshPurchaseOrderList: false,
};

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrderState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshPurchaseOrderList = action.payload;
    },
  },
  extraReducers(builder) {
    // Create purchase order
    builder
      .addCase(createPurchaseOrder.pending, (state) => {})
      .addCase(createPurchaseOrder.fulfilled, (state) => {})
      .addCase(createPurchaseOrder.rejected, (state) => {});

    // Get list purchase order
    builder
      .addCase(getListPO.pending, (state) => {})
      .addCase(getListPO.fulfilled, (state) => {})
      .addCase(getListPO.rejected, (state) => {});

    // Get Detail Purchase Order
    builder
      .addCase(getDetailPurchaseOrder.pending, (state) => {})
      .addCase(getDetailPurchaseOrder.fulfilled, (state) => {})
      .addCase(getDetailPurchaseOrder.rejected, (state) => {});

    // Get List Purchase Order By Job
    builder
      .addCase(getListPurchaseOrderByJob.pending, (state) => {})
      .addCase(getListPurchaseOrderByJob.fulfilled, (state) => {})
      .addCase(getListPurchaseOrderByJob.rejected, (state) => {});
  },
});

// Action
export const purchaseOrderActions = purchaseOrderSlice.actions;

// Selectors
export const selectIsRefreshPurchaseOrderList = (state: RootState) =>
  state.purchaseOrder.isRefreshPurchaseOrderList;

// Reducer
const purchaseOrderReducer = purchaseOrderSlice.reducer;
export default purchaseOrderReducer;
