import { THttpResponse } from '~/utils/type/common';

export const httpResponse: THttpResponse = {
  // Auth
  10007: 'api_response_failed_verify_code_invalid',
  10008: 'api_response_failed_verify_code_expired',
  18013: 'api_response_failed_password_cant_not_same_current_password',

  // Schedule
  23005: 'api_response_schedule_delete_success',

  // Change Password
  18012: 'api_response_incorrect_password',

  // Contract Job
  18014: 'api_response_failed_contract_job_closed_status',
};

// Array number HttpResponse don't show toast
export const skipHttpResponseArray: number[] = [
  10000, // LOGIN_SUCCESS
  10012, // REFRESH_TOKEN_SUCCESS
  10015, // FORGOT_PASSWORD_SUCCESS
  43002, // READ_NOTIFICATION
];

export const httpStatusCode = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};
