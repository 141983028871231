// Others
import { CONTENT_TYPE_FORM_DATA, urlApiJob } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IChangeStatusJobPayload,
  ICreateNewContractJob,
  IFormServiceJob,
  IGetJobDetailsReq,
  IGetListJobReq,
  IUpdateServiceJob,
} from '~/utils/interface/job';
import axiosClient from './axiosClient';
import { IQueryLogsActivity } from '~/utils/interface/job';
import { IActivity } from '~/utils/interface/activity';

const jobApi = {
  getListJob(params: IGetListJobReq) {
    const url = `${urlApiJob.job}`;
    return axiosClient.get(url, {
      params,
    });
  },

  getJobDetails(id: string, params?: IGetJobDetailsReq) {
    const url = `${urlApiJob.job}/${id}`;
    return axiosClient.get(url, { params });
  },

  addNewJob(formData: FormData) {
    const url = `${urlApiJob.addNewJob}`;
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  getJobAssignees(id: number) {
    const url = `${urlApiJob.getJobAssignees(id)}`;
    return axiosClient.get(url);
  },

  createNewContractJob(body: ICreateNewContractJob) {
    const url = `${urlApiJob.createNewContractJob}`;
    return axiosClient.post(url, body);
  },

  createServiceJob(body: IFormServiceJob) {
    const url = `${urlApiJob.createServiceJob}`;
    return axiosClient.post(url, body);
  },

  changeStatusServiceJob(id: string, status: IChangeStatusJobPayload) {
    const url = `${urlApiJob.changeStatusServiceJob(id)}`;
    return axiosClient.put<BaseResponse>(url, status);
  },

  convertToContractJob(id: string) {
    const url = `${urlApiJob.convertToContractJob(id)}`;
    return axiosClient.put<BaseResponse>(url);
  },

  getLogsActivity(payload: IQueryLogsActivity) {
    const { jobId, typeJob, limit, page } = payload;
    const params = { typeJob, limit, page };

    const url = `${urlApiJob.getLogsActivity(jobId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IActivity[]>>>(url, { params });
  },

  deleteServiceJob(id: string) {
    const url = `${urlApiJob.deleteServiceJob(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  updateServiceJob({ jobId, body }: IUpdateServiceJob) {
    const url = `${urlApiJob.updateServiceJob(jobId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default jobApi;
