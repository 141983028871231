// Libs
// Components, Layouts, Pages
import {
  AccountantAdmin,
  CompanyAdmin,
  CategoryAdmin,
  AccountsAdmin,
  AdminJobDetails,
  CreateNewPassword,
  CreatePasswordSuccessful,
  DashboardAdmin,
  EmployeeTimeClockAdmin,
  EstimationsAdmin,
  ExpensesAdmin,
  HelpAdmin,
  InventoryManagementAdmin,
  JobsAdmin,
  LoginAdmin,
  ManageClientsAdmin,
  ManageContractJobsAdmin,
  ManageNoteAdmin,
  ManagePaymentAdmin,
  ManagePoCoAdmin,
  ManageProposalAdmin,
  ManageScheduleAdmin,
  ManageServiceJobsAdmin,
  ManageVendors,
  ManagementEmployeeAdmin,
  ReportAdmin,
  SettingAdmin,
  ManageProductsAdmin,
  ManageServicesAdmin,
  ManageFixtureLocationsAdmin,
  ForgotPasswordAdmin,
  TransactionsAdmin,
  UsersAdmin,
  VerifyCode,
  ManageTaskAdmin,
  Landing,
  DashboardStaff,
  ManagementEmployeeStaff,
  CompanyStaff,
  ManageClientsStaff,
  ManageVendorsStaff,
  ManageScheduleStaff,
  ManageTaskStaff,
  JobsStaff,
  ManageContractJobsStaff,
  ManageServiceJobsStaff,
  StaffJobDetails,
  EstimationsStaff,
  EmployeeTimeClockStaff,
  AccountantStaff,
  TransactionsStaff,
  ExpensesStaff,
  AccountsStaff,
  ManageProposalStaff,
  InventoryManagementStaff,
  ReportStaff,
  ManagePoCoStaff,
  CategoryStaff,
  ManageProductsStaff,
  ManageServicesStaff,
  ManageFixtureLocationsStaff,
  SystemAdmin,
  SystemIntegrationAdmin,
  QuickBookCallBack,
  ManageTemplateStaff,
  ManageTemplateAdmin,
  CreateNewEstimate,
  DetailEstimate,
  ManageTermsAndConditionsStaff,
  ManageTermsAndConditionsAdmin,
  ManageEstimateAdmin,
  ManageEstimateStaff,
  ChangePassword,
  NotificationSettings,
  AdminSection,
  CreateMainContentAdmin,
  CreateMainContentStaff,
  ManageInvoicesStaff,
  ManageInvoicesAdmin,
  DetailMainContentAdmin,
  DetailMainContentStaff,
  ManagePoStaff,
  ManagePoAdmin,
  CreatePoTemplate,
  ServiceJobDetailTemplate,
  DetailPoTemplate,
  ProfileAdmin,
  ProfileStaff,
  DashboardProjectManage,
  ManagementEmployeeProjectManage,
  CompanyProjectManage,
  ManageClientsProjectManage,
  ManageVendorsProjectManage,
  ManageScheduleProjectManage,
  ManageTaskProjectManage,
  JobsProjectManage,
  ManageContractJobsProjectManage,
  ManageServiceJobsProjectManage,
  JobDetailsProjectManage,
  ManageTemplateProjectManage,
  ManageEstimateProjectManage,
  ManageTermsAndConditionsProjectManage,
  CreateMainContentProjectManage,
  DetailMainContentProjectManage,
  EmployeeTimeClockProjectManage,
  ManagePoProjectManage,
  CategoryProjectManage,
  ManageProductsProjectManage,
  ManageServicesProjectManage,
  ManageFixtureLocationsProjectManage,
  ProfileProjectManage,
  ViewExcelAdmin,
} from '~/pages';
import { AuthLayout } from '~/layouts';
// Others
import { IMenuItemModel, IRouteModel } from '../interface/common';
import {
  AdminRoutesGroup,
  MenuTypeEnum,
  ProjectManageRoutesGroup,
  StaffRoutesGroup,
} from '~/utils/enum';
import i18n from '../i18n';
// Styles, images, icons
import { icons } from '~/assets';

// Router && Path
export const adminRoute = {
  base: '/admin',
  login: '/login',
  dashboard: '/dashboard',
  clients: '/clients',
  company: '/company',
  category: '/category',
  products: '/products',
  services: '/services',
  fixtureLocations: '/fixture-locations',
  employee: '/employee',
  technician: '/technician',
  schedule: '/schedule',
  task: '/task',
  jobs: '/jobs',
  contractJobs: '/contract-jobs',
  contractJobBeta: '/contract-jobs-beta',
  serviceJobs: '/service-jobs',
  jobId: '/:jobId',
  notes: '/notes',
  documents: '/documents',
  createNewEstimate: '/create',
  detailEstimate: '/detail',
  estimateId: '/:estimateId',
  employeeTimeClock: '/employee-time-clock',
  payments: '/payments',
  proposal: '/proposal',
  inventoryManagement: '/inventory-management',
  manageVendors: '/vendors',
  report: '/report',
  poco: '/po-co',
  purchaseOrder: '/purchase-order',
  settings: '/settings',
  help: '/help',
  forgotPassword: '/forgot-password',
  users: '/users',
  accountant: '/accountant',
  transactions: '/transactions',
  expenses: '/expenses',
  accounts: '/accounts',
  estimations: '/estimations',
  system: '/system',
  integration: '/integration',
  quickBook: '/quick-book',
  templates: '/templates',
  estimate: '/estimate',
  termsAndConditions: '/terms-and-conditions',
  createMainContent: '/create-main-content',
  detailMainContent: '/main-content',
  mainContentId: '/:mainContentId',
  changePassword: '/change-password',
  notificationSetting: '/notification-setting',
  adminSection: '/admin-section',
  invoices: '/invoices',
  commonCreate: '/create',
  detailPo: '/:purchaseId',
  profile: '/profile',
  viewExcel: '/view-excel',
};

export const staffRoute = {
  base: '/staff',
  login: '/login',
  dashboard: '/dashboard',
  clients: '/clients',
  company: '/company',
  category: '/category',
  products: '/products',
  services: '/services',
  fixtureLocations: '/fixture-locations',
  employee: '/employee',
  technician: '/technician',
  schedule: '/schedule',
  task: '/task',
  jobs: '/jobs',
  contractJobs: '/contract-jobs',
  createNewEstimate: '/create',
  detailEstimate: '/detail',
  estimateId: '/:estimateId',
  serviceJobs: '/service-jobs',
  jobId: '/:jobId',
  notes: '/notes',
  documents: '/documents',
  employeeTimeClock: '/employee-time-clock',
  payments: '/payments',
  proposal: '/proposal',
  inventoryManagement: '/inventory-management',
  manageVendors: '/vendors',
  report: '/report',
  poco: '/po-co',
  purchaseOrder: '/purchase-order',
  settings: '/settings',
  help: '/help',
  forgotPassword: '/forgot-password',
  users: '/users',
  accountant: '/accountant',
  transactions: '/transactions',
  expenses: '/expenses',
  accounts: '/accounts',
  estimations: '/estimations',
  templates: '/templates',
  estimate: '/estimate',
  termsAndConditions: '/terms-and-conditions',
  createMainContent: '/create-main-content',
  detailMainContent: '/main-content',
  mainContentId: '/:mainContentId',
  changePassword: '/change-password',
  notificationSetting: '/notification-setting',
  invoices: '/invoices',
  commonCreate: '/create',
  detailPo: '/:purchaseId',
  profile: '/profile',
};

export const projectManageRoute = {
  base: '/project-manage',
  login: '/login',
  dashboard: '/dashboard',
  clients: '/clients',
  company: '/company',
  category: '/category',
  products: '/products',
  services: '/services',
  fixtureLocations: '/fixture-locations',
  employee: '/employee',
  technician: '/technician',
  schedule: '/schedule',
  task: '/task',
  jobs: '/jobs',
  contractJobs: '/contract-jobs',
  createNewEstimate: '/create',
  detailEstimate: '/detail',
  estimateId: '/:estimateId',
  serviceJobs: '/service-jobs',
  jobId: '/:jobId',
  notes: '/notes',
  documents: '/documents',
  employeeTimeClock: '/employee-time-clock',
  payments: '/payments',
  proposal: '/proposal',
  inventoryManagement: '/inventory-management',
  manageVendors: '/vendors',
  report: '/report',
  poco: '/po-co',
  purchaseOrder: '/purchase-order',
  settings: '/settings',
  help: '/help',
  forgotPassword: '/forgot-password',
  users: '/users',
  accountant: '/accountant',
  transactions: '/transactions',
  expenses: '/expenses',
  accounts: '/accounts',
  estimations: '/estimations',
  templates: '/templates',
  estimate: '/estimate',
  termsAndConditions: '/terms-and-conditions',
  createMainContent: '/create-main-content',
  detailMainContent: '/main-content',
  mainContentId: '/:mainContentId',
  changePassword: '/change-password',
  notificationSetting: '/notification-setting',
  invoices: '/invoices',
  commonCreate: '/create',
  detailPo: '/:purchaseId',
  profile: '/profile',
};

export const userRoute = {
  base: '/',
  login: '/login',
  home: '/home',
  about: '/about',
};

export const publicRoute = {
  base: '/',
  auth: '/auth',
  verifyCode: 'verify-code',
  forgotPassword: 'forgot-password',
  createPassword: 'create-new-password',
  createPasswordSuccess: 'create-password-success',
  quickBookCallBack: 'quick-book/call-back',
};

export const adminRouteAbsolute = {
  dashboard: `${adminRoute.base}`,
  clients: `${adminRoute.base}${adminRoute.company}${adminRoute.clients}`,
  employee: `${adminRoute.base}${adminRoute.users}${adminRoute.employee}`,
  technician: `${adminRoute.base}${adminRoute.users}${adminRoute.technician}`,
  schedule: `${adminRoute.base}${adminRoute.schedule}`,
  company: `${adminRoute.base}${adminRoute.company}`,
  category: `${adminRoute.base}${adminRoute.category}`,
  products: `${adminRoute.base}${adminRoute.category}${adminRoute.products}`,
  services: `${adminRoute.base}${adminRoute.category}${adminRoute.services}`,
  fixtureLocations: `${adminRoute.base}${adminRoute.category}${adminRoute.fixtureLocations}`,
  task: `${adminRoute.base}${adminRoute.task}`,
  jobs: `${adminRoute.base}${adminRoute.jobs}`,
  contractJobs: `${adminRoute.base}${adminRoute.jobs}${adminRoute.contractJobs}`,
  contractJobBeta: `${adminRoute.base}${adminRoute.jobs}${adminRoute.contractJobBeta}`,
  serviceJobs: `${adminRoute.base}${adminRoute.jobs}${adminRoute.serviceJobs}`,
  notes: `${adminRoute.base}${adminRoute.notes}`,
  documents: `${adminRoute.base}${adminRoute.documents}`,
  employeeTimeClock: `${adminRoute.base}${adminRoute.employeeTimeClock}`,
  payments: `${adminRoute.base}${adminRoute.payments}`,
  proposal: `${adminRoute.base}${adminRoute.proposal}`,
  inventoryManagement: `${adminRoute.base}${adminRoute.inventoryManagement}`,
  manageVendors: `${adminRoute.base}${adminRoute.company}${adminRoute.manageVendors}`,
  report: `${adminRoute.base}${adminRoute.report}`,
  poco: `${adminRoute.base}${adminRoute.poco}`,
  purchaseOrder: `${adminRoute.base}${adminRoute.purchaseOrder}`,
  createPo: `${adminRoute.base}${adminRoute.purchaseOrder}${adminRoute.commonCreate}`,
  detailPo: `${adminRoute.base}${adminRoute.purchaseOrder}${adminRoute.detailPo}`,
  settings: `${adminRoute.base}${adminRoute.settings}`,
  help: `${adminRoute.base}${adminRoute.help}`,
  accountant: `${adminRoute.base}${adminRoute.accountant}`,
  transactions: `${adminRoute.base}${adminRoute.accountant}${adminRoute.transactions}`,
  expenses: `${adminRoute.base}${adminRoute.accountant}${adminRoute.expenses}`,
  accounts: `${adminRoute.base}${adminRoute.accountant}${adminRoute.accounts}`,
  integration: `${adminRoute.base}${adminRoute.system}${adminRoute.integration}`,
  templates: `${adminRoute.base}${adminRoute.templates}`,
  estimate: `${adminRoute.base}${adminRoute.templates}${adminRoute.estimate}`,
  createNewEstimate: `${adminRoute.base}${adminRoute.templates}${adminRoute.estimate}${adminRoute.createNewEstimate}`,
  detailEstimate: `${adminRoute.base}${adminRoute.templates}${adminRoute.estimate}${adminRoute.detailEstimate}`,
  termsAndConditions: `${adminRoute.base}${adminRoute.templates}${adminRoute.termsAndConditions}`,
  createMainContent: `${adminRoute.base}${adminRoute.templates}${adminRoute.termsAndConditions}${adminRoute.createMainContent}`,
  detailMainContent: `${adminRoute.base}${adminRoute.templates}${adminRoute.termsAndConditions}${adminRoute.detailMainContent}`,
  changePassword: `${adminRoute.base}${adminRoute.changePassword}`,
  notificationSetting: `${adminRoute.base}${adminRoute.notificationSetting}`,
  adminSection: `${adminRoute.base}${adminRoute.adminSection}`,
  invoices: `${adminRoute.base}${adminRoute.invoices}`,
  profile: `${adminRoute.base}${adminRoute.profile}`,
  viewExcel: `${adminRoute.base}${adminRoute.system}${adminRoute.viewExcel}`,
};

export const staffRouteAbsolute = {
  dashboard: `${staffRoute.base}`,
  clients: `${staffRoute.base}${staffRoute.company}${staffRoute.clients}`,
  employee: `${staffRoute.base}${staffRoute.users}${staffRoute.employee}`,
  category: `${staffRoute.base}${staffRoute.category}`,
  products: `${staffRoute.base}${staffRoute.category}${staffRoute.products}`,
  services: `${staffRoute.base}${staffRoute.category}${staffRoute.services}`,
  fixtureLocations: `${staffRoute.base}${staffRoute.category}${staffRoute.fixtureLocations}`,
  technician: `${staffRoute.base}${staffRoute.users}${staffRoute.technician}`,
  schedule: `${staffRoute.base}${staffRoute.schedule}`,
  company: `${staffRoute.base}${staffRoute.company}`,
  task: `${staffRoute.base}${staffRoute.task}`,
  jobs: `${staffRoute.base}${staffRoute.jobs}`,
  contractJobs: `${staffRoute.base}${staffRoute.jobs}${staffRoute.contractJobs}`,
  serviceJobs: `${staffRoute.base}${staffRoute.jobs}${staffRoute.serviceJobs}`,
  notes: `${staffRoute.base}${staffRoute.notes}`,
  documents: `${staffRoute.base}${staffRoute.documents}`,
  employeeTimeClock: `${staffRoute.base}${staffRoute.employeeTimeClock}`,
  payments: `${staffRoute.base}${staffRoute.payments}`,
  proposal: `${staffRoute.base}${staffRoute.proposal}`,
  inventoryManagement: `${staffRoute.base}${staffRoute.inventoryManagement}`,
  manageVendors: `${staffRoute.base}${staffRoute.company}${staffRoute.manageVendors}`,
  report: `${staffRoute.base}${staffRoute.report}`,
  poco: `${staffRoute.base}${staffRoute.poco}`,
  purchaseOrder: `${staffRoute.base}${staffRoute.purchaseOrder}`,
  createPo: `${staffRoute.base}${staffRoute.purchaseOrder}${staffRoute.commonCreate}`,
  detailPo: `${staffRoute.base}${staffRoute.purchaseOrder}${staffRoute.detailPo}`,
  settings: `${staffRoute.base}${staffRoute.settings}`,
  help: `${staffRoute.base}${staffRoute.help}`,
  accountant: `${staffRoute.base}${staffRoute.accountant}`,
  transactions: `${staffRoute.base}${staffRoute.accountant}${staffRoute.transactions}`,
  expenses: `${staffRoute.base}${staffRoute.accountant}${staffRoute.expenses}`,
  accounts: `${staffRoute.base}${staffRoute.accountant}${staffRoute.accounts}`,
  templates: `${staffRoute.base}${staffRoute.templates}`,
  estimate: `${staffRoute.base}${staffRoute.templates}${staffRoute.estimate}`,
  createNewEstimate: `${staffRoute.base}${staffRoute.templates}${staffRoute.estimate}${staffRoute.createNewEstimate}`,
  detailEstimate: `${staffRoute.base}${staffRoute.templates}${staffRoute.estimate}${staffRoute.detailEstimate}`,
  termsAndConditions: `${staffRoute.base}${staffRoute.templates}${staffRoute.termsAndConditions}`,
  createMainContent: `${staffRoute.base}${staffRoute.templates}${staffRoute.termsAndConditions}${staffRoute.createMainContent}`,
  detailMainContent: `${staffRoute.base}${staffRoute.templates}${staffRoute.termsAndConditions}${staffRoute.detailMainContent}`,
  changePassword: `${staffRoute.base}${staffRoute.changePassword}`,
  notificationSetting: `${staffRoute.base}${staffRoute.notificationSetting}`,
  invoices: `${staffRoute.base}${staffRoute.invoices}`,
  profile: `${staffRoute.base}${staffRoute.profile}`,
};

export const projectManageRouteAbsolute = {
  dashboard: `${projectManageRoute.base}`,
  clients: `${projectManageRoute.base}${projectManageRoute.company}${projectManageRoute.clients}`,
  employee: `${projectManageRoute.base}${projectManageRoute.users}${projectManageRoute.employee}`,
  category: `${projectManageRoute.base}${projectManageRoute.category}`,
  products: `${projectManageRoute.base}${projectManageRoute.category}${projectManageRoute.products}`,
  services: `${projectManageRoute.base}${projectManageRoute.category}${projectManageRoute.services}`,
  fixtureLocations: `${projectManageRoute.base}${projectManageRoute.category}${projectManageRoute.fixtureLocations}`,
  technician: `${projectManageRoute.base}${projectManageRoute.users}${projectManageRoute.technician}`,
  schedule: `${projectManageRoute.base}${projectManageRoute.schedule}`,
  company: `${projectManageRoute.base}${projectManageRoute.company}`,
  task: `${projectManageRoute.base}${projectManageRoute.task}`,
  jobs: `${projectManageRoute.base}${projectManageRoute.jobs}`,
  contractJobs: `${projectManageRoute.base}${projectManageRoute.jobs}${projectManageRoute.contractJobs}`,
  serviceJobs: `${projectManageRoute.base}${projectManageRoute.jobs}${projectManageRoute.serviceJobs}`,
  notes: `${projectManageRoute.base}${projectManageRoute.notes}`,
  documents: `${projectManageRoute.base}${projectManageRoute.documents}`,
  employeeTimeClock: `${projectManageRoute.base}${projectManageRoute.employeeTimeClock}`,
  payments: `${projectManageRoute.base}${projectManageRoute.payments}`,
  proposal: `${projectManageRoute.base}${projectManageRoute.proposal}`,
  inventoryManagement: `${projectManageRoute.base}${projectManageRoute.inventoryManagement}`,
  manageVendors: `${projectManageRoute.base}${projectManageRoute.company}${projectManageRoute.manageVendors}`,
  report: `${projectManageRoute.base}${projectManageRoute.report}`,
  poco: `${projectManageRoute.base}${projectManageRoute.poco}`,
  purchaseOrder: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}`,
  createPo: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}${projectManageRoute.commonCreate}`,
  detailPo: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}${projectManageRoute.detailPo}`,
  settings: `${projectManageRoute.base}${projectManageRoute.settings}`,
  help: `${projectManageRoute.base}${projectManageRoute.help}`,
  accountant: `${projectManageRoute.base}${projectManageRoute.accountant}`,
  transactions: `${projectManageRoute.base}${projectManageRoute.accountant}${projectManageRoute.transactions}`,
  expenses: `${projectManageRoute.base}${projectManageRoute.accountant}${projectManageRoute.expenses}`,
  accounts: `${projectManageRoute.base}${projectManageRoute.accountant}${projectManageRoute.accounts}`,
  templates: `${projectManageRoute.base}${projectManageRoute.templates}`,
  estimate: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.estimate}`,
  createNewEstimate: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.estimate}${projectManageRoute.createNewEstimate}`,
  detailEstimate: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.estimate}${projectManageRoute.detailEstimate}`,
  termsAndConditions: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.termsAndConditions}`,
  createMainContent: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.termsAndConditions}${projectManageRoute.createMainContent}`,
  detailMainContent: `${projectManageRoute.base}${projectManageRoute.templates}${projectManageRoute.termsAndConditions}${projectManageRoute.detailMainContent}`,
  changePassword: `${projectManageRoute.base}${projectManageRoute.changePassword}`,
  notificationSetting: `${projectManageRoute.base}${projectManageRoute.notificationSetting}`,
  invoices: `${projectManageRoute.base}${projectManageRoute.invoices}`,
  profile: `${projectManageRoute.base}${projectManageRoute.profile}`,
};

export const publicRouteAbsolute = {
  login: `${publicRoute.auth}`,
  verifyCode: `${publicRoute.auth}/${publicRoute.verifyCode}`,
  forgotPassword: `${publicRoute.auth}/${publicRoute.forgotPassword}`,
  createPassword: `${publicRoute.auth}/${publicRoute.createPassword}`,
  createPasswordSuccess: `${publicRoute.auth}/${publicRoute.createPasswordSuccess}`,
};

//#region Admin Routes
export const publicRoutesAdmin: IRouteModel[] = [
  {
    path: `${publicRoute.base}`,
    component: Landing,
  },
  {
    path: `${publicRoute.quickBookCallBack}`,
    component: QuickBookCallBack,
  },
  {
    path: `${publicRoute.auth}`,
    component: AuthLayout,
    children: [
      {
        index: true,
        path: `${publicRoute.auth}`,
        component: LoginAdmin,
      },
      {
        path: `${publicRoute.verifyCode}`,
        component: VerifyCode,
      },
      {
        path: `${publicRoute.forgotPassword}`,
        component: ForgotPasswordAdmin,
      },
      {
        path: `${publicRoute.createPassword}`,
        component: CreateNewPassword,
      },
      {
        path: `${publicRoute.createPasswordSuccess}`,
        component: CreatePasswordSuccessful,
      },
    ],
  },
];

const adminMainRoutes: IMenuItemModel[] = [
  {
    index: true,
    path: `${adminRoute.base}`,
    component: DashboardAdmin,
    name: i18n.t('admin_sidebar_label_overview'),
    icon: icons.adminIconOverview,
    iconActive: icons.adminIconOverview,
  },
  {
    path: `${adminRoute.base}${adminRoute.users}`,
    component: ManagementEmployeeAdmin,
    name: i18n.t('admin_sidebar_label_employee'),
    icon: icons.adminIconManagementEmployee,
    iconActive: icons.adminIconManagementEmployeeActive,
  },
  {
    path: `${adminRoute.base}${adminRoute.company}`,
    component: CompanyAdmin,
    name: i18n.t('admin_sidebar_label_company'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: adminRouteAbsolute.clients,
        component: ManageClientsAdmin,
        name: i18n.t('admin_sidebar_label_clients'),
      },
      {
        path: adminRouteAbsolute.manageVendors,
        component: ManageVendors,
        name: i18n.t('admin_sidebar_label_vendors'),
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.schedule}`,
    component: ManageScheduleAdmin,
    name: i18n.t('admin_sidebar_label_schedule'),
    icon: icons.adminIconManageSchedule,
    iconActive: icons.adminIconManageScheduleActive,
  },
  {
    path: `${adminRoute.base}${adminRoute.task}`,
    component: ManageTaskAdmin,
    name: i18n.t('admin_sidebar_label_task'),
    icon: icons.adminIconManageTask,
    iconActive: icons.adminIconManageTaskActive,
  },
  {
    path: `${adminRoute.base}${adminRoute.jobs}`,
    component: JobsAdmin,
    name: i18n.t('admin_sidebar_label_jobs'),
    icon: icons.adminIconManageJobs,
    iconActive: icons.adminIconManageJobsActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: adminRouteAbsolute.contractJobs,
        name: i18n.t('admin_sidebar_label_contract_jobs'),
        component: ManageContractJobsAdmin,
      },
      {
        path: adminRouteAbsolute.contractJobBeta,
        component: ViewExcelAdmin,
        name: i18n.t('admin_sidebar_label_contract_job_beta'),
      },
      {
        path: adminRouteAbsolute.serviceJobs,
        name: i18n.t('admin_sidebar_label_service_jobs'),
        component: ManageServiceJobsAdmin,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.jobs}${adminRoute.contractJobs}${adminRoute.jobId}`,
    component: AdminJobDetails,
  },
  {
    path: `${adminRoute.base}${adminRoute.jobs}${adminRoute.serviceJobs}${adminRoute.jobId}`,
    component: ServiceJobDetailTemplate,
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.notes}`,
  //   component: ManageNoteAdmin,
  //   name: i18n.t('admin_sidebar_label_manage_notes'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  // {
  //   path: `${adminRoute.base}${adminRoute.estimations}`,
  //   component: EstimationsAdmin,
  //   name: i18n.t('admin_sidebar_label_estimations'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  {
    path: `${adminRoute.base}${adminRoute.templates}`,
    component: ManageTemplateAdmin,
    name: i18n.t('admin_sidebar_label_templates'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: adminRouteAbsolute.estimate,
        component: ManageEstimateAdmin,
        name: i18n.t('admin_sidebar_label_estimate'),
      },
      {
        path: adminRouteAbsolute.termsAndConditions,
        component: ManageTermsAndConditionsAdmin,
        name: i18n.t('admin_sidebar_label_terms_and_conditions'),
      },
    ],
  },
  {
    path: `${adminRouteAbsolute.createMainContent}`,
    component: CreateMainContentAdmin,
  },
  {
    path: `${adminRouteAbsolute.detailMainContent}${adminRoute.mainContentId}`,
    component: DetailMainContentAdmin,
  },
  {
    path: adminRouteAbsolute.createNewEstimate,
    component: CreateNewEstimate,
  },
  {
    path: `${adminRouteAbsolute.detailEstimate}${adminRoute.estimateId}`,
    component: DetailEstimate,
  },
  {
    path: `${adminRoute.base}${adminRoute.employeeTimeClock}`,
    component: EmployeeTimeClockAdmin,
    name: i18n.t('admin_sidebar_label_time_clock'),
    icon: icons.adminIconEmployeeTimeClock,
    iconActive: icons.adminIconEmployeeTimeClockActive,
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.payments}`,
  //   component: ManagePaymentAdmin,
  //   name: i18n.t('admin_sidebar_label_manage_payments'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  // },
  // {
  //   path: `${adminRoute.base}${adminRoute.accountant}`,
  //   component: AccountantAdmin,
  //   name: i18n.t('admin_sidebar_label_accountant'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  //   type: MenuTypeEnum.DROPDOWN,
  //   children: [
  //     {
  //       path: adminRouteAbsolute.transactions,
  //       name: i18n.t('admin_sidebar_label_transactions'),
  //       component: TransactionsAdmin,
  //     },
  //     {
  //       path: adminRouteAbsolute.expenses,
  //       name: i18n.t('admin_sidebar_label_expenses'),
  //       component: ExpensesAdmin,
  //     },
  //     {
  //       path: adminRouteAbsolute.accounts,
  //       name: i18n.t('admin_sidebar_label_accounts'),
  //       component: AccountsAdmin,
  //     },
  //   ],
  // },
  // {
  //   path: `${adminRoute.base}${adminRoute.proposal}`,
  //   component: ManageProposalAdmin,
  //   name: i18n.t('admin_sidebar_label_proposal'),
  //   icon: icons.adminIconManageProposal,
  //   iconActive: icons.adminIconManageProposalActive,
  // },
  {
    path: `${adminRoute.base}${adminRoute.invoices}`,
    component: ManageInvoicesAdmin,
    name: i18n.t('admin_sidebar_label_invoices'),
    icon: icons.adminIconManageProposal,
    iconActive: icons.adminIconManageProposalActive,
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.inventoryManagement}`,
  //   component: InventoryManagementAdmin,
  //   name: i18n.t('admin_sidebar_label_inventory'),
  //   icon: icons.adminIconInventoryManagement,
  //   iconActive: icons.adminIconInventoryManagementActive,
  // },
  // {
  //   path: `${adminRoute.base}${adminRoute.report}`,
  //   component: ReportAdmin,
  //   name: i18n.t('admin_sidebar_label_report'),
  //   icon: icons.adminIconReport,
  //   iconActive: icons.adminIconReportActive,
  // },
  // {
  //   path: `${adminRoute.base}${adminRoute.poco}`,
  //   component: ManagePoCoAdmin,
  //   name: i18n.t('admin_sidebar_label_order'),
  //   icon: icons.adminIconManagePoCo,
  //   iconActive: icons.adminIconManagePoCoActive,
  // },
  {
    path: `${adminRoute.base}${adminRoute.purchaseOrder}`,
    component: ManagePoAdmin,
    name: i18n.t('common_sidebar_label_purchase_order'),
    icon: icons.adminIconManagePoCo,
    iconActive: icons.adminIconManagePoCoActive,
  },
  {
    path: `${adminRoute.base}${adminRoute.purchaseOrder}${adminRoute.commonCreate}`,
    component: CreatePoTemplate,
  },
  {
    path: `${adminRoute.base}${adminRoute.purchaseOrder}${adminRoute.detailPo}`,
    component: DetailPoTemplate,
  },
  {
    path: `${adminRoute.base}${adminRoute.category}`,
    component: CategoryAdmin,
    name: i18n.t('admin_sidebar_label_category'),
    icon: icons.adminIconManageCategory,
    iconActive: icons.adminIconManageCategoryActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: adminRouteAbsolute.products,
        component: ManageProductsAdmin,
        name: i18n.t('admin_sidebar_label_products'),
      },
      {
        path: adminRouteAbsolute.services,
        component: ManageServicesAdmin,
        name: i18n.t('admin_sidebar_label_services'),
      },
      {
        path: adminRouteAbsolute.fixtureLocations,
        component: ManageFixtureLocationsAdmin,
        name: i18n.t('admin_sidebar_label_fixture_locations'),
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.system}`,
    component: SystemAdmin,
    name: i18n.t('admin_sidebar_label_setting'),
    icon: icons.commonIconSetting,
    iconActive: icons.adminIconManageCategoryActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: adminRouteAbsolute.integration,
        component: SystemIntegrationAdmin,
        name: i18n.t('admin_sidebar_label_integration'),
      },
    ],
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.quickBook}`,
  //   component: SystemAdmin,
  //   name: i18n.t('admin_sidebar_label_setting'),
  //   icon: icons.adminIconManageCategory,
  //   iconActive: icons.adminIconManageCategoryActive,
  //   type: MenuTypeEnum.DROPDOWN,
  //   children: [
  //     {
  //       path: adminRouteAbsolute.integration,
  //       component: SystemIntegrationAdmin,
  //       name: i18n.t('admin_sidebar_label_integration'),
  //     },
  //   ],
  // },
  {
    path: `${adminRoute.base}${adminRoute.changePassword}`,
    component: ChangePassword,
  },
  {
    path: `${adminRoute.base}${adminRoute.notificationSetting}`,
    component: NotificationSettings,
  },
  {
    path: `${adminRoute.base}${adminRoute.adminSection}`,
    component: AdminSection,
  },
  {
    path: `${adminRoute.base}${adminRoute.profile}`,
    component: ProfileAdmin,
  },
];

export const privateAdminRoutesGroup = [
  {
    name: AdminRoutesGroup.ADMIN_MAIN,
    menu: adminMainRoutes,
  },
];

export const privateAdminRoutes: IRouteModel[] = [...adminMainRoutes];
//#endregion Admin Routes

//#region Staff Routes
const staffMainRoutes: IMenuItemModel[] = [
  {
    index: true,
    path: `${staffRoute.base}`,
    component: DashboardStaff,
    name: i18n.t('admin_sidebar_label_overview'),
    icon: icons.adminIconOverview,
    iconActive: icons.adminIconOverview,
  },
  {
    path: `${staffRoute.base}${staffRoute.users}`,
    component: ManagementEmployeeStaff,
    name: i18n.t('admin_sidebar_label_employee'),
    icon: icons.adminIconManagementEmployee,
    iconActive: icons.adminIconManagementEmployeeActive,
  },
  {
    path: `${staffRoute.base}${staffRoute.company}`,
    component: CompanyStaff,
    name: i18n.t('admin_sidebar_label_company'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: staffRouteAbsolute.clients,
        component: ManageClientsStaff,
        name: i18n.t('admin_sidebar_label_clients'),
      },
      {
        path: staffRouteAbsolute.manageVendors,
        component: ManageVendorsStaff,
        name: i18n.t('admin_sidebar_label_vendors'),
      },
    ],
  },
  {
    path: `${staffRoute.base}${staffRoute.schedule}`,
    component: ManageScheduleStaff,
    name: i18n.t('admin_sidebar_label_schedule'),
    icon: icons.adminIconManageSchedule,
    iconActive: icons.adminIconManageScheduleActive,
  },
  {
    path: `${staffRoute.base}${staffRoute.task}`,
    component: ManageTaskStaff,
    name: i18n.t('admin_sidebar_label_task'),
    icon: icons.adminIconManageTask,
    iconActive: icons.adminIconManageTaskActive,
  },
  {
    path: `${staffRoute.base}${staffRoute.jobs}`,
    component: JobsStaff,
    name: i18n.t('admin_sidebar_label_jobs'),
    icon: icons.adminIconManageJobs,
    iconActive: icons.adminIconManageJobsActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: staffRouteAbsolute.contractJobs,
        name: i18n.t('admin_sidebar_label_contract_jobs'),
        component: ManageContractJobsStaff,
      },
      {
        path: staffRouteAbsolute.serviceJobs,
        name: i18n.t('admin_sidebar_label_service_jobs'),
        component: ManageServiceJobsStaff,
      },
    ],
  },
  {
    path: `${staffRoute.base}${staffRoute.jobs}${staffRoute.contractJobs}${staffRoute.jobId}`,
    component: StaffJobDetails,
  },
  {
    path: `${staffRoute.base}${staffRoute.jobs}${staffRoute.serviceJobs}${staffRoute.jobId}`,
    component: ServiceJobDetailTemplate,
  },
  // {
  //   path: `${staffRoute.base}${staffRoute.notes}`,
  //   component: ManageNoteAdmin,
  //   name: i18n.t('admin_sidebar_label_manage_notes'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.estimations}`,
  //   component: EstimationsStaff,
  //   name: i18n.t('admin_sidebar_label_estimations'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  {
    path: `${staffRoute.base}${staffRoute.templates}`,
    component: ManageTemplateStaff,
    name: i18n.t('admin_sidebar_label_templates'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: staffRouteAbsolute.estimate,
        component: ManageEstimateStaff,
        name: i18n.t('admin_sidebar_label_estimate'),
      },
      {
        path: staffRouteAbsolute.termsAndConditions,
        component: ManageTermsAndConditionsStaff,
        name: i18n.t('admin_sidebar_label_terms_and_conditions'),
      },
    ],
  },
  {
    path: `${staffRouteAbsolute.createMainContent}`,
    component: CreateMainContentStaff,
  },
  {
    path: `${staffRouteAbsolute.detailMainContent}${staffRoute.mainContentId}`,
    component: DetailMainContentStaff,
  },
  {
    path: staffRouteAbsolute.createNewEstimate,
    component: CreateNewEstimate,
  },
  {
    path: `${staffRouteAbsolute.detailEstimate}${staffRoute.estimateId}`,
    component: DetailEstimate,
  },
  {
    path: `${staffRoute.base}${staffRoute.employeeTimeClock}`,
    component: EmployeeTimeClockStaff,
    name: i18n.t('admin_sidebar_label_time_clock'),
    icon: icons.adminIconEmployeeTimeClock,
    iconActive: icons.adminIconEmployeeTimeClockActive,
  },
  // {
  //   path: `${staffRoute.base}${staffRoute.payments}`,
  //   component: ManagePaymentAdmin,
  //   name: i18n.t('admin_sidebar_label_manage_payments'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.accountant}`,
  //   component: AccountantStaff,
  //   name: i18n.t('admin_sidebar_label_accountant'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  //   type: MenuTypeEnum.DROPDOWN,
  //   children: [
  //     {
  //       path: staffRouteAbsolute.transactions,
  //       name: i18n.t('admin_sidebar_label_transactions'),
  //       component: TransactionsStaff,
  //     },
  //     {
  //       path: staffRouteAbsolute.expenses,
  //       name: i18n.t('admin_sidebar_label_expenses'),
  //       component: ExpensesStaff,
  //     },
  //     {
  //       path: staffRouteAbsolute.accounts,
  //       name: i18n.t('admin_sidebar_label_accounts'),
  //       component: AccountsStaff,
  //     },
  //   ],
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.invoices}`,
  //   component: ManageInvoicesStaff,
  //   name: i18n.t('admin_sidebar_label_invoices'),
  //   icon: icons.adminIconManageProposal,
  //   iconActive: icons.adminIconManageProposalActive,
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.inventoryManagement}`,
  //   component: InventoryManagementStaff,
  //   name: i18n.t('admin_sidebar_label_inventory'),
  //   icon: icons.adminIconInventoryManagement,
  //   iconActive: icons.adminIconInventoryManagementActive,
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.report}`,
  //   component: ReportStaff,
  //   name: i18n.t('admin_sidebar_label_report'),
  //   icon: icons.adminIconReport,
  //   iconActive: icons.adminIconReportActive,
  // },
  // {
  //   path: `${staffRoute.base}${staffRoute.poco}`,
  //   component: ManagePoCoStaff,
  //   name: i18n.t('admin_sidebar_label_order'),
  //   icon: icons.adminIconManagePoCo,
  //   iconActive: icons.adminIconManagePoCoActive,
  // },
  {
    path: `${staffRoute.base}${staffRoute.purchaseOrder}`,
    component: ManagePoStaff,
    name: i18n.t('common_sidebar_label_purchase_order'),
    icon: icons.adminIconManagePoCo,
    iconActive: icons.adminIconManagePoCoActive,
  },
  {
    path: `${staffRoute.base}${staffRoute.purchaseOrder}${staffRoute.commonCreate}`,
    component: CreatePoTemplate,
  },
  {
    path: `${staffRoute.base}${staffRoute.purchaseOrder}${staffRoute.detailPo}`,
    component: DetailPoTemplate,
  },
  {
    path: `${staffRoute.base}${staffRoute.category}`,
    component: CategoryStaff,
    name: i18n.t('admin_sidebar_label_category'),
    icon: icons.adminIconManageCategory,
    iconActive: icons.adminIconManageCategoryActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: staffRouteAbsolute.products,
        component: ManageProductsStaff,
        name: i18n.t('admin_sidebar_label_products'),
      },
      {
        path: staffRouteAbsolute.services,
        component: ManageServicesStaff,
        name: i18n.t('admin_sidebar_label_services'),
      },
      {
        path: staffRouteAbsolute.fixtureLocations,
        component: ManageFixtureLocationsStaff,
        name: i18n.t('admin_sidebar_label_fixture_locations'),
      },
    ],
  },
  {
    path: `${staffRoute.base}${staffRoute.changePassword}`,
    component: ChangePassword,
  },
  {
    path: `${staffRoute.base}${staffRoute.notificationSetting}`,
    component: NotificationSettings,
  },
  {
    path: `${staffRoute.base}${staffRoute.profile}`,
    component: ProfileStaff,
  },
];

export const privateStaffRoutesGroup = [
  {
    name: StaffRoutesGroup.STAFF_MAIN,
    menu: staffMainRoutes,
  },
];

export const privateStaffRoutes: IRouteModel[] = [...staffMainRoutes];
//#endregion Staff Routes

//#region Project Manage Routes
const projectManageMainRoutes: IMenuItemModel[] = [
  {
    index: true,
    path: `${projectManageRoute.base}`,
    component: DashboardProjectManage,
    name: i18n.t('admin_sidebar_label_overview'),
    icon: icons.adminIconOverview,
    iconActive: icons.adminIconOverview,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.users}`,
    component: ManagementEmployeeProjectManage,
    name: i18n.t('admin_sidebar_label_employee'),
    icon: icons.adminIconManagementEmployee,
    iconActive: icons.adminIconManagementEmployeeActive,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.company}`,
    component: CompanyProjectManage,
    name: i18n.t('admin_sidebar_label_company'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: projectManageRouteAbsolute.clients,
        component: ManageClientsProjectManage,
        name: i18n.t('admin_sidebar_label_clients'),
      },
      {
        path: projectManageRouteAbsolute.manageVendors,
        component: ManageVendorsProjectManage,
        name: i18n.t('admin_sidebar_label_vendors'),
      },
    ],
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.schedule}`,
    component: ManageScheduleProjectManage,
    name: i18n.t('admin_sidebar_label_schedule'),
    icon: icons.adminIconManageSchedule,
    iconActive: icons.adminIconManageScheduleActive,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.task}`,
    component: ManageTaskProjectManage,
    name: i18n.t('admin_sidebar_label_task'),
    icon: icons.adminIconManageTask,
    iconActive: icons.adminIconManageTaskActive,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.jobs}`,
    component: JobsProjectManage,
    name: i18n.t('admin_sidebar_label_jobs'),
    icon: icons.adminIconManageJobs,
    iconActive: icons.adminIconManageJobsActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: projectManageRouteAbsolute.contractJobs,
        name: i18n.t('admin_sidebar_label_contract_jobs'),
        component: ManageContractJobsProjectManage,
      },
      {
        path: projectManageRouteAbsolute.serviceJobs,
        name: i18n.t('admin_sidebar_label_service_jobs'),
        component: ManageServiceJobsProjectManage,
      },
    ],
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.jobs}${projectManageRoute.contractJobs}${projectManageRoute.jobId}`,
    component: JobDetailsProjectManage,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.jobs}${projectManageRoute.serviceJobs}${projectManageRoute.jobId}`,
    component: ServiceJobDetailTemplate,
  },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.notes}`,
  //   component: ManageNoteProjectManage,
  //   name: i18n.t('admin_sidebar_label_manage_notes'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.estimations}`,
  //   component: EstimationsProjectManage,
  //   name: i18n.t('admin_sidebar_label_estimations'),
  //   icon: icons.adminIconManageNotes,
  //   iconActive: icons.adminIconManageNotesActive,
  // },
  {
    path: `${projectManageRoute.base}${projectManageRoute.templates}`,
    component: ManageTemplateProjectManage,
    name: i18n.t('admin_sidebar_label_templates'),
    icon: icons.adminIconCompany,
    iconActive: icons.adminIconCompanyActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: projectManageRouteAbsolute.estimate,
        component: ManageEstimateProjectManage,
        name: i18n.t('admin_sidebar_label_estimate'),
      },
      {
        path: projectManageRouteAbsolute.termsAndConditions,
        component: ManageTermsAndConditionsProjectManage,
        name: i18n.t('admin_sidebar_label_terms_and_conditions'),
      },
    ],
  },
  {
    path: `${projectManageRouteAbsolute.createMainContent}`,
    component: CreateMainContentProjectManage,
  },
  {
    path: `${projectManageRouteAbsolute.detailMainContent}${projectManageRoute.mainContentId}`,
    component: DetailMainContentProjectManage,
  },
  {
    path: projectManageRouteAbsolute.createNewEstimate,
    component: CreateNewEstimate,
  },
  {
    path: `${projectManageRouteAbsolute.detailEstimate}${projectManageRoute.estimateId}`,
    component: DetailEstimate,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.employeeTimeClock}`,
    component: EmployeeTimeClockProjectManage,
    name: i18n.t('admin_sidebar_label_time_clock'),
    icon: icons.adminIconEmployeeTimeClock,
    iconActive: icons.adminIconEmployeeTimeClockActive,
  },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.payments}`,
  //   component: ManagePaymentProjectManage,
  //   name: i18n.t('admin_sidebar_label_manage_payments'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.accountant}`,
  //   component: AccountantProjectManage,
  //   name: i18n.t('admin_sidebar_label_accountant'),
  //   icon: icons.adminIconManagePayment,
  //   iconActive: icons.adminIconManagePaymentActive,
  //   type: MenuTypeEnum.DROPDOWN,
  //   children: [
  //     {
  //       path: projectManageRouteAbsolute.transactions,
  //       name: i18n.t('admin_sidebar_label_transactions'),
  //       component: TransactionsProjectManage,
  //     },
  //     {
  //       path: projectManageRouteAbsolute.expenses,
  //       name: i18n.t('admin_sidebar_label_expenses'),
  //       component: ExpensesProjectManage,
  //     },
  //     {
  //       path: projectManageRouteAbsolute.accounts,
  //       name: i18n.t('admin_sidebar_label_accounts'),
  //       component: AccountsProjectManage,
  //     },
  //   ],
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.invoices}`,
  //   component: ManageInvoicesProjectManage,
  //   name: i18n.t('admin_sidebar_label_invoices'),
  //   icon: icons.adminIconManageProposal,
  //   iconActive: icons.adminIconManageProposalActive,
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.inventoryManagement}`,
  //   component: InventoryManagementProjectManage,
  //   name: i18n.t('admin_sidebar_label_inventory'),
  //   icon: icons.adminIconInventoryManagement,
  //   iconActive: icons.adminIconInventoryManagementActive,
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.report}`,
  //   component: ReportProjectManage,
  //   name: i18n.t('admin_sidebar_label_report'),
  //   icon: icons.adminIconReport,
  //   iconActive: icons.adminIconReportActive,
  // },
  // {
  //   path: `${projectManageRoute.base}${projectManageRoute.poco}`,
  //   component: ManagePoCoProjectManage,
  //   name: i18n.t('admin_sidebar_label_order'),
  //   icon: icons.adminIconManagePoCo,
  //   iconActive: icons.adminIconManagePoCoActive,
  // },
  {
    path: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}`,
    component: ManagePoProjectManage,
    name: i18n.t('common_sidebar_label_purchase_order'),
    icon: icons.adminIconManagePoCo,
    iconActive: icons.adminIconManagePoCoActive,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}${projectManageRoute.commonCreate}`,
    component: CreatePoTemplate,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.purchaseOrder}${projectManageRoute.detailPo}`,
    component: DetailPoTemplate,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.category}`,
    component: CategoryProjectManage,
    name: i18n.t('admin_sidebar_label_category'),
    icon: icons.adminIconManageCategory,
    iconActive: icons.adminIconManageCategoryActive,
    type: MenuTypeEnum.DROPDOWN,
    children: [
      {
        path: projectManageRouteAbsolute.products,
        component: ManageProductsProjectManage,
        name: i18n.t('admin_sidebar_label_products'),
      },
      {
        path: projectManageRouteAbsolute.services,
        component: ManageServicesProjectManage,
        name: i18n.t('admin_sidebar_label_services'),
      },
      {
        path: projectManageRouteAbsolute.fixtureLocations,
        component: ManageFixtureLocationsProjectManage,
        name: i18n.t('admin_sidebar_label_fixture_locations'),
      },
    ],
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.changePassword}`,
    component: ChangePassword,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.notificationSetting}`,
    component: NotificationSettings,
  },
  {
    path: `${projectManageRoute.base}${projectManageRoute.profile}`,
    component: ProfileProjectManage,
  },
];

export const privateProjectManageRoutesGroup = [
  {
    name: ProjectManageRoutesGroup.PROJECT_MANAGE_MAIN,
    menu: projectManageMainRoutes,
  },
];

export const privateProjectManageRoutes: IRouteModel[] = [...projectManageMainRoutes];
//#endregion Project Manage Routes

//#region Technician Routes
export const publicRoutesTechnician: IRouteModel[] = [
  {
    path: `${publicRoute.base}`,
    component: Landing,
  },
  {
    path: `${publicRoute.auth}`,
    component: AuthLayout,
    children: [
      {
        index: true,
        path: `${publicRoute.auth}`,
        component: LoginAdmin,
      },
      {
        path: `${publicRoute.verifyCode}`,
        component: VerifyCode,
      },
      {
        path: `${publicRoute.forgotPassword}`,
        component: ForgotPasswordAdmin,
      },
      {
        path: `${publicRoute.createPassword}`,
        component: CreateNewPassword,
      },
      {
        path: `${publicRoute.createPasswordSuccess}`,
        component: CreatePasswordSuccessful,
      },
    ],
  },
];

const technicianMainRoutes: IMenuItemModel[] = [];

export const privateTechnicianRoutesGroup = [];

export const privateTechnicianRoutes: IRouteModel[] = [...technicianMainRoutes];
//#endregion Technician Routes

export const privateRoutes = [];
