import { CONTENT_TYPE_FORM_DATA, urlApiPurchaseOrder } from '~/utils/constants/common';
import { BaseResponse } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { IListDataResponse } from '~/utils/interface/common';
import {
  IListPOByJobResp,
  IPurchaseOrder,
  IPurchaseOrderDetail,
  IQueryListPO,
  IQueryListPOByJob,
} from '~/utils/interface/purchaseOrder';

const purchaseOrderApi = {
  createPurchaseOrder(body: FormData) {
    const url = `${urlApiPurchaseOrder.createPurchaseOrder}`;
    return axiosClient.post<BaseResponse>(url, body, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  getListPurchaseOrder(payload: IQueryListPO) {
    const url = `${urlApiPurchaseOrder.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IPurchaseOrder[]>>>(url, {
      params: payload,
    });
  },

  getPurchaseOrderDetail(id: string) {
    const url = `${urlApiPurchaseOrder.getPurchaseOrderDetail(id)}`;
    return axiosClient.get<BaseResponse<IPurchaseOrderDetail>>(url);
  },

  getListPurchaseOrderByJob(payload: IQueryListPOByJob) {
    const url = `${urlApiPurchaseOrder.getListPurchaseOrderByJob(payload.jobId)}`;
    const { jobId, ...rest } = payload;

    return axiosClient.get<BaseResponse<IListPOByJobResp>>(url, { params: rest });
  },
};

export default purchaseOrderApi;
