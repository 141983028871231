// Libs
import {
  CellChange,
  CellLocation,
  DefaultCellTypes,
  Id,
  MenuOption,
  ReactGrid,
  Row,
  SelectionMode,
} from '@silevis/reactgrid';
import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { TProductGridColumn } from '~/utils/type/common';
import { DEFAULT_INDEX_COLUMN_WIDTH, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import '@silevis/reactgrid/styles.css';
import { icons } from '~/assets';
import styles from './ProductSpreadsheet.module.scss';

type Props<T> = {
  title?: string;
  columns: TProductGridColumn<T>[];
  dataSource: T[];
  onChange: (data: T[]) => void;
  defaultDataSource: T;
};

const cx = classNames.bind(styles);

const ProductSpreadsheet = <T extends Object>(props: Props<T>) => {
  //#region Destructuring Props
  const { title, columns, dataSource = [], defaultDataSource, onChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>({});
  //#endregion Declare State

  //#region Declare useMemo
  const nonEditable = (cell: DefaultCellTypes): DefaultCellTypes => ({
    ...cell,
    nonEditable: true,
  });

  const headerRow: Row = useMemo(
    () => ({
      rowId: 'header',
      cells: [
        nonEditable({ type: 'header', text: EMPTY_STRING }),
        ...columns.map((col) => nonEditable({ type: 'header', text: col.name ?? EMPTY_STRING })),
      ],
    }),
    [columns]
  );

  const dataRows: Row[] = useMemo(() => {
    return dataSource.map<Row>((item, rowIndex) => ({
      rowId: rowIndex,
      cells: [
        nonEditable({ type: 'number', value: rowIndex + 1, className: cx('customCellRow') }),
        ...columns.map((col) => {
          const cellBase = col.render(item[col.dataIndex]);
          if (col.disabled) {
            return nonEditable(cellBase);
          }
          if (cellBase.type === 'dropdown') {
            const dropdownKey = `${rowIndex}-${col.columnId}`;
            return {
              ...cellBase,
              isOpen: openDropdowns[dropdownKey],
            };
          }

          return cellBase;
        }),
      ],
    }));
  }, [dataSource, columns, openDropdowns]);
  //#endregion Declare useMemo

  //#region Handle Function
  const handleChanges = (cellChanges: CellChange[]) => {
    if (!onChange) return;

    const updatedData = applyChangesToData(cellChanges, dataSource);
    onChange(updatedData);
  };

  const applyChangesToData = (cellChanges: CellChange[], prevData: T[]): T[] => {
    let updatedData = [...prevData];

    cellChanges.forEach((cellChange) => {
      const { rowId, columnId, newCell, previousCell } = cellChange;
      if (typeof rowId !== 'number') return;

      if (newCell.type === 'text') {
        updatedData[rowId] = {
          ...updatedData[rowId],
          [columnId]: newCell.text,
        };
      }

      if (newCell.type === 'number') {
        updatedData[rowId] = {
          ...updatedData[rowId],
          [columnId]: newCell.value,
        };
      }

      if (newCell.type === 'dropdown' && previousCell.type === 'dropdown') {
        const dropdownKey = `${rowId}-${columnId}`;
        toggleDropdown(dropdownKey, newCell.isOpen || false);

        if (newCell.selectedValue !== previousCell.selectedValue) {
          updatedData[rowId] = {
            ...updatedData[rowId],
            [columnId]: newCell.selectedValue,
          };
        }
      }
    });

    return updatedData;
  };

  const handleAddRow = () => {
    if (!onChange) return;

    onChange([...dataSource, defaultDataSource]);
  };

  const handleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    selectedRanges: Array<CellLocation[]>
  ): MenuOption[] => {
    if (selectionMode === 'row' && selectedRowIds.length > 0) {
      const deleteOption: MenuOption = {
        id: 'deleteRow',
        label: t('product_spread_sheet_delete_row_btn'),
        handler: () => {
          if (!onChange) return;

          const updatedData = dataSource.filter((_, index) => !selectedRowIds.includes(index));
          onChange(updatedData);
        },
      };

      return [deleteOption];
    }

    return [];
  };

  const toggleDropdown = (dropdownKey: string, isOpen: boolean) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [dropdownKey]: isOpen,
    }));
  };
  //#endregion Handle Function

  return (
    <div id='productSpreadsheetComponent' className={cx('container')}>
      {title && (
        <div className={cx('header')}>
          <h3 className={cx('title')}>{title}</h3>
        </div>
      )}
      <ReactGrid
        columns={[{ columnId: 'index', width: DEFAULT_INDEX_COLUMN_WIDTH }, ...columns]}
        rows={[headerRow, ...dataRows]}
        onCellsChanged={handleChanges}
        onContextMenu={handleContextMenu}
        enableRowSelection
      />

      <div className={cx('btnAddWrap')}>
        <button type='button' onClick={handleAddRow} className={cx('btnAddField', 'group')}>
          <img
            src={icons.commonIconAddField}
            alt={t('common_img_text_alt')}
            className={cx('iconAddField')}
          />
          <span className={cx('btnTextAdd')}>{t('template_form_phase_content_btn_add_field')}</span>
        </button>
      </div>
    </div>
  );
};

export default ProductSpreadsheet;
