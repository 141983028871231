import { urlApiNotifications } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { INotificationsResp, IReadNotificationResp } from '~/utils/interface/notifications';
import { BaseResponse } from '~/utils/interface/common';
import { IQueryBase } from '~/utils/interface/common';

const notificationsApi = {
  getList(params: IQueryBase) {
    const url = `${urlApiNotifications.getList}`;
    return axiosClient.get<BaseResponse<INotificationsResp>>(url, { params });
  },

  markedRead(notificationId: string) {
    const url = `${urlApiNotifications.markedRead(notificationId)}`;
    return axiosClient.put<BaseResponse<IReadNotificationResp>>(url);
  },
};

export default notificationsApi;
