// Libs
import { Description, Field } from '@headlessui/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import {
  DEFAULT_VALUE_EMPTY_RICH_EDITOR,
  EMPTY_STRING,
  toolbarOptionsRichEditor,
  toolbarOptionsRichEditorCustom,
  toolbarOptionsRichEditorShort,
} from '~/utils/constants/common';
import { ModeTypeEnum, TextEditorEnum, ThemeTextEditorEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './BaseRichEditor.module.scss';
import './BaseRichEditor.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

type Props = {
  onChange?: (text: string) => void;
  errorMessage?: string;
  value?: string;
  mode: string;
  width?: number | string;
  height?: number | string;
  type?: TextEditorEnum;
  theme?: ThemeTextEditorEnum;
};

const cx = classNames.bind(styles);

const BaseRichEditor = (props: Props) => {
  //#region Destructuring Props
  const {
    onChange,
    errorMessage,
    width = '100%',
    height = 356,
    value = EMPTY_STRING,
    mode,
    type = TextEditorEnum.FULL,
    theme = ThemeTextEditorEnum.SNOW,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const reactQuillRef = useRef<ReactQuill>(null);

  const handleChange = useCallback(
    (content: string) => {
      if (content === DEFAULT_VALUE_EMPTY_RICH_EDITOR) {
        onChange && onChange(EMPTY_STRING);
        return setValueRichEditor(EMPTY_STRING);
      }
      setValueRichEditor(content);
      onChange && onChange(content);
    },
    [onChange]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [valueRichEditor, setValueRichEditor] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) {
      setValueRichEditor(EMPTY_STRING);
      return;
    }

    setValueRichEditor(value);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const getOptionsRichEditor = () => {
    const options = {
      [TextEditorEnum.FULL]: toolbarOptionsRichEditor,
      [TextEditorEnum.SHORT]: toolbarOptionsRichEditorShort,
      [TextEditorEnum.CUSTOM]: toolbarOptionsRichEditorCustom,
    };

    return options[type] || [];
  };
  //#endregion Handle Function

  const modules = {
    toolbar: {
      container: getOptionsRichEditor(),
    },
  };

  return (
    <div id='textEditorComponent'>
      {theme === ThemeTextEditorEnum.SNOW && (
        <Field id='baseRichEditorComponent' className={cx('container')}>
          <div className={cx('quillEditorGroup')} style={{ width, height }}>
            <ReactQuill
              ref={reactQuillRef}
              theme='snow'
              readOnly={mode === ModeTypeEnum.VIEW && true}
              modules={modules}
              value={valueRichEditor}
              onChange={handleChange}
              className={cx('quill')}
            />
            {errorMessage && <Description className={cx('error-text')}>{errorMessage}</Description>}
          </div>
        </Field>
      )}

      {theme === ThemeTextEditorEnum.BUBBLE && (
        <div className={cx('bubbleEditor')}>
          <ReactQuill
            ref={reactQuillRef}
            theme={ThemeTextEditorEnum.BUBBLE}
            readOnly={true}
            value={value}
          />
        </div>
      )}
    </div>
  );
};

export default memo(BaseRichEditor);
