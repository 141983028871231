// Libs
import { Library } from '@googlemaps/js-api-loader';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import { BaseTextarea, Status } from '~/components';
// Others
import { selectJobDetails } from '~/thunks/job/jobSlice';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  convertGarageHandOfJob,
  convertProjectTypeOfJob,
  formatAddress,
  formattedTime,
  getFullName,
} from '~/utils/helper';
import { IPosition } from '~/utils/interface/common';
// Styles, images, icons
import styles from './JobSummaryTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const libraries: Library[] = ['places'];

const JobDetailsTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? '',
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useSelector(selectJobDetails);
  //#endregion Selector

  //#region Declare State
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    getUserLocation();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  //#endregion Handle Function

  return (
    <div id={'adminJobSummaryTabComponent'} className={cx('container')}>
      <div className={cx('jobDetailsSection')}>
        <div className={cx('jobDetailsTitle')}>
          {t('admin_manage_jobs_tabs_summary_job_details')}
        </div>

        <div className={cx('jobDetailsContainer')}>
          <div className={cx('jobDetailsWrap')}>
            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t('admin_manage_jobs_tabs_summary_status')}:`}</span>
              <span className={cx('value')}>
                {jobDetails?.status && <Status status={jobDetails?.status} />}
              </span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t('admin_manage_jobs_tabs_summary_type')}:`}</span>
              <span className={cx('value')}>
                {t(convertProjectTypeOfJob(jobDetails?.type)) || EMPTY_STRING}
              </span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>
                {`${t('admin_manage_jobs_tabs_summary_project_manager')}:`}
              </span>
              <span className={cx('value')}>
                {getFullName({
                  ...jobDetails?.manager,
                })}
              </span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t(
                'admin_manage_jobs_tabs_summary_bid_due_date'
              )}:`}</span>
              <span className={cx('value')}>
                {jobDetails?.dueDate ? formattedTime(jobDetails?.dueDate) : EMPTY_STRING}
              </span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t(
                'admin_manage_jobs_tabs_summary_estimator'
              )}:`}</span>
              <span className={cx('value')}>{getFullName({ ...jobDetails?.estimator })}</span>
            </div>
          </div>

          <div className={cx('mapSection')}>
            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t('admin_manage_jobs_tabs_summary_address')}:`}</span>
              <span className={cx('value')}>
                {formatAddress({
                  ...jobDetails,
                  address: jobDetails?.streetAddress,
                })}
              </span>
            </div>

            {isLoaded && (
              <div className={cx('map')}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: 300 }}
                  center={{
                    lat: jobDetails?.latitude || positionSelected.lat,
                    lng: jobDetails?.longitude || positionSelected.lng,
                  }}
                  zoom={DEFAULT_MAP_ZOOM}
                >
                  <Marker
                    position={{
                      lat: jobDetails?.latitude || positionSelected.lat,
                      lng: jobDetails?.longitude || positionSelected.lng,
                    }}
                  />
                </GoogleMap>
              </div>
            )}
          </div>
        </div>

        <div className={cx('jobDetailsTitle')}>
          {t('admin_manage_jobs_tabs_summary_construction_detail')}
        </div>

        <div className={cx('constructionDetail')}>
          <div className={cx('threeColumns')}>
            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t(
                'admin_manage_jobs_tabs_summary_plan_number'
              )}:`}</span>
              <span className={cx('value')}>{jobDetails?.planNumber || EMPTY_STRING}</span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t(
                'admin_manage_jobs_tabs_summary_lot_number'
              )}:`}</span>
              <span className={cx('value')}>{jobDetails?.lotNo || EMPTY_STRING}</span>
            </div>

            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t(
                'admin_manage_jobs_tabs_summary_elevation'
              )}:`}</span>
              <span className={cx('value')}>{jobDetails?.elevation || EMPTY_STRING}</span>
            </div>
          </div>

          <div className={cx('jobDetailsItem')}>
            <span className={cx('key')}>{`${t(
              'admin_manage_jobs_tabs_summary_garage_hand'
            )}:`}</span>
            <span className={cx('value')}>
              {t(convertGarageHandOfJob(jobDetails?.garageHand)) || EMPTY_STRING}
            </span>
          </div>

          <div className={cx('generateNote')}>
            <p className={cx('key')}>{`${t('admin_manage_jobs_tabs_summary_generate_note')}:`}</p>

            <BaseTextarea
              value={jobDetails?.note || EMPTY_STRING}
              height={100}
              px={10}
              py={10}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsTab;
